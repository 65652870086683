import React, {useRef, useState, useEffect} from 'react';
import {langSeparator} from '../../../hooks/helperFunctions';
import TakeActionLine from './TakeActionLine';
import {gsap} from 'gsap';
import {Settings} from '../../../store';

const ContinentsColumn = (props) => {
    const {data, totalImpactValue, negativeCalculation} = props;
    const [animateBar, setAnimateBar] = useState(false);
    const progressBarSection = useRef(null);
    const settingsState = React.useContext(Settings.State);
    const {language} = settingsState;
    useEffect(() => {
        setTimeout(() => {
            gsap.to(progressBarSection, {
                scrollTrigger: {
                    trigger: progressBarSection.current,
                    start: 'top 50%',
                    scrub: false,
                },
                onStart: () => {
                    setAnimateBar(true);
                },
            });
        }, 500);

    }, []);

    return (
            <div ref={progressBarSection} className="col-lg-4 formue-animation" data-delay={0.1}>
                <div className="d-flex align-items-center mb-4 text-center justify-content-center justify-content-lg-start text-lg-start gap-3">
                    <img className="" src={'../media/Vector/Position.svg'} alt="Continents"/>
                    <h5 className="">Impact by Continents</h5>
                </div>
                {Object.keys(data).map((item, index) => {
                    const continent = data[item];
                    const percent = (100 * continent.negativeImpactAverageFiveYears / totalImpactValue).toFixed(3);
                    const value = (percent * negativeCalculation / 100).toLocaleString(langSeparator(language), {maximumFractionDigits: 0});
                    return <TakeActionLine animateBar={animateBar} name={continent.name}
                                           percent={percent} value={value}/>;
                })}
            </div>
    );

};

export default ContinentsColumn;
