import React, {useContext, useEffect, useRef} from 'react';
import {gsap} from 'gsap';
import {Settings} from '../../../store';
import translations from '../../../data/footprint/lang.json';

const Screen1 = (props) => {
    const {imgel} = props;
    const dispatch = useContext(Settings.Dispatch);
    const settingsState = useContext(Settings.State);
    const {activeScreen, language} = settingsState;
    const el = useRef(null);

    useEffect(() => {
        gsap.set('header', {
            zIndex: 11,
        });
        gsap.to(el.current,
                {opacity: 1},
        );
        gsap.to(imgel.current,
                {ease: 'expo.out', duration: 0.8, scale: 1, filter: 'none', opacity: 0.85});
        gsap.to('header', {
            opacity: 1,
            duration: 0.2,
            visibility: 'visible',
            delay: 0.2,
        });
    }, []);

    return (<>

        <div style={{opacity: 0}} ref={el}>
            <div className="block__content p-0 gap-5 gap-lg-7">
                <div className="row justify-content-center d-flex gap-3 gap-lg-5">
                    <div className="col-xl-11">
                        <h1 className="large"><span
                                className="word"><span>{translations[language].maintitle.part1} </span></span> <span
                                className="word"><span>{translations[language].maintitle.part2} </span></span> <span
                                className="word"><span>{translations[language].maintitle.part3} </span></span> <span
                                className="word"><span>{translations[language].maintitle.part4} </span></span> <span
                                className="word"><span>{translations[language].maintitle.part5} </span></span> <span
                                className="word"><span>{translations[language].maintitle.part6}</span></span></h1>
                    </div>
                </div>
                <div className="row justify-content-center d-flex gap-3 gap-lg-5">
                    <div className="col-md-9 col-sm-7">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="d-flex align-items-center justify-content-start mb-3">
                                    <svg className="me-2" style={{minWidth: 40}} width="40" height="40"
                                         viewBox="0 0 40 40" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <mask id="mask0_642_545" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse"
                                              x="0"
                                              y="0" width="40" height="40">
                                            <rect width="40" height="40" fill="#D9D9D9"/>
                                        </mask>
                                        <g mask="url(#mask0_642_545)">
                                            <path d="M17.5833 23.5278L13.6528 19.5972C13.3843 19.3287 13.0556 19.1944 12.6667 19.1944C12.2778 19.1944 11.9445 19.3333 11.6667 19.6111C11.3796 19.8981 11.2361 20.2454 11.2361 20.6528C11.2361 21.0602 11.3704 21.3981 11.6389 21.6666L16.6111 26.6389C16.8611 26.8889 17.1852 27.0139 17.5833 27.0139C17.9815 27.0139 18.3056 26.8889 18.5556 26.6389L28.375 16.8194C28.6435 16.5509 28.7778 16.2222 28.7778 15.8333C28.7778 15.4444 28.6297 15.1018 28.3333 14.8055C28.0648 14.537 27.7269 14.4028 27.3195 14.4028C26.9121 14.4028 26.5648 14.5463 26.2778 14.8333L17.5833 23.5278ZM20 36.6666C17.6574 36.6666 15.4722 36.2384 13.4445 35.3819C11.4167 34.5254 9.6528 33.3472 8.1528 31.8472C6.6528 30.3472 5.47455 28.5833 4.61805 26.5555C3.76158 24.5277 3.33334 22.3426 3.33334 20C3.33334 17.6759 3.76158 15.5 4.61805 13.4722C5.47455 11.4444 6.6528 9.68052 8.1528 8.18052C9.6528 6.68052 11.4167 5.49766 13.4445 4.63194C15.4722 3.76619 17.6574 3.33331 20 3.33331C22.3241 3.33331 24.5 3.76619 26.5278 4.63194C28.5556 5.49766 30.3195 6.68052 31.8195 8.18052C33.3195 9.68052 34.5023 11.4444 35.3681 13.4722C36.2338 15.5 36.6667 17.6759 36.6667 20C36.6667 22.3426 36.2338 24.5277 35.3681 26.5555C34.5023 28.5833 33.3195 30.3472 31.8195 31.8472C30.3195 33.3472 28.5556 34.5254 26.5278 35.3819C24.5 36.2384 22.3241 36.6666 20 36.6666Z"
                                                  fill="#F5F3F0"/>
                                        </g>
                                    </svg>
                                    <p className="lead text-start m-0"><strong>{translations[language].bannerList.item1}</strong></p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="d-flex align-items-center justify-content-start mb-3">
                                    <svg className="me-2" style={{minWidth: 40}} width="40" height="40"
                                         viewBox="0 0 40 40" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <mask id="mask0_642_545" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse"
                                              x="0"
                                              y="0" width="40" height="40">
                                            <rect width="40" height="40" fill="#D9D9D9"/>
                                        </mask>
                                        <g mask="url(#mask0_642_545)">
                                            <path d="M17.5833 23.5278L13.6528 19.5972C13.3843 19.3287 13.0556 19.1944 12.6667 19.1944C12.2778 19.1944 11.9445 19.3333 11.6667 19.6111C11.3796 19.8981 11.2361 20.2454 11.2361 20.6528C11.2361 21.0602 11.3704 21.3981 11.6389 21.6666L16.6111 26.6389C16.8611 26.8889 17.1852 27.0139 17.5833 27.0139C17.9815 27.0139 18.3056 26.8889 18.5556 26.6389L28.375 16.8194C28.6435 16.5509 28.7778 16.2222 28.7778 15.8333C28.7778 15.4444 28.6297 15.1018 28.3333 14.8055C28.0648 14.537 27.7269 14.4028 27.3195 14.4028C26.9121 14.4028 26.5648 14.5463 26.2778 14.8333L17.5833 23.5278ZM20 36.6666C17.6574 36.6666 15.4722 36.2384 13.4445 35.3819C11.4167 34.5254 9.6528 33.3472 8.1528 31.8472C6.6528 30.3472 5.47455 28.5833 4.61805 26.5555C3.76158 24.5277 3.33334 22.3426 3.33334 20C3.33334 17.6759 3.76158 15.5 4.61805 13.4722C5.47455 11.4444 6.6528 9.68052 8.1528 8.18052C9.6528 6.68052 11.4167 5.49766 13.4445 4.63194C15.4722 3.76619 17.6574 3.33331 20 3.33331C22.3241 3.33331 24.5 3.76619 26.5278 4.63194C28.5556 5.49766 30.3195 6.68052 31.8195 8.18052C33.3195 9.68052 34.5023 11.4444 35.3681 13.4722C36.2338 15.5 36.6667 17.6759 36.6667 20C36.6667 22.3426 36.2338 24.5277 35.3681 26.5555C34.5023 28.5833 33.3195 30.3472 31.8195 31.8472C30.3195 33.3472 28.5556 34.5254 26.5278 35.3819C24.5 36.2384 22.3241 36.6666 20 36.6666Z"
                                                  fill="#F5F3F0"/>
                                        </g>
                                    </svg>
                                    <p className="lead text-start m-0"><strong>{translations[language].bannerList.item2}</strong></p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="d-flex align-items-center justify-content-start mb-3">
                                    <svg className="me-2" style={{minWidth: 40}} width="40" height="40"
                                         viewBox="0 0 40 40" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <mask id="mask0_642_545" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse"
                                              x="0"
                                              y="0" width="40" height="40">
                                            <rect width="40" height="40" fill="#D9D9D9"/>
                                        </mask>
                                        <g mask="url(#mask0_642_545)">
                                            <path d="M17.5833 23.5278L13.6528 19.5972C13.3843 19.3287 13.0556 19.1944 12.6667 19.1944C12.2778 19.1944 11.9445 19.3333 11.6667 19.6111C11.3796 19.8981 11.2361 20.2454 11.2361 20.6528C11.2361 21.0602 11.3704 21.3981 11.6389 21.6666L16.6111 26.6389C16.8611 26.8889 17.1852 27.0139 17.5833 27.0139C17.9815 27.0139 18.3056 26.8889 18.5556 26.6389L28.375 16.8194C28.6435 16.5509 28.7778 16.2222 28.7778 15.8333C28.7778 15.4444 28.6297 15.1018 28.3333 14.8055C28.0648 14.537 27.7269 14.4028 27.3195 14.4028C26.9121 14.4028 26.5648 14.5463 26.2778 14.8333L17.5833 23.5278ZM20 36.6666C17.6574 36.6666 15.4722 36.2384 13.4445 35.3819C11.4167 34.5254 9.6528 33.3472 8.1528 31.8472C6.6528 30.3472 5.47455 28.5833 4.61805 26.5555C3.76158 24.5277 3.33334 22.3426 3.33334 20C3.33334 17.6759 3.76158 15.5 4.61805 13.4722C5.47455 11.4444 6.6528 9.68052 8.1528 8.18052C9.6528 6.68052 11.4167 5.49766 13.4445 4.63194C15.4722 3.76619 17.6574 3.33331 20 3.33331C22.3241 3.33331 24.5 3.76619 26.5278 4.63194C28.5556 5.49766 30.3195 6.68052 31.8195 8.18052C33.3195 9.68052 34.5023 11.4444 35.3681 13.4722C36.2338 15.5 36.6667 17.6759 36.6667 20C36.6667 22.3426 36.2338 24.5277 35.3681 26.5555C34.5023 28.5833 33.3195 30.3472 31.8195 31.8472C30.3195 33.3472 28.5556 34.5254 26.5278 35.3819C24.5 36.2384 22.3241 36.6666 20 36.6666Z"
                                                  fill="#F5F3F0"/>
                                        </g>
                                    </svg>
                                    <p className="lead text-start m-0"><strong>{translations[language].bannerList.item3}</strong></p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="d-flex align-items-center justify-content-start mb-3">
                                    <svg className="me-2" style={{minWidth: 40}} width="40" height="40"
                                         viewBox="0 0 40 40" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <mask id="mask0_642_545" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse"
                                              x="0"
                                              y="0" width="40" height="40">
                                            <rect width="40" height="40" fill="#D9D9D9"/>
                                        </mask>
                                        <g mask="url(#mask0_642_545)">
                                            <path d="M17.5833 23.5278L13.6528 19.5972C13.3843 19.3287 13.0556 19.1944 12.6667 19.1944C12.2778 19.1944 11.9445 19.3333 11.6667 19.6111C11.3796 19.8981 11.2361 20.2454 11.2361 20.6528C11.2361 21.0602 11.3704 21.3981 11.6389 21.6666L16.6111 26.6389C16.8611 26.8889 17.1852 27.0139 17.5833 27.0139C17.9815 27.0139 18.3056 26.8889 18.5556 26.6389L28.375 16.8194C28.6435 16.5509 28.7778 16.2222 28.7778 15.8333C28.7778 15.4444 28.6297 15.1018 28.3333 14.8055C28.0648 14.537 27.7269 14.4028 27.3195 14.4028C26.9121 14.4028 26.5648 14.5463 26.2778 14.8333L17.5833 23.5278ZM20 36.6666C17.6574 36.6666 15.4722 36.2384 13.4445 35.3819C11.4167 34.5254 9.6528 33.3472 8.1528 31.8472C6.6528 30.3472 5.47455 28.5833 4.61805 26.5555C3.76158 24.5277 3.33334 22.3426 3.33334 20C3.33334 17.6759 3.76158 15.5 4.61805 13.4722C5.47455 11.4444 6.6528 9.68052 8.1528 8.18052C9.6528 6.68052 11.4167 5.49766 13.4445 4.63194C15.4722 3.76619 17.6574 3.33331 20 3.33331C22.3241 3.33331 24.5 3.76619 26.5278 4.63194C28.5556 5.49766 30.3195 6.68052 31.8195 8.18052C33.3195 9.68052 34.5023 11.4444 35.3681 13.4722C36.2338 15.5 36.6667 17.6759 36.6667 20C36.6667 22.3426 36.2338 24.5277 35.3681 26.5555C34.5023 28.5833 33.3195 30.3472 31.8195 31.8472C30.3195 33.3472 28.5556 34.5254 26.5278 35.3819C24.5 36.2384 22.3241 36.6666 20 36.6666Z"
                                                  fill="#F5F3F0"/>
                                        </g>
                                    </svg>
                                    <p className="lead text-start m-0"><strong>{translations[language].bannerList.item4}</strong></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center buttons">
                    <div className="col-sm-12">
                        <div className="d-flex flex-wrap justify-content-center gap-3">
                            <button onClick={() => {
                                dispatch({type: 'setActiveScreen', payload: activeScreen + 1});
                                window.scrollTo(0, 0);
                            }} className="btn btn-light btn-lg btn-rounded">{translations[language].bannerButton}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>);
};
export default Screen1;
