import React from 'react';
import {SmoothCorners} from 'react-smooth-corners';

const BetaBar = (props) => {
    return (
            <div className="beta-bar">
                <div className="container">
                    <div className="inner">
                        <div className="tool-label">
                            <span className="tool-label__title">{props.name}</span>
                            <SmoothCorners className={'d-inline-flex'}>
                                <span className="badge">Beta</span>
                            </SmoothCorners>
                        </div>
                    </div>
                </div>
            </div>
    );
};
export default BetaBar;
