import React from 'react';

const CheckBox = (props) => {
    return (<>
        <div className="position-absolute translate-middle start-50 top-50">
            <input className={props.active ? 'checkbox checked' : 'checkbox'} id="cbx" type="checkbox"
                   style={{display: 'none'}}/>
            <div className="position-relative">
                <svg className="position-absolute" width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.4875 8.1375L12.8375 0.7875C13.1125 0.5125 13.4625 0.375 13.8875 0.375C14.3125 0.375 14.6625 0.5125 14.9375 0.7875C15.2125 1.0625 15.35 1.4125 15.35 1.8375C15.35 2.2625 15.2125 2.6125 14.9375 2.8875L6.5375 11.2875C6.2375 11.5875 5.8875 11.7375 5.4875 11.7375C5.0875 11.7375 4.7375 11.5875 4.4375 11.2875L0.5375 7.3875C0.2625 7.1125 0.125 6.7625 0.125 6.3375C0.125 5.9125 0.2625 5.5625 0.5375 5.2875C0.8125 5.0125 1.1625 4.875 1.5875 4.875C2.0125 4.875 2.3625 5.0125 2.6375 5.2875L5.4875 8.1375Z"
                          fill={props.active ? '#C7E3FF' : ''}/>
                </svg>
            </div>
        </div>
    </>);
};

export default CheckBox;