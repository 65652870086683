import {gsap} from 'gsap';
import {Flip} from 'gsap/Flip';

gsap.registerPlugin(Flip);

function useFlyImg(cart) {
    const flyImg = (item, callback) => {
        if (!item || !cart) return;

        const state = Flip.getState(item, {props: 'width'});
        item.classList.remove('opacity-0');
        item.classList.remove('position-relative');
        cart.appendChild(item);
        Flip.from(state, {
            duration: 0.5,
            scale: true,
            absolute: true,
            ease: 'back.in(2)',
            onComplete: () => {
                cart.removeChild(item);
                gsap.fromTo(cart.current, {scale: 1.3}, {scale: 1});
                callback();
            },
        });
    };

    return flyImg;
}

export default useFlyImg;
