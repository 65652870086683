import React, {useContext, useEffect, useRef, useState} from 'react';
import {gsap} from 'gsap';
import {ScrollToPlugin} from 'gsap/ScrollToPlugin';
import {ProgressBar} from 'react-bootstrap';
import {Settings} from '../../store';
import translations from '../../data/footprint/lang.json';
import {langSeparator} from '../../hooks/helperFunctions';

const VerticalBusinessLine = (props) => {
    gsap.registerPlugin(ScrollToPlugin);
    const {name, value, businessSector, language, currentValue, maxDiagramValue, subIndustryName} = props;
    const settingsState = useContext(Settings.State);
    const {
        businessValue,
        businessIndustryGroup,
        businessIndustry,
        businessSubIndustry,
        businessSubIndustryValue,
    } = settingsState;
    const [totalValue, setTotalValue] = useState(false);
    const el = useRef(null);
    let maxValue = maxDiagramValue * currentValue * 1000000;
    useEffect(() => {
        setTotalValue(value * currentValue * 1000000);

    }, [businessValue, businessIndustryGroup, businessIndustry, businessSubIndustry, businessSubIndustryValue]);

    /*500 is factory for bigger line percent*/
    const factor = 300;

    return (
            <div className={name === businessSector ? 'progress-list__item mb-4 current' : 'progress-list__item mb-4'}
                 ref={el}>
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <p className="m-0 d-flex align-items-center">

                        <span className={name === businessSector ? 'font-weight-medium' : ''}>{name === businessSector ? subIndustryName : name}
                            <span className="fw-normal">{name === businessSector && ' (' + translations[language].businessSection.chooseSector + ')'}</span></span>
                    </p>
                    <p className="m-0 font-weight-medium">{totalValue.toLocaleString(langSeparator(language), {maximumFractionDigits: 1})}
                        <span className="small"> tCO<sub>2</sub></span></p>
                </div>


                <ProgressBar className={name === businessSector && 'current'}
                             now={(totalValue / maxValue * 100) < 20 ? totalValue / maxValue * factor : totalValue / maxValue * 100}/>
            </div>
    );
};
export default VerticalBusinessLine;
