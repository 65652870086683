import React, {useContext, useEffect, useRef, useState} from 'react';
import Form from 'react-bootstrap/Form';
import translations from '../../../data/footprint/lang.json';
import {NumericFormat} from 'react-number-format';
import {Settings} from '../../../store';
import {gsap} from 'gsap';
import Next from '../Next';
import PersonalFootprintData from '../../../data/footprint/personalFootprint.json';
import InvestmentsFootprintData from '../../../data/footprint/investmentsFootprint.json';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import BusinessModal from '../modals/BusinessModal';
import CompanyRevenueModal from '../modals/CompanyRevenueModal';


const Screen2 = (props) => {
    const {imgel, setScreenTwoLoaded} = props;
    const [separator, setSeparator] = useState(' ');
    const settingsState = useContext(Settings.State);
    const dispatch = useContext(Settings.Dispatch);
    const [isOpen, setIsOpen] = useState(false);
    const [isCompanyRevenueOpen, setIsCompanyRevenueOpen] = useState(false);
    const [activeValidation, setActiveValidation] = useState(false);
    const el = useRef(null);
    const MAX_LIMIT = 1000000000000;


    const {
        language,
        portfolioValue,
        businessOwner,
        activeCurrencyLang,
        portfolioAllocation,
        incomeLevel,
        companyRevenue,
        businessSubIndustry,
        activeTasks,
    } = settingsState;

    const validationIsActive = () => {
        setActiveValidation(true);
    };

    const validationIsDisabled = () => {
        setActiveValidation(false);
    };

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
    });

    const showModal = () => {
        setIsOpen(true);
    };
    const hideModal = () => {
        setIsOpen(false);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && companyRevenue !== 0) {
            hideModal();
            hideCompanyRevenueModal();
        }
    };

    const updateCompanyRevenue = e => {
        if (e.floatValue === undefined) {
            dispatch({type: 'setCompanyRevenue', payload: 0});
        } else {
            dispatch({type: 'setCompanyRevenue', payload: e.floatValue});
        }
    };
    const showCompanyRevenueModal = () => {
        setIsCompanyRevenueOpen(true);
    };
    const hideCompanyRevenueModal = () => {
        setIsCompanyRevenueOpen(false);
    };
    const updatePortfolioValue = e => {
        if (e.floatValue === undefined) {
            dispatch({type: 'setPortfolioValue', payload: 0});
        } else {
            dispatch({type: 'setPortfolioValue', payload: e.floatValue});
        }
    };

    useEffect(() => {
        if (businessOwner === 'no') {
            dispatch({type: 'setBusinessSector', payload: ''});
            dispatch({type: 'setCompanyRevenue', payload: 0});
            dispatch({type: 'setBusinessIndustryGroup', payload: ''});
            dispatch({type: 'setBusinessIndustry', payload: ''});
            dispatch({type: 'setBusinessSubIndustry', payload: ''});
            dispatch({type: 'setBusinessSubIndustryValue', payload: 0});
            const newTaskList = activeTasks.filter((item) => {
                return item.category !== translations[language].menuitems.item3;
            });
            dispatch({type: 'setActiveTasks', payload: newTaskList});
        }
    }, [businessOwner]);

    useEffect(() => {
        if (activeCurrencyLang == 'no' && portfolioAllocation == 'Lokalt och globalt') {
            dispatch({type: 'setPortfolioAllocation', payload: 'Lokalt og globalt'});
        }
        if (activeCurrencyLang == 'se' && portfolioAllocation == 'Lokalt og globalt') {
            dispatch({type: 'setPortfolioAllocation', payload: 'Lokalt och globalt'});
        }

    }, [activeCurrencyLang]);


    useEffect(() => {
        if (language === 'com') {
            setSeparator(',');
        } else {
            setSeparator(' ');
        }
    }, [language]);

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
            });
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        gsap.to(el.current, {opacity: 1});
        gsap.to(imgel.current, {ease: 'expo.out', duration: 0.8, scale: 1.4, filter: 'blur(10px)', opacity: 0.6});
        gsap.to('header', {
            opacity: 0, duration: 0.2, visibility: 'hidden', zIndex: -1,
        });
        setScreenTwoLoaded(true);
    }, []);


    return (<>
        <Form noValidate onSubmit={(e) => {
            e.preventDefault();
        }}>
            <div style={{opacity: 0}} ref={el} className="row justify-content-center">

                <div className="col-lg-12 col-xl-10 d-flex  flex-column gap-4 gap-lg-6">

                    <div className="row">
                        <div className="col-lg-8 col-xl-9">
                            <h2 className="medium special-size-sm mb-lg-4">{translations[language].screen2.title}</h2>
                        </div>
                    </div>

                    <div className="row gx-lg-6 gx-xl-8 d-none d-lg-flex">
                        <div className="col-lg-4">
                            <h4 className="d-flex align-items-center mb-2">
                                {translations[language].menuitems.item1}
                            </h4>
                            <p>{translations[language].menuitems.item1desc}</p>
                        </div>
                        <div className="col-lg-4">
                            <h4 className="d-flex align-items-center mb-2">
                                {translations[language].menuitems.item2}
                            </h4>
                            <p>{translations[language].menuitems.item2desc}</p>
                        </div>
                        <div className="col-lg-4">
                            <h4 className="d-flex align-items-center mb-2">
                                {translations[language].menuitems.item3}
                            </h4>
                            <p>{translations[language].menuitems.item3desc}</p>
                        </div>
                    </div>

                    <div className="row gx-xl-8 g-6">
                        <div className="col-lg-4">
                            <div className="d-lg-none">
                                <h4 className="d-flex align-items-center">
                                    {translations[language].menuitems.item1}
                                </h4>
                                <p>{translations[language].menuitems.item1desc}</p>
                            </div>

                            <Form.Group>
                                <Form.Label
                                        htmlFor={'lifestyle-select'}>{translations[language].screen2.enterIncome}</Form.Label>
                                <Form.Select id="lifestyle-select"
                                             className={incomeLevel === '' && activeValidation && 'is-invalid'}
                                             onChange={(e) => {
                                                 dispatch({type: 'setIncomeLevel', payload: e.target.value});
                                                 dispatch({type: 'setLockCounterUp', payload: false});
                                             }} value={incomeLevel}>
                                    {incomeLevel === '' &&
                                            <option value={''}>{translations[language].screen2.chooseVal}</option>}
                                    {PersonalFootprintData.dropdown.map((value) => <option key={value}
                                            value={value}>{language !== 'com' ? value.replace('.', ',') : value} {translations[language].general.millions} {translations[activeCurrencyLang].general.currency}</option>)}
                                </Form.Select>
                            </Form.Group>
                        </div>
                        <div className="col-lg-4">
                            <div className="d-lg-none">
                                <h4 className="d-flex align-items-center">
                                    {translations[language].menuitems.item2}
                                </h4>
                                <p>{translations[language].menuitems.item2desc}</p>
                            </div>
                            <div className="d-flex flex-column gap-3">
                                <Form.Group>
                                    <Form.Label
                                            htmlFor="investmentValue">{translations[language].screen2.enterPortfolio}</Form.Label>
                                    <NumericFormat
                                            value={portfolioValue === 0 ? '' : portfolioValue}
                                            pattern="[0-9]*"
                                            thousandSeparator={separator}
                                            id="investmentValue"
                                            autoComplete="off"
                                            className={portfolioValue === '' && activeValidation ? 'form-control is-invalid' : 'form-control'}
                                            placeholder={'10 000 000 ' + translations[activeCurrencyLang].general.currency}
                                            onValueChange={(value) => {
                                                updatePortfolioValue(value);
                                                if (value.value != portfolioValue) {
                                                    dispatch({type: 'setLockCounterUp', payload: false});
                                                }
                                            }}
                                            isAllowed={(values) => {
                                                return values.value < MAX_LIMIT;
                                            }}
                                            suffix={' ' + translations[activeCurrencyLang].general.currency}
                                    />
                                </Form.Group>
                                <Form.Group
                                        className="">
                                    <Form.Label className="d-flex align-items-center"
                                                htmlFor="market-select">{translations[language].screen2.enterAllocation}
                                        <OverlayTrigger
                                                overlay={<Tooltip
                                                        className="tooltip-driver">{translations[language].screen2.enterAllocationPopup}</Tooltip>}><span
                                                className={'drivers__item drivers__item--tooltip'}></span></OverlayTrigger></Form.Label>
                                    <Form.Select id="market-select"
                                                 className={portfolioAllocation === '' && activeValidation && 'is-invalid'}
                                                 value={portfolioAllocation} placeholder={'Enter allocation'}
                                                 onChange={(e) => {
                                                     dispatch({
                                                         type: 'setPortfolioAllocation',
                                                         payload: e.target.value,
                                                     });
                                                     dispatch({type: 'setLockCounterUp', payload: false});
                                                 }}>
                                        {portfolioAllocation === '' &&
                                                <option value={''}>{translations[language].screen2.chooseVal}</option>}
                                        {Object.keys(InvestmentsFootprintData[activeCurrencyLang]['tCO2_per_m$_invested']).map((key, index) =>
                                                <option key={key}
                                                        value={key}>{Object.keys(InvestmentsFootprintData[language]['tCO2_per_m$_invested'])[index]}</option>)}

                                    </Form.Select>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="d-flex flex-column gap-3">
                                <div className="d-lg-none">
                                    <h4 className="d-flex align-items-center">
                                        {translations[language].menuitems.item3}
                                    </h4>
                                    <p>{translations[language].menuitems.item3desc}</p>
                                </div>
                                <Form.Group className="w-100">
                                    <Form.Label
                                            htmlFor="business-owner">{translations[language].screen2.businessOwner} </Form.Label>
                                    <Form.Select id="business-owner"
                                                 className={businessOwner === '' && activeValidation && 'is-invalid'}
                                                 onChange={(e) => {
                                                     dispatch({type: 'setBusinessOwner', payload: e.target.value});
                                                     dispatch({type: 'setLockCounterUp', payload: false});
                                                 }} value={businessOwner}>
                                        {businessOwner === '' &&
                                                <option value={''}>{translations[language].screen2.chooseVal}</option>}
                                        <option value={'no'}>{translations[language].general.no}</option>
                                        <option value={'yes'}>{translations[language].general.yes}</option>
                                    </Form.Select>
                                </Form.Group>
                                <div className={businessOwner === 'yes' ? 'd-flex flex-wrap gap-3' : 'd-none'}>
                                    <Form.Group className="w-100">
                                        <Form.Label
                                                htmlFor="company-revenue">{translations[language].screen2.chooseIndustry2}</Form.Label>
                                        <div
                                                className={businessSubIndustry === '' && activeValidation ? 'form-select is-invalid' : 'form-select'}
                                                role="button" onClick={(e) => {
                                            showModal();

                                        }}> {businessSubIndustry !== '' ? (businessSubIndustry.length > 24 ? businessSubIndustry.substring(businessSubIndustry, 24) + '...' : businessSubIndustry) : translations[language].screen2.chooseIndustry}
                                        </div>
                                    </Form.Group>
                                    <Form.Group className={businessSubIndustry !== '' ? 'd-block w-100' : 'd-none'}
                                                onClick={(e) => {
                                                }}>
                                        <Form.Label
                                                htmlFor="company-revenue">{translations[language].screen2.enterRevenueCompany}</Form.Label>
                                        <NumericFormat disabled={businessSubIndustry == '' && `disabled`}
                                                       value={companyRevenue !== 0 ? companyRevenue : ''}
                                                       pattern="[0-9]*"
                                                       thousandSeparator={separator}
                                                       onKeyDown={handleKeyPress}
                                                       id="company-revenue"
                                                       className={companyRevenue === 0 && activeValidation ? 'form-control is-invalid' : 'form-control'}
                                                       placeholder={translations[language].screen2.enterRevenue}
                                                       required
                                                       onValueChange={(value, sourceInfo) => {
                                                           updateCompanyRevenue(value);
                                                           dispatch({type: 'setLockCounterUp', payload: false});
                                                       }}
                                                       isAllowed={(values, sourceInfo) => {
                                                           const {value} = values;
                                                           return value < MAX_LIMIT;
                                                       }}
                                                       suffix={' ' + translations[language].general.millions + ' ' + translations[activeCurrencyLang].general.currency}
                                        />
                                    </Form.Group>

                                    <BusinessModal showCompanyRevenueModal={showCompanyRevenueModal} isOpen={isOpen}
                                                   hideModal={hideModal} separator={separator}/>
                                    <CompanyRevenueModal handleKeyPress={handleKeyPress}
                                                         updateCompanyRevenue={updateCompanyRevenue}
                                                         separator={separator}
                                                         hideBusinessModal={hideModal} isOpen={isCompanyRevenueOpen}
                                                         hideModal={hideCompanyRevenueModal}/>

                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <hr className="m-0"/>
                        <Next validationIsActive={validationIsActive} validationIsDisabled={validationIsDisabled}
                              condition={(portfolioAllocation === '' || incomeLevel === '' || businessOwner === '')}
                              name={translations[language].general.continue}/>
                    </div>
                </div>
            </div>
        </Form>
    </>);
};
export default Screen2;
