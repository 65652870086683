import React, {useEffect, useRef, useState} from 'react';
import {langSeparator} from '../../../hooks/helperFunctions';
import TakeActionLine from './TakeActionLine';
import {gsap} from 'gsap';
import {Settings} from '../../../store';
import sectorCodes from '../../../data/impact/sector-codes.json'

const SectorsColumn = (props) => {
    const {data, totalImpactValue, negativeCalculation} = props;
    const [animateBar, setAnimateBar] = useState(false);
    const progressBarSection = useRef(null);
    const settingsState = React.useContext(Settings.State);
    const {language} = settingsState;
    useEffect(() => {
        setTimeout(() => {
            gsap.to(progressBarSection, {
                scrollTrigger: {
                    trigger: progressBarSection.current, start: 'top 50%', scrub: false,
                }, onStart: () => {
                    setAnimateBar(true);
                },
            });
        }, 500);
    }, []);
    return (<div ref={progressBarSection} className="col-lg-4 formue-animation">
        <div className="d-flex align-items-center mb-4 text-center justify-content-center justify-content-lg-start text-lg-start gap-3">
            <img className="" src={'../media/Vector/Sectors.svg'} alt="Sectors"/>
            <h5 className="">Impact by Sectors</h5>
        </div>

        {Object.keys(data).map((item, index) => {
            const sector = data[item];
            let sectorName = sector.name;
            console.log(sector)
            const percent = (100 * sector.value / totalImpactValue).toFixed(0);
            const value = (percent * negativeCalculation / 100).toLocaleString(langSeparator(language), {maximumFractionDigits: 0});
            return sector.name !== 'Remaining Sectors' && <TakeActionLine animateBar={animateBar} name={sectorCodes[sectorName].name}
                                                                          percent={percent} value={value}/>;
        })}
    </div>);

};

export default SectorsColumn;
