import React, {useEffect, useState, useRef} from "react";
import {gsap,} from "gsap";
import imagesLoaded from "imagesloaded";
import {ProgressBar} from 'react-bootstrap';
import CustomEase from "gsap/CustomEase";

const LoadingScreen = (props) => {
    gsap.registerPlugin(CustomEase);
    const el = useRef(null);
    const elGroup = gsap.utils.selector(el);
    const {setIsLoaded} = props;
    const [percentage, setPercentage] = useState(0);

    useEffect(() => {

        CustomEase.create("custom", "M0,0 C0,0 0.303,-0.008 0.54,0.228 0.655,0.343 0.676,0.571 0.756,0.718 0.856,0.904 1,1 1,1")
        gsap.set(elGroup('svg'), {opacity: 0})

        let i = 0;
        let interval = setInterval(increment, 40);
        const stop = 100;
        gsap.to(elGroup('svg'), {opacity: 1, duration: 4, delay: 1})

        function increment() {
            i = i + 2;
            if (i === stop) {
                clearTimeout(interval)
                imagesLoaded(document.body, function () {
                    setIsLoaded(true);
                    gsap.to(el.current, {
                        height: 0,
                        duration: 1,
                        ease: "custom"
                    })
                });
            }
            setPercentage(i);
        }
    }, []);


    return (
        <div ref={el} className="loading-screen wrap overflow-hidden">
            <div className="vh-100 d-flex w-100 align-items-center position-relative">
                <div className="progressBar position-absolute text-center" style={{width: 300}}>
                    <h3>{percentage}</h3>
                    <ProgressBar now={percentage} style={{height: '6px'}}/>
                </div>
            </div>
        </div>
    )
}

export default LoadingScreen;
