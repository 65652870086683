import React from 'react';
import {ProgressBar} from 'react-bootstrap';
import {langSeparator} from '../../hooks/helperFunctions';

const VerticalLifestyleLine = (props) => {
    const {name, value, maxVal, language} = props;

    let finalVal;
    if (value === maxVal) {
        finalVal = 100;
    } else {
        finalVal = (value * 100) / maxVal;
    }
    return (
            <div className={value === maxVal ? 'col d-flex flex-column active ' : 'col d-flex flex-column'}
                 style={{flex: '1 1'}}>
                <div className="d-flex align-items-end justify-content-center" style={{minHeight: 200, maxHeight: 200}}>
                    <div className="text-center">
                        <div className="mb-2">
                            <p className="m-0 font-weight-medium">
                                 <small className={value === maxVal ? 'font-weight-medium d-block' : 'd-block'}>
                                     {value.toLocaleString(langSeparator(language),{maximumFractionDigits:1})}
                    </small>

                            </p>
                        </div>
                        <ProgressBar style={{height: finalVal * 1.5}}
                                     className={value === maxVal ? 'active progress-bar-vertical mx-auto' : 'progress-bar-vertical mx-auto'}
                                     now={'100%'}/>
                    </div>
                </div>
                <p className="m-0 px-1 mt-2">
                    <small className={value === maxVal ? 'font-weight-medium' : ''}>{name}</small>
                </p>
            </div>);
};
export default VerticalLifestyleLine;