import React, {useState, useEffect, useRef, useContext} from 'react';
import CountUp from 'react-countup';
import {gsap} from 'gsap';
import Next from '../Next';
import {Settings} from '../../../store';
import {
    businessCalculation,
    convertToThousand,
    investmentsCalculation,
    lifeStyleCalculation,
} from '../../../hooks/helperFunctions';
import translations from '../../../data/footprint/lang.json';
import HowWeEstimateModal from '../modals/HowWeEstimateModal';
import useFitText from 'use-fit-text';


const Screen3 = (props) => {
    const {
        imgel,
        showSwiperModal,
    } = props;
    const settingsState = useContext(Settings.State);
    const dispatch = useContext(Settings.Dispatch);
    const {
        language,
        activeCurrencyLang,
        incomeLevel,
        portfolioAllocation,
        portfolioValue,
        companyRevenue,
        businessOwner,
        lifeStyleValue,
        investmentsValue,
        businessSubIndustryValue,
        businessValue,
        screenThirdLoaded,
        lockCounterUp,
        totalImpact,
    } = settingsState;

    const el = useRef(null);
    const [separator, setSeparator] = useState(',');
    const [isHowWeEstimateOpen, setIsHowWeEstimateOpen] = useState(false);
    const [langCode, setLangCode] = useState('no-NO');
    const [roundedDecimals, setRoundedDecimals] = useState(1);
    const [investmentsValueDecimals, setInvestmentsValueDecimals] = useState(1);
    const [businessValueDecimals, setbusinessValueDecimals] = useState(1);

    const {fontSize, ref} = useFitText({
        maxFotnSize: 80,
        minimumFontSize: 40,
    });

    const [numberBlockWidth, setNumberBlockWidth] = useState(null);

    const showHowWeEstimateModal = () => {
        setIsHowWeEstimateOpen(true);
    };
    const hideHowWeEstimateModal = () => {
        setIsHowWeEstimateOpen(false);
    };
    useEffect(() => {
        if (language === 'com') {
            setSeparator('.');
            setLangCode('en-US');
        } else if (language === 'se') {
            setLangCode('sv-SE');
        } else {
            setLangCode('no-NO');
        }
    }, [language]);


    useEffect(() => {
        if (totalImpact > 1000) {
            setRoundedDecimals(0);
        }
    }, [totalImpact]);

    useEffect(() => {
        if (investmentsValue > 1000) {
            setInvestmentsValueDecimals(0);
        }
    }, [investmentsValue]);

    useEffect(() => {
        if (businessValue > 1000) {
            setbusinessValueDecimals(0);
        }
    }, [businessValue]);


    const easingFn = (t, b, c, d) => {
        const ts = (t /= d) * t;
        const tc = ts * t;
        return b + c * (tc + -3 * ts + 3 * t);
    };

    useEffect(() => {
        dispatch({type: 'setLifeStyleValue', payload: lifeStyleCalculation(activeCurrencyLang, incomeLevel)});
        dispatch({
            type: 'setInvestmentsValue',
            payload: investmentsCalculation(activeCurrencyLang, portfolioAllocation, portfolioValue),
        });
        dispatch({
            type: 'setBusinessValue',
            payload: businessCalculation(activeCurrencyLang, businessSubIndustryValue, companyRevenue),
        });
    }, [incomeLevel, portfolioValue, portfolioAllocation, activeCurrencyLang]);


    useEffect(() => {
        dispatch({
            type: 'setTotalImpact',
            payload: lifeStyleValue + investmentsValue + businessValue,
        });

    }, [lifeStyleValue, investmentsValue, businessValue]);


    useEffect(() => {
        if (!screenThirdLoaded) {
            gsap.utils.toArray('.formue-animation').forEach(function(e) {
                let delay = 0;
                let duration = 0.4;
                let offset = 'top 60%';
                if (e.getAttribute('data-animation-delay') !== null) {
                    delay = e.getAttribute('data-animation-delay');
                }

                if (e.getAttribute('data-duration') !== null) {
                    duration = e.getAttribute('data-duration');
                }

                if (e.getAttribute('data-offset') !== null) {
                    offset = e.getAttribute('data-offset');
                }
                gsap.from(e, {
                    duration: duration,
                    opacity: 0,
                    y: 16,
                    delay: delay,
                    scrollTrigger: {
                        trigger: e,
                        start: offset,
                    },
                });
            });
        }
    }, []);

    useEffect(() => {
        gsap.to(el.current,
                {opacity: 1},
        );
        gsap.to(imgel.current,
                {ease: 'expo.out', duration: 0.8, scale: 1.6, filter: 'blur(11px)', opacity: 0.5});

        setNumberBlockWidth(document.getElementById('counter-number-holder').getBoundingClientRect().width - 50);
    }, []);

    function handleResize() {
        setNumberBlockWidth(document.getElementById('counter-number-holder').getBoundingClientRect().width - 50);
    }

    window.addEventListener('resize', handleResize);

    useEffect(() => {
        ref.current.style.fontSize = fontSize;
    }, [totalImpact]);

    return (<>
        <div style={{opacity: 0}} ref={el} className="row justify-content-center gap-4 gap-lg-6">
            <div className="col-lg-12 col-xl-10">
                <div className="row formue-animation">
                    <div className="col-lg-8">
                        <h2 className="medium mb-3">{translations[language].screen3.title}</h2>
                    </div>
                </div>
            </div>
            <div className="col-lg-12 col-xl-10">
                <div className="row align-items-center g-4 formue-animation">
                    <div className="col-lg-7" id="counter-number-holder">

                        <div className="d-flex justify-content-start align-items-baseline" ref={ref}
                             style={{fontSize, width: numberBlockWidth}}>
                            {/*<svg className="pos-value" style={{minWidth: 30}}*/}
                            {/*     xmlns="http://www.w3.org/2000/svg"*/}
                            {/*     height="64px"*/}
                            {/*     viewBox="0 0 24 24"*/}
                            {/*     width="64px" fill="#FFFFFF">*/}
                            {/*    <path className="no-fill" d="M0 0h24v24H0z" fill="none"/>*/}
                            {/*    <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/>*/}
                            {/*</svg>*/}
                            <h2 className="large">
                                {lockCounterUp ? convertToThousand(totalImpact).value.toLocaleString(langCode, {maximumFractionDigits: roundedDecimals}) :
                                        <CountUp
                                                start={0}
                                                preserveValue={true}
                                                end={convertToThousand(totalImpact).value}
                                                duration={2}
                                                delay={0}
                                                separator={'&nbsp;'}
                                                prefix={''}
                                                decimal={separator}
                                                decimals={roundedDecimals}
                                                useEasing={true}
                                                easingFn={easingFn}
                                                enableScrollspy={true}
                                                onEnd={() => {
                                                    dispatch({type: 'setScreenThirdLoaded', payload: true});
                                                    dispatch({type: 'setLockCounterUp', payload: true});
                                                }}>
                                            {({countUpRef, start}) => (
                                                    <span ref={countUpRef}/>
                                            )}
                                        </CountUp>}
                            </h2>
                            <span className="currency-suffix lead ms-2"><strong>{convertToThousand(totalImpact, language).string}
                                <small> tCO<sub>2</sub></small></strong></span>
                        </div>

                    </div>
                    <div className="col-lg-5 formue-animation" data-animation-delay={0.1}>
                        <div className="p-5 rounded mb-2" style={{background: 'rgba(0,0,0,0.45)'}}>
                            <div className="d-flex justify-content-between ">
                                <p className="m-0">{lifeStyleValue.toLocaleString(langCode, {maximumFractionDigits: 2})} tCO<sub>2</sub>
                                </p>
                                <p className="m-0 d-flex align-items-center">{translations[language].menuitems.item1}
                                    <svg className="ms-2" width="20" height="20" viewBox="0 0 20 20" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.0001 2.75C11.3869 2.75 12.5149 3.87825 12.5149 5.26475C12.5149 6.65125 11.3869 7.77975 10.0001 7.77975C8.61335 7.77975 7.48535 6.6515 7.48535 5.265C7.48535 3.8785 8.61335 2.75 10.0001 2.75ZM10.0001 0.75C7.5066 0.75 5.48535 2.77125 5.48535 5.26475C5.48535 7.75825 7.5066 9.77975 10.0001 9.77975C12.4936 9.77975 14.5149 7.75825 14.5149 5.265C14.5149 2.77175 12.4936 0.75 10.0001 0.75Z"
                                              fill="#EAE2D7"/>
                                        <path d="M18.0215 19.25H16.5205C16.3852 19.25 16.2785 19.1422 16.2717 19.0072C16.1242 16.002 13.257 13.4167 10.0002 13.4167C6.74346 13.4167 3.87621 16.002 3.72871 19.0072C3.72196 19.1422 3.61496 19.25 3.47996 19.25H1.97896C1.83846 19.25 1.72221 19.134 1.72721 18.9935C1.87596 14.8605 5.60396 11.4167 10.0002 11.4167C14.3965 11.4167 18.1245 14.8605 18.2732 18.9935C18.2782 19.134 18.162 19.25 18.0215 19.25Z"
                                              fill="white"/>
                                    </svg>

                                </p>
                            </div>
                            <hr className="my-3"/>
                            <div className="d-flex justify-content-between">
                                <p className="m-0">{convertToThousand(investmentsValue).value.toLocaleString(langCode, {maximumFractionDigits: investmentsValueDecimals}) + convertToThousand(investmentsValue, language).string} tCO<sub>2</sub>
                                </p>
                                <p className="m-0 d-flex align-items-center">{translations[language].menuitems.item2}
                                    <svg className="ms-2"
                                         width="20" height="20"
                                         viewBox="0 0 20 20"
                                         fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.51672 11.869L0.483717 10.7812C0.388717 10.6812 0.392717 10.523 0.492967 10.4277L8.30372 2.87248C8.40197 2.77923 8.55672 2.78123 8.65272 2.87698L11.9522 6.17648C12.049 6.27323 12.2057 6.27423 12.3037 6.17848L14.4977 4.05523C14.5965 3.95873 14.7547 3.96048 14.8512 4.05948L15.8992 5.13248C15.9957 5.23123 15.9937 5.38948 15.895 5.48598L12.2875 8.99073C12.1895 9.08648 12.0327 9.08548 11.936 8.98873L8.61647 5.66923C8.52072 5.57348 8.36597 5.57148 8.26747 5.66473L1.87022 11.8782C1.76997 11.9732 1.61172 11.9692 1.51672 11.869Z"
                                              fill="#EAE2D7"/>
                                        <path d="M19 7.5H17.5C17.362 7.5 17.25 7.388 17.25 7.25V3C17.25 2.862 17.138 2.75 17 2.75H12.75C12.612 2.75 12.5 2.638 12.5 2.5V1C12.5 0.862 12.612 0.75 12.75 0.75H19C19.138 0.75 19.25 0.862 19.25 1V7.25C19.25 7.388 19.138 7.5 19 7.5Z"
                                              fill="white"/>
                                        <path d="M16.75 18.5H15.25C15.112 18.5 15 18.388 15 18.25V10.5C15 10.362 15.112 10.25 15.25 10.25H16.75C16.888 10.25 17 10.362 17 10.5V18.25C17 18.388 16.888 18.5 16.75 18.5Z"
                                              fill="white"/>
                                        <path d="M12.75 18.5H11.25C11.112 18.5 11 18.388 11 18.25V12.25C11 12.112 11.112 12 11.25 12H12.75C12.888 12 13 12.112 13 12.25V18.25C13 18.388 12.888 18.5 12.75 18.5Z"
                                              fill="white"/>
                                        <path d="M8.75 18.5H7.25C7.112 18.5 7 18.388 7 18.25V10.25C7 10.112 7.112 10 7.25 10H8.75C8.888 10 9 10.112 9 10.25V18.25C9 18.388 8.888 18.5 8.75 18.5Z"
                                              fill="white"/>
                                        <path d="M4.75 18.5H3.25C3.112 18.5 3 18.388 3 18.25V13.75C3 13.612 3.112 13.5 3.25 13.5H4.75C4.888 13.5 5 13.612 5 13.75V18.25C5 18.388 4.888 18.5 4.75 18.5Z"
                                              fill="white"/>
                                    </svg>
                                </p>
                            </div>
                            <hr className={businessOwner === 'no' ? 'd-none' : ''}/>
                            <div className={businessOwner === 'no' ? 'd-none justify-content-between' : 'd-flex justify-content-between'}>
                                <p className="m-0">{convertToThousand(businessValue).value.toLocaleString(langCode, {maximumFractionDigits: businessValueDecimals}) + convertToThousand(businessValue, language).string} tCO<sub>2</sub>
                                </p>
                                <p className="m-0 d-flex align-items-center">{translations[language].menuitems.item3}
                                    <svg className="ms-2" width="20"
                                         height="20"
                                         viewBox="0 0 20 20"
                                         fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.375 19.5H15.625C15.556 19.5 15.5 19.444 15.5 19.375V2.88001C15.5 2.80076 15.4272 2.74151 15.3497 2.75751L4.22475 5.04476C4.16675 5.05676 4.125 5.10776 4.125 5.16726V19.375C4.125 19.444 4.069 19.5 4 19.5H2.25C2.181 19.5 2.125 19.444 2.125 19.375V3.53651C2.125 3.47726 2.16675 3.42601 2.22475 3.41401L17.3498 0.304258C17.4273 0.288258 17.5 0.347508 17.5 0.426758V19.375C17.5 19.444 17.444 19.5 17.375 19.5Z"
                                              fill="#EAE2D7"/>
                                        <path d="M12.75 8.25H11.25C11.112 8.25 11 8.138 11 8V6.5C11 6.362 11.112 6.25 11.25 6.25H12.75C12.888 6.25 13 6.362 13 6.5V8C13 8.138 12.888 8.25 12.75 8.25Z"
                                              fill="white"/>
                                        <path d="M12.75 11.5H11.25C11.112 11.5 11 11.388 11 11.25V9.75C11 9.612 11.112 9.5 11.25 9.5H12.75C12.888 9.5 13 9.612 13 9.75V11.25C13 11.388 12.888 11.5 12.75 11.5Z"
                                              fill="white"/>
                                        <path d="M12.75 14.75H11.25C11.112 14.75 11 14.638 11 14.5V13C11 12.862 11.112 12.75 11.25 12.75H12.75C12.888 12.75 13 12.862 13 13V14.5C13 14.638 12.888 14.75 12.75 14.75Z"
                                              fill="white"/>
                                        <path d="M12.75 18H11.25C11.112 18 11 17.888 11 17.75V16.25C11 16.112 11.112 16 11.25 16H12.75C12.888 16 13 16.112 13 16.25V17.75C13 17.888 12.888 18 12.75 18Z"
                                              fill="white"/>
                                        <path d="M8.25 19.5H6.75C6.612 19.5 6.5 19.388 6.5 19.25V16.25C6.5 16.112 6.612 16 6.75 16H8.25C8.388 16 8.5 16.112 8.5 16.25V19.25C8.5 19.388 8.388 19.5 8.25 19.5Z"
                                              fill="white"/>
                                        <path d="M8.25 8.25H6.75C6.612 8.25 6.5 8.138 6.5 8V6.5C6.5 6.362 6.612 6.25 6.75 6.25H8.25C8.388 6.25 8.5 6.362 8.5 6.5V8C8.5 8.138 8.388 8.25 8.25 8.25Z"
                                              fill="white"/>
                                        <path d="M8.25 11.5H6.75C6.612 11.5 6.5 11.388 6.5 11.25V9.75C6.5 9.612 6.612 9.5 6.75 9.5H8.25C8.388 9.5 8.5 9.612 8.5 9.75V11.25C8.5 11.388 8.388 11.5 8.25 11.5Z"
                                              fill="white"/>
                                        <path d="M8.25 14.75H6.75C6.612 14.75 6.5 14.638 6.5 14.5V13C6.5 12.862 6.612 12.75 6.75 12.75H8.25C8.388 12.75 8.5 12.862 8.5 13V14.5C8.5 14.638 8.388 14.75 8.25 14.75Z"
                                              fill="white"/>
                                    </svg>
                                </p>
                            </div>
                        </div>
                        <div className="text-end mt-3">
                            <a className="small" onClick={showHowWeEstimateModal}
                               role="button">{translations[language].howWeEstimateFootprint}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-12 col-xl-10">
                <div className="row  formue-animation" data-offset={'top 100%'} data-animation-delay={0.2}>
                    <div className="col-lg-12">
                        <p className=" lead fst-italic">{translations[language].investments_inputs.text4}</p>
                        <hr className="mt-3 mb-5"/>
                    </div>
                    <div className="col-lg-4">
                        <h3>{convertToThousand(0.217 * totalImpact).value.toLocaleString(langCode, {maximumFractionDigits: 1})}</h3>
                        <p className="fst-italic">{convertToThousand(0.217 * totalImpact, language).string} {translations[language].investments_inputs.text1}</p>
                    </div>
                    <div className="col-lg-4">
                        <h3>{convertToThousand(0.12 * totalImpact).value.toLocaleString(langCode, {maximumFractionDigits: 1})}</h3>
                        <p className="fst-italic">{convertToThousand(0.12 * totalImpact, language).string} {translations[language].investments_inputs.text2}</p>
                    </div>
                    <div className="col-lg-4">
                        <h3>{convertToThousand(totalImpact / 1.68).value.toLocaleString(langCode, {maximumFractionDigits: 1})}</h3>
                        <p className="fst-italic">{convertToThousand(totalImpact / 1.68, language).string} {translations[language].investments_inputs.text3}</p>
                    </div>
                    <hr className="my-5"/>
                </div>
                <div className="row  formue-animation" data-offset={'top 100%'} data-animation-delay={0.4}>
                    <div className="col-lg-12">
                        <Next showSwiperModal={showSwiperModal} name={translations[language].general.explore}/>
                    </div>
                </div>
            </div>
            <HowWeEstimateModal isHowWeEstimateOpen={isHowWeEstimateOpen}
                                hideHowWeEstimateModal={hideHowWeEstimateModal}/>
        </div>
    </>);
};
export default Screen3;
