import React, {useContext} from 'react';
import Modal from 'react-bootstrap/Modal';
import translations from '../../../data/footprint/lang.json';
import {Settings} from '../../../store';

const HowWeEstimateModalNImpact = (props) => {
    const settingsState = useContext(Settings.State);
    const {
        language,
    } = settingsState;
    const {
        isHowWeEstimateNImpactOpen, hideHowWeEstimateNImpactModal,
    } = props;

    return (<Modal size="md"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered show={isHowWeEstimateNImpactOpen} onHide={hideHowWeEstimateNImpactModal}
    >
        <div className="modal-header">
            <button onClick={hideHowWeEstimateNImpactModal} type="button" className="btn-close" data-bs-dismiss="modal"
                    aria-label="Close"></button>
        </div>
        <div className="modal-body p-lg-7" dangerouslySetInnerHTML={{__html:translations[language].howWeEstimateNImapctContent}}>
        </div>
    </Modal>);
};

export default HowWeEstimateModalNImpact;