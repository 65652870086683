import React, { useState} from "react";
import {Settings} from "../../../store";
import Modal from "react-bootstrap/Modal";
import TagManager from "react-gtm-module";

const TalkToExpert = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const {showContactModal} = props;
    const showModal = () => {
        setIsOpen(true);
    };
    const hideModal = () => {
        setIsOpen(false);
    };

    return (
        <>
            <button className="btn btn-lg rounded btn-primary" onClick={()=>{
                TagManager.dataLayer({
                    dataLayer: {
                        userProject: 'Impact',
                        event: 'CTAevent',
                        CTAaction: 'Talk to an expert - footer'
                    }
                });
                showModal();
            }}>Talk to an expert</button>
            <Modal className={'modal--experts'} size="xl" dialogClassName={'modal-fullscreen-md-down'}
                   aria-labelledby="contained-modal-title-vcenter"
                   centered show={isOpen} onHide={hideModal}>
                <div className="modal-header">
                    <button onClick={hideModal} type="button" className="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                </div>
                <div className="modal-body px-lg-6">
                    <div className="row mb-7 align-items-start g-5">
                        <div className="col-md-6">
                            <h2>Nordic Sustainable Ownership Center</h2>
                        </div>
                        <div className="col-md-6">
                            <h4 className="lead">If you are a business owner, your business will often account for
                                70 percent of your total footprint. By implementing measures, you can impact the
                                business you own, but also suppliers, customers and employees.
                                <br/><br/>
                                We have established the Nordic Sustainable Ownership Center with experts that can
                                assist our clients towards a net zero society.</h4>
                        </div>
                    </div>
                    <div className="row g-6">
                        <div className="col-md-4">
                            <img className="rounded img-fluid"
                                 src={'../media/Philip-Mitchell-portrait-sustainability-site.png'} alt="person"/>
                            <h4 className="mt-4">Philip Mitchell</h4>
                            <p className="lead small mt-1"><i>Senior Sustainability Advisor</i></p>
                            <p className="mt-3">Philip Mitchell is part of the Nordic Sustainable Ownership Center.
                                Philip comes from Carbon Intelligence and the role of sustainability advisor. He has
                                worked in the banking and finance industry for 25 years, most recently as a manager
                                at Första AP-fonden.
                            </p>
                        </div>
                        <div className="col-md-4">
                            <img className="rounded img-fluid" src={'../media/person2-min.png'} alt="person"/>
                            <h4 className="mt-4">Fridtjof Wergeland</h4>
                            <p className="lead small mt-1"><i>Sustainability Advisor</i></p>
                            <p className="mt-3">Fridtjof Wergeland is part of the Nordic Sustainable Ownership
                                Center as a sustainability advisor. Here he helps customers initiate and strengthen
                                their sustainability work with strategies and reporting. For the past five years,
                                Fridtjof has worked as a sustainability consultant at PwC.</p>
                        </div>
                        <div className="col-md-4">
                            <img className="rounded img-fluid" src={'../media/person3-min.png'} alt="person"/>
                            <h4 className="mt-4">Helena von der Esch</h4>
                            <p className="lead small mt-1"><i>Senior Family Advisor</i></p>
                            <p className="mt-3">Helena von der Esch is one of Formue's foremost experts in ownership
                                advisory for families. Helena is part of the group's expert team that works across
                                Norway and Sweden with advice to families regarding generational changes and
                                governance structures.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box p-5 p-lg-6 mt-6 position-relative">
                                <svg className="position-absolute pictogram" width="177" height="177"
                                     viewBox="0 0 177 177" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_4499_30614)">
                                        <path
                                            d="M166.704 66.3614L150.833 62.1089C150.06 61.9016 149.921 60.8601 150.616 60.4591L173.013 47.5293C173.873 47.0329 174.17 45.9311 173.672 45.0708L168.274 35.7157C167.778 34.8542 166.676 34.5588 165.816 35.057L143.4 47.9987C142.705 48.3996 141.872 47.7608 142.079 46.9871L146.339 31.0887C146.596 30.129 146.026 29.1414 145.067 28.8842L134.635 26.089C133.675 25.8318 132.687 26.402 132.43 27.3617L124.075 58.5412C123.952 59.002 123.651 59.3964 123.237 59.634L117.602 62.8872L117.599 62.8828L80.8759 84.0719C80.0144 84.5678 79.719 85.6701 80.2171 86.5304L85.6136 95.885C86.1096 96.7466 87.2119 97.0419 88.0721 96.5438L121.838 77.0611L121.84 77.0655L130.446 72.0982C130.86 71.8607 131.351 71.7965 131.811 71.9199L162.977 80.2707C163.937 80.5279 164.924 79.9577 165.182 78.9979L167.977 68.5659C168.234 67.6062 167.664 66.6186 166.704 66.3614Z"
                                            fill="#59497A"/>
                                        <path
                                            d="M71.4538 137.631C46.7931 129.331 31.8505 103.45 36.993 77.9431C42.8735 48.771 71.65 30.743 99.9853 37.3329C100.936 37.5541 101.893 37.0074 102.146 36.0633L104.943 25.6261C105.204 24.6524 104.616 23.6339 103.635 23.3991C67.164 14.6796 30.1466 37.877 22.7862 75.5489C16.4735 107.857 35.288 140.444 66.4239 151.132C102.731 163.594 141.329 143.133 152.013 107.19C152.298 106.223 151.712 105.205 150.737 104.943L140.299 102.147C139.355 101.894 138.403 102.449 138.119 103.385C129.659 131.217 99.6595 147.125 71.4538 137.631Z"
                                            fill="#7C599F"/>
                                        <path
                                            d="M78.9166 111.622C68.1136 107.395 61.506 95.9534 63.2485 84.4846C65.4562 69.9445 79.2829 60.7036 93.1071 63.4686C94.0236 63.6508 94.9147 63.0511 95.1561 62.1504L97.9509 51.7202C98.2136 50.7396 97.6192 49.71 96.6287 49.4912C75.2765 44.8116 53.8046 58.4106 49.3532 80.3924C45.4314 99.758 56.7809 119.416 75.5129 125.702C96.7753 132.838 119.288 121.042 125.912 100.211C126.219 99.2443 125.623 98.2143 124.643 97.9516L114.212 95.1568C113.312 94.9155 112.347 95.3874 112.048 96.2709C107.53 109.627 92.6147 116.979 78.9166 111.622Z"
                                            fill="#C76FF2"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_4499_30614">
                                            <rect width="144" height="144" fill="white"
                                                  transform="translate(37.2695) rotate(15)"/>
                                        </clipPath>
                                    </defs>
                                </svg>

                                <h3 className="mb-4">We can help owners with:</h3>
                                <div className="row">
                                    <div className="col-md-6">
                                        <ul className="list-unstyled">
                                            <li className="d-flex align-items-center mb-1">
                                                <svg style={{minWidth: 17}} width="17" height="14"
                                                     viewBox="0 0 17 14"
                                                     fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M13.9393 0.939973C14.2211 0.671751 14.596 0.523467 14.985 0.52641C15.374 0.529353 15.7466 0.683294 16.0242 0.955749C16.3019 1.2282 16.4628 1.59786 16.4731 1.98672C16.4834 2.37558 16.3422 2.75323 16.0793 3.03997L8.09934 13.02C7.96212 13.1678 7.79651 13.2864 7.6124 13.3687C7.4283 13.451 7.22948 13.4954 7.02784 13.4991C6.8262 13.5029 6.62588 13.4659 6.43885 13.3904C6.25182 13.315 6.08193 13.2026 5.93934 13.06L0.647338 7.76797C0.499965 7.63065 0.381761 7.46505 0.299777 7.28105C0.217793 7.09705 0.173709 6.89843 0.170155 6.69702C0.166602 6.49561 0.203651 6.29556 0.279093 6.10878C0.354536 5.922 0.466825 5.75234 0.609262 5.6099C0.7517 5.46746 0.921367 5.35517 1.10814 5.27973C1.29492 5.20429 1.49498 5.16724 1.69638 5.17079C1.89779 5.17434 2.09642 5.21843 2.28041 5.30041C2.46441 5.3824 2.63001 5.5006 2.76734 5.64797L6.95534 9.83397L13.9013 0.983974C13.9138 0.968575 13.9272 0.953885 13.9413 0.939973H13.9393Z"
                                                        fill="#182361"/>
                                                </svg>
                                                <p className="mb-0 ms-3">Measuring the current footprint</p>
                                            </li>
                                            <li className="d-flex align-items-center mb-1">
                                                <svg style={{minWidth: 17}} width="17" height="14"
                                                     viewBox="0 0 17 14"
                                                     fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M13.9393 0.939973C14.2211 0.671751 14.596 0.523467 14.985 0.52641C15.374 0.529353 15.7466 0.683294 16.0242 0.955749C16.3019 1.2282 16.4628 1.59786 16.4731 1.98672C16.4834 2.37558 16.3422 2.75323 16.0793 3.03997L8.09934 13.02C7.96212 13.1678 7.79651 13.2864 7.6124 13.3687C7.4283 13.451 7.22948 13.4954 7.02784 13.4991C6.8262 13.5029 6.62588 13.4659 6.43885 13.3904C6.25182 13.315 6.08193 13.2026 5.93934 13.06L0.647338 7.76797C0.499965 7.63065 0.381761 7.46505 0.299777 7.28105C0.217793 7.09705 0.173709 6.89843 0.170155 6.69702C0.166602 6.49561 0.203651 6.29556 0.279093 6.10878C0.354536 5.922 0.466825 5.75234 0.609262 5.6099C0.7517 5.46746 0.921367 5.35517 1.10814 5.27973C1.29492 5.20429 1.49498 5.16724 1.69638 5.17079C1.89779 5.17434 2.09642 5.21843 2.28041 5.30041C2.46441 5.3824 2.63001 5.5006 2.76734 5.64797L6.95534 9.83397L13.9013 0.983974C13.9138 0.968575 13.9272 0.953885 13.9413 0.939973H13.9393Z"
                                                        fill="#182361"/>
                                                </svg>
                                                <p className="mb-0 ms-3">Create engagement internally and
                                                    externally</p>
                                            </li>
                                            <li className="d-flex align-items-center mb-1">
                                                <svg style={{minWidth: 17}} width="17" height="14"
                                                     viewBox="0 0 17 14"
                                                     fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M13.9393 0.939973C14.2211 0.671751 14.596 0.523467 14.985 0.52641C15.374 0.529353 15.7466 0.683294 16.0242 0.955749C16.3019 1.2282 16.4628 1.59786 16.4731 1.98672C16.4834 2.37558 16.3422 2.75323 16.0793 3.03997L8.09934 13.02C7.96212 13.1678 7.79651 13.2864 7.6124 13.3687C7.4283 13.451 7.22948 13.4954 7.02784 13.4991C6.8262 13.5029 6.62588 13.4659 6.43885 13.3904C6.25182 13.315 6.08193 13.2026 5.93934 13.06L0.647338 7.76797C0.499965 7.63065 0.381761 7.46505 0.299777 7.28105C0.217793 7.09705 0.173709 6.89843 0.170155 6.69702C0.166602 6.49561 0.203651 6.29556 0.279093 6.10878C0.354536 5.922 0.466825 5.75234 0.609262 5.6099C0.7517 5.46746 0.921367 5.35517 1.10814 5.27973C1.29492 5.20429 1.49498 5.16724 1.69638 5.17079C1.89779 5.17434 2.09642 5.21843 2.28041 5.30041C2.46441 5.3824 2.63001 5.5006 2.76734 5.64797L6.95534 9.83397L13.9013 0.983974C13.9138 0.968575 13.9272 0.953885 13.9413 0.939973H13.9393Z"
                                                        fill="#182361"/>
                                                </svg>
                                                <p className="mb-0 ms-3">Establishing a net zero strategy for the
                                                    business</p>
                                            </li>

                                        </ul>
                                    </div>
                                    <div className="col-md-6">
                                        <ul className="list-unstyled">
                                            <li className="d-flex align-items-center mb-1">
                                                <svg style={{minWidth: 17}} width="17" height="14"
                                                     viewBox="0 0 17 14"
                                                     fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M13.9393 0.939973C14.2211 0.671751 14.596 0.523467 14.985 0.52641C15.374 0.529353 15.7466 0.683294 16.0242 0.955749C16.3019 1.2282 16.4628 1.59786 16.4731 1.98672C16.4834 2.37558 16.3422 2.75323 16.0793 3.03997L8.09934 13.02C7.96212 13.1678 7.79651 13.2864 7.6124 13.3687C7.4283 13.451 7.22948 13.4954 7.02784 13.4991C6.8262 13.5029 6.62588 13.4659 6.43885 13.3904C6.25182 13.315 6.08193 13.2026 5.93934 13.06L0.647338 7.76797C0.499965 7.63065 0.381761 7.46505 0.299777 7.28105C0.217793 7.09705 0.173709 6.89843 0.170155 6.69702C0.166602 6.49561 0.203651 6.29556 0.279093 6.10878C0.354536 5.922 0.466825 5.75234 0.609262 5.6099C0.7517 5.46746 0.921367 5.35517 1.10814 5.27973C1.29492 5.20429 1.49498 5.16724 1.69638 5.17079C1.89779 5.17434 2.09642 5.21843 2.28041 5.30041C2.46441 5.3824 2.63001 5.5006 2.76734 5.64797L6.95534 9.83397L13.9013 0.983974C13.9138 0.968575 13.9272 0.953885 13.9413 0.939973H13.9393Z"
                                                        fill="#182361"/>
                                                </svg>
                                                <p className="mb-0 ms-3">Benchmarking</p>
                                            </li>
                                            <li className="d-flex align-items-center mb-1">
                                                <svg style={{minWidth: 17}} width="17" height="14"
                                                     viewBox="0 0 17 14"
                                                     fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M13.9393 0.939973C14.2211 0.671751 14.596 0.523467 14.985 0.52641C15.374 0.529353 15.7466 0.683294 16.0242 0.955749C16.3019 1.2282 16.4628 1.59786 16.4731 1.98672C16.4834 2.37558 16.3422 2.75323 16.0793 3.03997L8.09934 13.02C7.96212 13.1678 7.79651 13.2864 7.6124 13.3687C7.4283 13.451 7.22948 13.4954 7.02784 13.4991C6.8262 13.5029 6.62588 13.4659 6.43885 13.3904C6.25182 13.315 6.08193 13.2026 5.93934 13.06L0.647338 7.76797C0.499965 7.63065 0.381761 7.46505 0.299777 7.28105C0.217793 7.09705 0.173709 6.89843 0.170155 6.69702C0.166602 6.49561 0.203651 6.29556 0.279093 6.10878C0.354536 5.922 0.466825 5.75234 0.609262 5.6099C0.7517 5.46746 0.921367 5.35517 1.10814 5.27973C1.29492 5.20429 1.49498 5.16724 1.69638 5.17079C1.89779 5.17434 2.09642 5.21843 2.28041 5.30041C2.46441 5.3824 2.63001 5.5006 2.76734 5.64797L6.95534 9.83397L13.9013 0.983974C13.9138 0.968575 13.9272 0.953885 13.9413 0.939973H13.9393Z"
                                                        fill="#182361"/>
                                                </svg>
                                                <p className="mb-0 ms-3">Prioritizing the UN Sustainable Development
                                                    Goals</p>
                                            </li>
                                            <li className="d-flex align-items-center mb-1">
                                                <svg style={{minWidth: 17}} width="17" height="14"
                                                     viewBox="0 0 17 14"
                                                     fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M13.9393 0.939973C14.2211 0.671751 14.596 0.523467 14.985 0.52641C15.374 0.529353 15.7466 0.683294 16.0242 0.955749C16.3019 1.2282 16.4628 1.59786 16.4731 1.98672C16.4834 2.37558 16.3422 2.75323 16.0793 3.03997L8.09934 13.02C7.96212 13.1678 7.79651 13.2864 7.6124 13.3687C7.4283 13.451 7.22948 13.4954 7.02784 13.4991C6.8262 13.5029 6.62588 13.4659 6.43885 13.3904C6.25182 13.315 6.08193 13.2026 5.93934 13.06L0.647338 7.76797C0.499965 7.63065 0.381761 7.46505 0.299777 7.28105C0.217793 7.09705 0.173709 6.89843 0.170155 6.69702C0.166602 6.49561 0.203651 6.29556 0.279093 6.10878C0.354536 5.922 0.466825 5.75234 0.609262 5.6099C0.7517 5.46746 0.921367 5.35517 1.10814 5.27973C1.29492 5.20429 1.49498 5.16724 1.69638 5.17079C1.89779 5.17434 2.09642 5.21843 2.28041 5.30041C2.46441 5.3824 2.63001 5.5006 2.76734 5.64797L6.95534 9.83397L13.9013 0.983974C13.9138 0.968575 13.9272 0.953885 13.9413 0.939973H13.9393Z"
                                                        fill="#182361"/>
                                                </svg>
                                                <p className="mb-0 ms-3">Regulatory updates</p>
                                            </li>

                                        </ul>
                                    </div>
                                    <div className="col-md-12 mt-5">
                                        <button onClick={() => {
                                            TagManager.dataLayer({
                                                dataLayer: {
                                                    userProject: 'Impact',
                                                    event: 'CTAevent',
                                                    CTAaction: 'Talk to an expert - inside popup'
                                                }
                                            });
                                            hideModal();
                                            showContactModal();
                                        }}
                                                className="btn btn-primary rounded btn-lg ">Talk with an expert
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </Modal>
        </>
    )
}

export default TalkToExpert;
