import React, {useEffect, useRef, useState, useContext} from 'react';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {Settings} from '../../../store';
import translations from '../../../data/impact/lang.json';
import CountUp from 'react-countup';
import Tooltip from 'react-bootstrap/Tooltip';
import useFitText from 'use-fit-text';
import {langSeparator} from '../../../hooks/helperFunctions';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

const InvestingReturn = (props) => {
    gsap.registerPlugin(ScrollTrigger);
    const {investmentValue, negativeCalculation, negativeImpactAverageFiveYears} = props;
    const settingsState = useContext(Settings.State);
    const {language} = settingsState;
    const el = useRef(null);
    const button1 = useRef(null);
    const button2 = useRef(null);
    const elGroup = gsap.utils.selector(el);
    const [countupNumber, setCountupNumber] = useState(0);
    const [finalCalc, setFinalCalc] = useState((investmentValue * 0.1642));
    const [lockIcon, setLockIcon] = useState(false);
    const [numberBlockWidth, setNumberBlockWidth] = useState(window.innerWidth - 100);
    const [separator, setSeparator] = useState('&nbsp;');
    const {fontSize, ref} = useFitText({
        maxFotnSize: 80,
        minimumFontSize: 40,
    });
    useEffect(() => {
        if (!lockIcon) {
            window.bodymovin.loadAnimation({
                wrapper: button1.current,
                animType: 'svg',
                loop: true,
                path: '../media/arrowDown.json',
            });
            window.bodymovin.loadAnimation({
                wrapper: button2.current,
                animType: 'svg',
                loop: true,
                path: '../media/arrowDown.json',
            });
            setLockIcon(true);
        }
    }, [lockIcon]);

    const updateNumber = () => {
        setCountupNumber(finalCalc);
    };
    useEffect(() => {
        setFinalCalc((investmentValue * 0.1642));
    }, [investmentValue]);

    const easingFn = (t, b, c, d) => {
        const ts = (t /= d) * t;
        const tc = ts * t;
        return b + c * (tc + -3 * ts + 3 * t);
    };

    useEffect(() => {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: el.current,
                start: window.innerHeight / 2.5,
                end: 'bottom',
                invalidateOnRefresh: true,
            },
        });
        tl.call(updateNumber, 0);
        tl.to('#investing_return h2', {
            opacity: 1,
        });

    }, [finalCalc]);

    useEffect(() => {
        button1.current.focus();
        gsap.set('#investing_return h2', {
            opacity: 0,
        });
        gsap.to(elGroup('#negativeImpact'), {
            scrollTrigger: {
                trigger: '#investing_return',
                start: () => {
                    return document.getElementById('banner').clientHeight;
                },
                end: () => {
                    return document.getElementById('banner').clientHeight + (window.innerHeight * 1.2);
                },
                scrub: true,
                invalidateOnRefresh: true,
            },
            clipPath: 'inset(0% 0px 0px)',
        });
    }, []);

    function handleResize() {
        setNumberBlockWidth(window.innerWidth - 100);
    }

    window.addEventListener('resize', handleResize);

    useEffect(() => {
        if (language === 'com') {
            setSeparator(',');
        } else {
            setSeparator('&nbsp;');
        }

    }, [language]);

    return (<>
        <section id="investments" ref={el} data-spy="#investing_return"
                 className="block block--impact block--bg sticky-container ignore-theme">
            <div className="sticky-element">
                <div id="investing_return" className="block__content one">
                    <div className="block__media" style={{backgroundImage: 'url(../media/'}}></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-auto return-number align-items-center d-flex justify-content-center flex-column gap-4">
                                <div>
                                  <p className="impact-label lead">Your five-year average return:</p>
                                  <h2 className="large">
                                    <span className="d-flex justify-content-center align-items-center" ref={ref} style={{fontSize, width: numberBlockWidth}}>
                                            <svg className="pos-value" xmlns="http://www.w3.org/2000/svg"
                                                 height="64px"
                                                 viewBox="0 0 24 24"
                                                 width="64px" fill="#FFFFFF">
                                            <path className="no-fill" d="M0 0h24v24H0z" fill="none"/>
                                            <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/>
                                            </svg>
                                            <CountUp
                                                    start={0}
                                                    end={countupNumber}
                                                    duration={2}
                                                    separator={separator}
                                                    decimals={0}
                                                    useEasing={true}
                                                    // prefix="+"
                                                    suffix=""
                                                    easingFn={easingFn}
                                                    enableScrollspy={true}
                                            >
                                                {({countUpRef, start}) => (
                                                        <span ref={countUpRef}/>
                                                )}
                                            </CountUp>
                                            <span
                                                    className="currency-suffix">{translations[language].general.currency}</span>
                                    </span>
                                  </h2>
                                  <p className="subheader">Based on
                                            <strong> {investmentValue.toLocaleString(langSeparator(language), {maximumFractionDigits: 0})} {translations[language].general.currency}</strong> invested in the <OverlayTrigger delay={{show: 500, hide: 200}}
                                                                overlay={<Tooltip
                                                                        className="tooltip-driver">MSCI World is a stock
                                                                    market
                                                                    index constisting of approximately 1 500 companies
                                                                    throughout
                                                                    the world. It is used as a common benchmark for the
                                                                    global stock
                                                                    market.</Tooltip>}><span

                                                    className={'drivers__item drivers__item--tooltip'}><span
                                                    className="tooltip-info">MSCI World Index<img
                                                    className="tooltip-info__icn" src={'../media/info-2.svg'}
                                                    alt=""/></span>

                                                      </span></OverlayTrigger>
                                  </p>
                                </div>
                            </div>
                        </div>

                        <div className="block__content__footer">
                            <p className="small"><i>See your negative impact</i></p>
                            <button id={'first_button'} ref={button1} type="button"
                                    className="btn btn--circle btn-light btn--svg"
                                    onClick={() => {
                                        gsap.to(window, 0, {
                                            scrollTo: document.getElementById('banner').clientHeight + (document.getElementById('investing_return').clientHeight * 1.2),
                                            ease: 'none',
                                            duration: 2,
                                            delay: 0,
                                        });
                                        button2.current.focus();
                                    }}></button>

                        </div>
                    </div>
                </div>
                <div id="negativeImpact" className="block__content two">
                    <div className="block__media block__media--fade"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-auto align-items-center d-flex justify-content-center flex-column gap-4">
                                <div>
                                    <p className="impact-label lead">Your five-year average negative impact:</p>
                                        <h2 className="large">
                                            <span className="d-flex justify-content-center align-items-center" ref={ref}
                                                  style={{fontSize, width: numberBlockWidth}}>
                                                 <svg className="neg-value" xmlns="http://www.w3.org/2000/svg"
                                                      height="64px"
                                                      viewBox="0 0 24 24"
                                                      width="64px"
                                                      fill="#FFFFFF">
                                                     <path d="M0 0h24v24H0z" className="no-fill" fill="none"/>
                                                     <path d="M19 13H5v-2h14v2z"/>
                                                 </svg>
                                                <span> {negativeCalculation.toLocaleString(langSeparator(language), {maximumFractionDigits: 0})}</span>
                                                <span
                                                        className="currency-suffix">{translations[language].general.currency}</span>
                                            </span>
                                        </h2>

                                    <p className="subheader">Total five-year average negative impact for <OverlayTrigger
                                            delay={{show: 500, hide: 200}}
                                            overlay={<Tooltip
                                                    className="tooltip-driver">MSCI World is a stock market index
                                                constisting of approximately 1 500 companies throughout the world. It is
                                                used as a common benchmark for the global stock market.</Tooltip>}><span

                                            className={'drivers__item drivers__item--tooltip'}><span
                                            className="tooltip-info">MSCI World Index<img
                                            className="tooltip-info__icn" src={'../media/info-2.svg'}
                                            alt=""/></span>

                                                      </span></OverlayTrigger>: <strong>{negativeImpactAverageFiveYears}</strong>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="block__content__footer">
                            <p className="small"><i>What is negative impact?</i></p>

                            <button ref={button2} type="button" className="btn btn--circle btn--svg btn-light"
                                    onClick={() => {
                                        gsap.to(window, 0, {
                                            scrollTo: '#company',
                                            ease: 'none',
                                            duration: 1,
                                            delay: 0,
                                        });
                                    }}></button>

                        </div>
                    </div>

                </div>
            </div>
        </section>
    </>);
};

export default InvestingReturn;
