import React, {useContext} from 'react';
import {Settings} from '../../../store';
import TagManager from 'react-gtm-module';
import Widget from '../widgets/Widget';
import translations from '../../../data/footprint/lang.json';

const CTABlock = (props) => {
    // const settingsState = useContext(Settings.State);
    const {showContactModal, isContactOpen, setIsCalendlyOpen, isCalendlyOpen, setIsContactOpen, language} = props;

    // const {language} = settingsState;

    const hideContactModal = () => {
        setIsContactOpen(false);
    };
    const showCalendlyModal = () => {
        setIsCalendlyOpen(true);
    };
    const hideCalendlyModal = () => {
        setIsCalendlyOpen(false);
    };

    return (<>

                <button className="btn btn-rounded btn-lg btn-primary  pe-auto" onClick={(e) => {
                    e.preventDefault();
                    TagManager.dataLayer({
                        dataLayer: {
                            userProject: 'Footprint Tool 2023',
                            event: 'CTAevent',
                            CTAaction: 'Contact us - footer',
                        },
                    });
                    showContactModal();
                }}>{translations[language].whatcanyoudo.popup.button}
                </button>
                <Widget language={language} isCalendlyOpen={isCalendlyOpen} showContactModal={showContactModal}
                        hideContactModal={hideContactModal} isContactOpen={isContactOpen}
                        showCalendlyModal={showCalendlyModal} hideCalendlyModal={hideCalendlyModal}/>
            </>
    );
};

export default CTABlock;