import React, {useContext, useEffect, useRef, useState} from 'react';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {ScrollToPlugin} from 'gsap/ScrollToPlugin';
import {Settings} from '../../../store';
import Screen1 from './Screen1';
import Screen2 from './Screen2';
import Screen3 from './Screen3';
import Back from './Back';

const Banner = (props) => {
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

    const el = useRef(null);
    const imgel = useRef(null);
    const elGroup = gsap.utils.selector(el);
    const settingsState = useContext(Settings.State);
    const {activeScreen} = settingsState;
    const [swiper, setSwiper] = useState(null);

    const {
        isLoaded,
        setScreenTwoLoaded,
        setScreenThirdLoaded,
        screenThirdLoaded,
        showSwiperModal,
    } = props;

    useEffect(() => {
        gsap.set(elGroup('.word span'), {
            y: 100,
            position: 'relative',
            opacity: 0,
            transitionTimingFunction: 'cubic-bezier(0.19, 1, 0.22, 1)',
            display: 'inline-block',
        });
        gsap.set(elGroup('.lead, form, .badge, .buttons, .lists, svg'), {
            y: 10, position: 'relative', opacity: 0, transitionTimingFunction: 'cubic-bezier(0.19, 1, 0.22, 1)',
        });
    }, []);
    useEffect(() => {
        if (isLoaded === true) {
            setTimeout(() => {
                gsap.to(elGroup('.word span')[0], {y: 0, ease: 'expo.out', duration: 0.8, delay: 0.05, opacity: 1});
                gsap.to(elGroup('.word span')[1], {y: 0, ease: 'expo.out', duration: 0.8, delay: 0.1, opacity: 1});
                gsap.to(elGroup('.word span')[2], {y: 0, ease: 'expo.out', duration: 0.8, delay: 0.15, opacity: 1});
                gsap.to(elGroup('.word span')[3], {y: 0, ease: 'expo.out', duration: 0.8, delay: 0.2, opacity: 1});
                gsap.to(elGroup('.word span')[4], {y: 0, ease: 'expo.out', duration: 0.8, delay: 0.25, opacity: 1});
                gsap.to(elGroup('.word span')[5], {y: 0, ease: 'expo.out', duration: 0.8, delay: 0.27, opacity: 1});
                gsap.to(elGroup('.word span')[6], {y: 0, ease: 'expo.out', duration: 0.8, delay: 0.32, opacity: 1});
                gsap.to(elGroup('.word span')[7], {y: 0, ease: 'expo.out', duration: 0.8, delay: 0.37, opacity: 1});
                gsap.to(elGroup('.word span')[8], {y: 0, ease: 'expo.out', duration: 0.8, delay: 0.42, opacity: 1});
                gsap.to(elGroup('.lead, form, .badge, .buttons, .lists,svg'), {
                    y: 0, opacity: 1,
                });
            }, 900);
        }
    }, [isLoaded]);

    useEffect(() => {
        el.current.scrollTop = 0;
    }, [activeScreen]);


    return (<>
        <div ref={el} className="position-relative">
            <div className="top-bg top-bg--alt overflow-hidden">
                <img ref={imgel} src={'../media/footprint/banner.jpg'} alt=""/>
            </div>
            <section id="banner"
                     className={activeScreen !== 1 ? 'block block--hero flex-row flex-start w-100' : 'block block--hero flex-start w-100'}>
                <div className="container d-flex flex-column justify-content-center py-8">

                    {(activeScreen === 2 || activeScreen === 3) && <Back/>}
                    {activeScreen === 1 && <Screen1 imgel={imgel}/>}
                    {activeScreen === 2 && <Screen2 setScreenTwoLoaded={setScreenTwoLoaded}
                                                    imgel={imgel}
                    />}
                    {activeScreen === 3 && <Screen3 imgel={imgel} swiper={swiper}
                                                    showSwiperModal={showSwiperModal}
                                                    setScreenThirdLoaded={setScreenThirdLoaded}
                                                    screenThirdLoaded={screenThirdLoaded}
                    />}
                </div>
            </section>
        </div>
    </>);
};
export default Banner;
