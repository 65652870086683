import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import sdgRemainingData from '../../../data/impact/sdgremaining-data.json';
import {langSeparator} from '../../../hooks/helperFunctions';
import translations from '../../../data/impact/lang.json';
import sdgData from '../../../data/impact/sdg-data.json';
import {Settings} from '../../../store';
import sectorsData from '../../../data/impact/sectors-data.json';
import {SwiperSlide} from 'swiper/react';
import {ProgressBar} from 'react-bootstrap';
import RemainingSectorsList from '../sector/RemainingSectorsList';


const SDGSectorsRemainingModal = (props) => {
    const {
        isOpenSectorsRemaining,
        hideSectorsModalRemaining,
        negativeCalculation,
        activeSDG,
        remainingSectorsCalc,
        negativeValueForCurrentItem
    } = props;
    const settingsState = React.useContext(Settings.State);
    const {language} = settingsState;
    const [overlayColor, setOverlayColor] = useState('#F2FAF0');
    const [totalImpactValue, setTotalImpactValue] = useState(0);

    useEffect(() => {
        setOverlayColor(activeSDG.overlayColor);
    }, [activeSDG]);

    useEffect(() => {
        let sum = 0;
        Object.keys(sectorsData).forEach((key, index) => {
            sum += sectorsData[key].value;
        });
        setTotalImpactValue(sum);
    }, []);

    const sector = sectorsData['sector1'];


    return (<Modal size="sm" dialogClassName={'modal-fullscreen-md-down'}
                   className={'modal--sectors sdg sdg--' + activeSDG.id}
                   backdrop={true}
                   aria-labelledby="contained-modal-title-vcenter"
                   centered show={isOpenSectorsRemaining} onHide={() => {
        hideSectorsModalRemaining();
    }}
    >
        <div className="overlay-background" style={{background: overlayColor}}></div>
        <div className="modal-header">
            <button onClick={hideSectorsModalRemaining} type="button" className="btn-close" data-bs-dismiss="modal"
                    aria-label="Close"></button>
        </div>
        <div className="modal-body d-flex bg-transparent justify-content-center align-items-center">
            <div className="d-flex flex-column gap-5 w-100">
                <div className="">
                    <h4>Remaining Sectors</h4>
                </div>
                <div className="box d-flex flex-column gap-4">


                    <div className={'grid__item__inner'}>

                        <div className="d-flex mw-100 justify-content-between mb-0">
                            <span>Numbers of Companies</span>
                            <span>{sector.companies}</span>
                        </div>
                        <div className="d-flex mw-100 justify-content-between my-3">
                            <span>Total Negative Impact</span>
                            <span> - {(sector.value * translations[language].general.currencyvalue / 1000000000).toLocaleString(langSeparator(language), {maximumFractionDigits: 0})} {translations[language].general.billions} {translations[language].general.currency}</span>
                        </div>
                        <div className="d-flex mw-100 justify-content-between mb-0">
                            <span><strong>Your Share:</strong></span>
                            <span><strong> - {(negativeValueForCurrentItem - remainingSectorsCalc).toLocaleString(langSeparator(language), {maximumFractionDigits: 0})} {translations[language].general.currency}</strong></span>
                        </div>
                        {/*<hr className="my-4"/>
                                                        <p>Some text</p>*/}

                        <RemainingSectorsList/>
                    </div>

                </div>
            </div>
        </div>
    </Modal>);
};

export default SDGSectorsRemainingModal;
