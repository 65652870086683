import React, {useContext, useEffect, useRef} from 'react';
import {Settings} from '../../../store';
import translations from '../../../data/footprint/lang.json';

const SwiperNavigation = (props) => {
    const {swiper} = props;
    const dispatch = useContext(Settings.Dispatch);
    const settingsState = useContext(Settings.State);
    const {businessValue, language} = settingsState;
    const el = useRef(null);
    const handleClick = (index) => {
        swiper.slideTo(index);
    };

    useEffect(() => {
        if (swiper !== null) {
            swiper.on('transitionEnd', function() {
                document.querySelectorAll('span.swiper-nav-item').forEach((childElement) => {
                    childElement.classList.remove('active');
                });

                const activeChild = document.querySelector('[data-active-swiper="' + swiper.activeIndex + '"]');
                if (activeChild) {

                    activeChild.classList.add('active');
                    if (window.innerWidth < 992) {
                        var target = activeChild;
                        var parentContainer = target.parentNode;
                        var targetWidth = target.offsetWidth;
                        var parentWidth = parentContainer.offsetWidth;
                        var targetPosition = target.offsetLeft - targetWidth / 2;
                        var paddingAndGap = (parentWidth - targetWidth) / 2;
                        parentContainer.scrollLeft = targetPosition - paddingAndGap;
                    }
                }
            });
        }
    }, [swiper]);
    return <>
        <div ref={el} className="d-inline-flex gap-1 gap-md-2 swiper-navigation__nav">
        <span onClick={(e) => {
            dispatch({type: 'setFinalResults', payload: false});
            handleClick(0);
        }} data-active-swiper={0} className="swiper-nav-item active">{translations[language].menuitems.item1}</span>
            <span onClick={(e) => {
                dispatch({type: 'setFinalResults', payload: false});
                handleClick(1);
            }} data-active-swiper={1} className="swiper-nav-item">{translations[language].menuitems.item2}</span>

            {businessValue !== 0 && <span onClick={(e) => {
                dispatch({type: 'setFinalResults', payload: false});
                handleClick(2);
            }} data-active-swiper={2} className="swiper-nav-item">{translations[language].menuitems.item3}</span>}

            <span onClick={(e) => {
                dispatch({type: 'setFinalResults', payload: true});
                handleClick(businessValue !== 0 ? 3 : 2);
            }} data-active-swiper={businessValue !== 0 ? 3 : 2} className="swiper-nav-item">{translations[language].intro.name4}</span>
        </div>
        <div className="d-block d-lg-none" style={{minWidth: 40}}></div>
    </>;
};

export default SwiperNavigation;