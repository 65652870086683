import React from 'react';
import Modal from 'react-bootstrap/Modal';
import {InlineWidget} from 'react-calendly';

const Calendly = (props) => {
    const {showContactModal, hideCalendlyModal, isCalendlyOpen} = props;
    return (<>
        <Modal className={'modal--calendly'} size="sm" dialogClassName={'modal-fullscreen-md-down'}
               aria-labelledby="contained-modal-title-vcenter"
               centered show={isCalendlyOpen} onHide={hideCalendlyModal}>
            <div className="modal-header d-flex justify-cotnent-between">
                <button type="button" className="btn btn-link btn--back" onClick={() => {
                    hideCalendlyModal();
                    showContactModal();
                }}>Go Back
                </button>

                <button onClick={hideCalendlyModal} type="button" className="btn-close" data-bs-dismiss="modal"
                        aria-label="Close"></button>
            </div>
            <div className="modal-body">
                <InlineWidget
                        url="https://calendly.com/formue-no/15min?hide_gdpr_banner=1&background_color=F5F3F0&text_color=2F3A71&primary_color=385795"/>
            </div>
        </Modal>
    </>);
};

export default Calendly;
