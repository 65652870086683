import React, {useRef, useState, useEffect} from 'react';
import {gsap} from 'gsap';
import {Settings} from '../../../store';
import translations from '../../../data/footprint/lang.json';
import TagManager from '../../../services/tag-manager';
import axios from 'axios';
import validator from 'validator';

const EmailSubmissionModal = (props) => {

    const {emailScreen, successMessage, setSuccessMessage} = props;
    const el = useRef(null);
    const [spinner, setSpinner] = useState(false);
    const settingsState = React.useContext(Settings.State);

    const {
        language,
        businessValue,
        investmentsValue,
        lifeStyleValue,
        activeTasks,
        sustainableShare,
        engagement,
        negativeImpact,
        checkboxItems,
        portfolioValue,
        portfolioAllocation,
        incomeLevel,
        businessSector,
        businessSubIndustry,
        companyRevenue,
    } = settingsState;

    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    let translationObject = translations[language].emailpopup;
    const validateFields = () => {
        if (!validator.isMobilePhone(phone)) {
            setPhoneError(translationObject.phone_notification);
        } else {
            setPhoneError('');
        }
        if (!validator.isEmail(email)) {
            setEmailError(translationObject.email_notification);
        } else {
            setEmailError('');
        }

        if (firstName === '') {
            setFirstNameError(translations[language].emailpopup.req_first_name);
        } else {
            setFirstNameError('');
        }
        if (lastName === '') {
            setLastNameError(translations[language].emailpopup.req_last_name);
        } else {
            setLastNameError('');
        }
        return validator.isMobilePhone(phone) && validator.isEmail(email) && (firstName !== '') && (lastName !== '');
    };

    const sendDataToAPI = () => {
        let postApi = process.env.REACT_APP_POST_URL;
        let dataToSend = {
            'data': {
                'type': 'lead-footprint-submission',
                'attributes': {
                    email,
                    firstName,
                    lastName,
                    language,
                    activeTasks,
                    businessValue,
                    investmentsValue,
                    lifeStyleValue,
                    phone,
                    sustainableShare,
                    engagement,
                    negativeImpact,
                    checkboxItems,
                    portfolioValue,
                    portfolioAllocation,
                    incomeLevel,
                    companyRevenue,
                    businessSector,
                    businessSubIndustry,
                },
            },
        };
        axios.post(postApi, dataToSend).then((response) => {
            if (response.status === 201) {
                setSuccessMessage(true);
                // window.location.href = (window.location.href + 'result/' + response.data.data.id);
            } else {
                setSuccessMessage(false);
            }
        }).catch(function(error) {
            console.log(error);
        });
    };

    const submitForm = (e) => {
        e.preventDefault();
        if (validateFields()) {
            setSpinner(true);
            TagManager.dataLayer({
                dataLayer: {
                    userProject: 'Footprint Tool 2023',
                    event: 'formSubmission',
                    formType: 'Results collection',
                    formName: 'Export the results',
                    formCategory: 'Results collection',

                },
            });
            sendDataToAPI();
        } else {
            setSpinner(false);
        }
    };

    useEffect(() => {
        if (emailScreen) {
            gsap.to(el.current, {
                opacity: 1,
            });
            document.body.classList.add('overflow-hidden');
        }
    }, [emailScreen]);

    return (<>
                <div className="modal-content">
                    <div className="modal-body p-0">
                        <div className="container-fluid p-0">
                            <div className="row justify-content-start flex-column flex-lg-row m-0 flex-nowrap">
                                <div className="col-lg-12 p-0 d-flex justify-content-md-center flex-column">
                                    <div className="d-flex flex-column p-4 p-sm-5 p-md-6 pb-9">
                                        <div className="d-flex gap-5 flex-column">
                                            <div>
                                                <h3 className="mb-3">{successMessage ? translations[language].kanban.title : translations[language].emailpopup.text1}</h3>
                                                <p className="">{successMessage ? translations[language].kanban.subtitle : translations[language].emailpopup.text2}</p>
                                            </div>

                                            <form style={{display: successMessage && 'none'}} className="form"
                                                  action="">

                                                <div
                                                        className="form-floating mb-3">
                                                    <input type="text" id="first_name" onChange={(e) => {
                                                        setFirstName(e.target.value);
                                                    }}
                                                           className="form-control"
                                                           placeholder={translations[language].emailpopup.first_name}
                                                           required/>

                                                    <label htmlFor="first_name"
                                                           className="form-label">{translations[language].emailpopup.first_name}</label>
                                                    <div id="emailHelp"
                                                         className="mt-2 form-text me-auto border-0">{firstNameError}</div>
                                                </div>
                                                <div
                                                        className="form-floating mb-3">
                                                    <input type="text" id="last_name" onChange={(e) => {
                                                        setLastName(e.target.value);
                                                    }}
                                                           className="form-control"
                                                           placeholder={translations[language].emailpopup.last_name}
                                                           required/>

                                                    <label htmlFor="first_name"
                                                           className="form-label">{translations[language].emailpopup.last_name}</label>
                                                    <div id="emailHelp"
                                                         className="mt-2 form-text me-auto border-0">{lastNameError}</div>
                                                </div>
                                                <div
                                                        className="form-floating mb-3">
                                                    <input type="text" id="userPhone" onChange={(e) => {
                                                        setPhone(e.target.value);
                                                    }}
                                                           className="form-control"
                                                           placeholder={translations[language].emailpopup.phone_label}
                                                           required/>

                                                    <label htmlFor="userPhone"
                                                           className="form-label">{translations[language].emailpopup.phone_label}</label>
                                                    <div id="emailHelp"
                                                         className="mt-2 form-text me-auto border-0">{phoneError}</div>
                                                </div>
                                                <div className="form-floating mb-3">
                                                    <input type="email" id="user_email" onChange={(e) => {
                                                        setEmail(e.target.value);
                                                    }}
                                                           className="form-control"
                                                           placeholder={translations[language].emailpopup.text3}
                                                           required/>

                                                    <label htmlFor="user_email"
                                                           className="form-label">{translations[language].emailpopup.text3}</label>
                                                    <div id="emailHelp"
                                                         className="mt-2 form-text me-auto border-0">{emailError}</div>
                                                </div>
                                                <div className="d-grid mt-5">
                                                    <input type="submit" disabled={spinner && 'disabled'}
                                                           className={spinner ? 'd-none btn btn-primary btn-lg btn-rounded' : 'btn btn-primary btn-lg btn-rounded'}
                                                           value={translations[language].general.export}
                                                           onClick={submitForm}/>
                                                    <div
                                                            className={spinner ? 'btn btn-primary p-4 position-relative' : 'd-none btn btn-primary p-4 position-relative'}>
                                                        <div className="dot-flashing"></div>
                                                    </div>
                                                </div>
                                                <div className="mt-4" style={{fontSize: 12}}
                                                     dangerouslySetInnerHTML={{__html: translations[language].emailpopup.text4}}></div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    );
};

export default EmailSubmissionModal;