import React, {useContext, useState, useEffect} from 'react';
import {Settings} from '../../store';
import translations from '../../data/footprint/lang.json';
import DeleteModal from './modals/DeleteModal';
import EmailSubmissionModal from './modals/EmailSubmissionModal';
import Modal from 'react-bootstrap/Modal';
import SummaryTask from './SummaryTask';
import {putItemFirst, langSeparator} from '../../hooks/helperFunctions';
import CTABlock from './ctaBlock/CTABlock';
import SummaryNegativeImpactRegular from './SummaryNegativeImpactRegular';
import NegativeImpactsCheckboxes from './NegativeImpactsCheckboxes';
import CheckboxConfirmationModal from './modals/CheckboxConfirmationModal';
import EngagementBars from './EngagementBars';
import Header from './Header';

const Summary = (props) => {

    const backgroundUrl = '../media/summary-lg.svg';
    const settingsState = useContext(Settings.State);
    const dispatch = useContext(Settings.Dispatch);
    const {
        showContactModal,
        resultPage,
        result,
        isCalendlyOpen,
        setIsCalendlyOpen,
        isContactOpen,
        setIsContactOpen,
        hideContactModal,
        neutralsColor,
        sustainableColor,
        transitionColor,
        significantColor,
    } = props;

    const {
        language,
        activeTasks,
        engagement,
        sustainableShare,
        negativeImpact,
        investmentsValue,
        lifeStyleValue,
        businessValue,
        checkboxItems,
        checkboxItemsSkip,
        activeSlide,
        swiper,
    } = result || settingsState;



    const [isOpen, setIsOpen] = useState(false);
    const [element, setElement] = useState(null);
    const [isOpenEmailScreen, setIsOpenEmailScreen] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [isCheckboxScreen, setIsCheckboxScreen] = useState(false);
    const [ctaTopDistance, setCtaTopDistance] = useState(window.innerHeight - 128 + 'px');
    const [invalidValidation, setInvalidValidation] = useState(false);

    const activeList1 = activeTasks.filter((item) => {
        return item.category === translations[language].menuitems.item1;
    });
    const [sustainablePercent, setSustainablePercent] = useState('10% - 20%');

    const activeList3 = activeTasks.filter((item) => {
        return item.category === translations[language].menuitems.item3;
    });
    // putItemFirst(activeList3, 'kanbanitem144');

    const showModal = () => {
        setIsOpen(true);
    };
    const hideModal = () => {
        setIsOpen(false);
    };
    const showEmailScreen = () => {
        setIsOpenEmailScreen(true);
    };
    const hideEmailScreen = () => {
        setIsOpenEmailScreen(false);
    };

    const showCheckboxScreen = () => {
        setIsCheckboxScreen(true);
    };
    const hideCheckboxScreen = () => {
        setIsCheckboxScreen(false);
    };

    useEffect(() => {
        if (negativeImpact.includes('A.')) {
            setInvalidValidation(false);
        }
    }, [negativeImpact]);

    useEffect(() => {

        if (sustainableShare.includes('A.')) {
            setSustainablePercent('0% - 10%');
        }
        if (sustainableShare.includes('B.')) {
            setSustainablePercent('10% - 20%');
        }
        if (sustainableShare.includes('C.')) {
            setSustainablePercent('20% - 30%');
        }

    }, [sustainableShare]);

    useEffect(() => {
        if (negativeImpact.includes('A.') && !checkboxItems.length && !checkboxItemsSkip) {
            dispatch({
                type: 'setAlertTextNegativeImpact',
                payload: 'We have changed your selected negativ impact from B (invest and follow up specific theme) to A (Follow up all themes with focus on climate) because you\'ve not selected any specific preferance to follow.',
            });
            dispatch({type: 'setCheckboxItemsSkip', payload: true});
        } else {
            dispatch({
                type: 'setAlertTextNegativeImpact',
                payload: '',
            });
        }
        if (negativeImpact.includes('B.')) {
            dispatch({type: 'setAlertTextNegativeImpact', payload: ''});
        }
    }, [activeSlide]);


    useEffect(() => {
        if (checkboxItems.length) {
            const element = document.getElementById('negativeImpactBlock');
            element.classList.remove('negative-impact-alert');
            if (activeSlide !== 'summary-block') {
                dispatch({type: 'setCheckboxItemsSkip', payload: false});
            }
        } else {
            dispatch({type: 'setCheckboxItemsSkip', payload: true});

        }
    }, [checkboxItems]);

    const handleResize = () => {
        if (window.innerWidth < 768) {
            setCtaTopDistance(window.innerHeight - 88 + 'px');
        } else {
            setCtaTopDistance(window.innerHeight - 128 + 'px');
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (<>
                <div className={resultPage && 'swiper-slide'} id={resultPage && 'summary-block'}>
                    <div className="block__content">
                        {resultPage && <Header resultPage={resultPage} appName={'Footprint'}/>}
                        <img className="top-img m-auto d-block position-absolute start-50 translate-middle-x"
                             src={backgroundUrl} alt="My Image"/>
                        <div className="position-absolute start-0 end-0 z-n1">
                            <svg width="2440" height="467" viewBox="0 0 2440 467" fill="none"
                                 xmlns="http://www.w3.org/2000/svg"
                                 className="position-absolute top-0 start-50 translate-middle-x h-auto"
                                 style={{width: '130vw'}}>
                                <path d="M1259.07 1.02893C1674.94 5.57879 2031.82 22.8767 2031.82 22.8767L2440 55.9526C2440 55.9526 2440 47.9593 2440 135.441V467H0V135.41C0.000284209 90.9229 0 82.9297 0 82.9297C102.783 71.3766 338.14 45.3852 397.354 38.3634C661.823 6.99785 993.403 -0.056903 1165.51 0.000344494C1198.06 0.361454 1229.21 0.702284 1259.07 1.02893Z"
                                      fill="url(#paint0_linear_1739_13351)"/>
                                <defs>
                                    <linearGradient id="paint0_linear_1739_13351" x1="1220" y1="0" x2="1220" y2="467"
                                                    gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#E6EFE8"/>
                                        <stop offset="1" stopColor="#EFF5F1"/>
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>
                        <div className="container position-relative pt-6 pt-lg-9 pb-5">
                            <div className="row justify-content-center">
                                <div className="col-lg-12 col-xl-10 d-flex flex-column gap-5">
                                    <div className="row justify-content-center text-center">
                                        <div className="col-sm-10 col-lg-9 col-xl-8 d-flex flex-column gap-4">
                                            <div>
                                                <h2 className="medium">{translations[language].summary.title}</h2>
                                                <p className="mb-0 mt-3 preamble">{translations[language].summary.subtitle}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row justify-content-center">
                                        <div className="col-lg-7 d-flex flex-column gap-2">
                                            <div className="d-flex flex-row justify-content-between p-3 border-bottom">
                                                <div className="d-flex flex-row gap-2 align-items-center preamble">
                                                    <strong className="preamble">{translations[language].menuitems.item1}</strong>
                                                </div>
                                                <div>
                                                    <strong className="preamble">{(lifeStyleValue).toLocaleString(langSeparator(language), {maximumFractionDigits: 1})}
                                                    </strong>
                                                    <strong className="ms-1 small"> tCO<sub>2</sub></strong>

                                                </div>

                                            </div>

                                            <div className="d-flex flex-row justify-content-between p-3 border-bottom">
                                                <div className="d-flex flex-row gap-2 align-items-center preamble">


                                                    <strong className="preamble">{translations[language].menuitems.item2}</strong>
                                                </div>
                                                <div>
                                                    <strong className="preamble">{(investmentsValue).toLocaleString(langSeparator(language), {maximumFractionDigits: 1})}
                                                    </strong>
                                                    <strong className="ms-1 small"> tCO<sub>2</sub></strong>

                                                </div>

                                            </div>

                                            <div className="d-flex flex-row justify-content-between p-3">
                                                <div className="d-flex flex-row gap-2 align-items-center preamble">


                                                    <strong className="preamble">{translations[language].menuitems.item3}</strong>
                                                </div>
                                                <div className="d-flex align-items-baseline">
                                                    <strong className="preamble">{(businessValue).toLocaleString(langSeparator(language), {maximumFractionDigits: 1})}
                                                    </strong>
                                                    <strong className="ms-1 small"> tCO<sub>2</sub></strong>

                                                </div>

                                            </div>

                                            <div className="d-flex flex-row justify-content-between p-4 bg-dark">
                                                <div className="d-flex flex-row gap-2 align-items-center preamble">
                                                    <h4>{translations[language].general.total}</h4>
                                                </div>
                                                <div className="d-flex align-items-baseline">
                                                    <h4 className="">{(businessValue + lifeStyleValue + investmentsValue).toLocaleString(langSeparator(language), {maximumFractionDigits: 1})}
                                                    </h4><strong className="ms-2 small"> tCO<sub>2</sub></strong>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="container position-sticky z-2 pt-2 pt-lg-4 pe-none"
                             style={{top: ctaTopDistance}}>
                            <div className="row justify-content-center">
                                <div className="col-lg-7 text-center">
                                    {!resultPage ?
                                            <button className={'btn btn-primary btn-rounded btn-lg pe-auto'}
                                                    onClick={() => {

                                                        if (negativeImpact.includes('B.') && !checkboxItems.length) {
                                                            showCheckboxScreen();
                                                        } else {
                                                            showEmailScreen();
                                                        }
                                                    }}>{translations[language].general.export}
                                            </button> : <CTABlock
                                                    language={language}
                                                    isCalendlyOpen={isCalendlyOpen}
                                                    setIsCalendlyOpen={setIsCalendlyOpen}
                                                    isContactOpen={isContactOpen} setIsContactOpen={setIsContactOpen}
                                                    showContactModal={showContactModal}
                                                    hideContactModal={hideContactModal}/>}

                                </div>
                            </div>
                        </div>
                        <div className="container position-relative py-6 py-lg-9 mt-7">
                            <svg width="2440" height="467" viewBox="0 0 2440 467" fill="none"
                                 xmlns="http://www.w3.org/2000/svg"
                                 className="position-absolute top-0 start-50 translate-middle-x h-auto z-n1"
                                 style={{width: '130vw'}}>
                                <path d="M1259.07 1.02893C1674.94 5.57879 2031.82 22.8767 2031.82 22.8767L2440 55.9526C2440 55.9526 2440 47.9593 2440 135.441V467H0V135.41C0.000284209 90.9229 0 82.9297 0 82.9297C102.783 71.3766 338.14 45.3852 397.354 38.3634C661.823 6.99785 993.403 -0.056903 1165.51 0.000344494C1198.06 0.361454 1229.21 0.702284 1259.07 1.02893Z"
                                      fill="url(#paint0_linear_1739_13351)"/>
                                <defs>
                                    <linearGradient id="paint0_linear_1739_13351" x1="1220" y1="0" x2="1220" y2="467"
                                                    gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#E6EFE8"/>
                                        <stop offset="1" stopColor="#EFF5F1"/>
                                    </linearGradient>
                                </defs>
                            </svg>
                            <div className="row justify-content-center">
                                <div className="col-lg-12 col-xl-10 d-flex flex-column gap-6 gap-lg-7">
                                    <div className="row justify-content-center text-center">
                                        <div className="col-md-10 col-lg-9 col-xl-8 d-flex flex-column gap-4">
                                            <h2 className="">{translations[language].investmentsSection.sustprofilename}</h2>
                                        </div>
                                    </div>

                                    <div className="row row-cols-1 row-cols-lg-2 g-2">

                                        <div className="col">
                                            <div className="card h-100 rounded bg-light p-5 p-lg-7">
                                                <div className="d-flex align-items-center flex-row gap-3 border-bottom pb-4">

                                                    <div>
                                                        <h4 className="large">{translations[language].investmentsSection.sustainableShare}</h4>
                                                    </div>
                                                </div>

                                                <div className="pt-4">
                                                    <p className="mb-0"><strong>{sustainableShare}</strong></p>
                                                </div>

                                                <div className="d-flex flex-column mt-4 gap-3">

                                                    <div className="d-flex flex-row align-items-center gap-4">
                                                        <svg style={{minWidth: 120}} width="164" height="160"
                                                             className="flex-shrink-1" viewBox="0 0 164 160"
                                                             fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M142.648 109.064C138.481 121.888 130.849 133.31 120.596 142.067C110.343 150.824 97.8684 156.575 84.5507 158.684C71.233 160.794 57.5914 159.179 45.1341 154.019C32.6768 148.859 21.889 140.355 13.9635 129.446C6.03794 118.538 1.28336 105.65 0.225402 92.2081C-0.832559 78.766 1.84731 65.2931 7.96874 53.279C14.0902 41.265 23.4147 31.1777 34.9114 24.1325C46.4081 17.0872 59.6291 13.3584 73.1128 13.3584L73.1131 86.4715L142.648 109.064Z"
                                                                  fill="#2E4A37"/>
                                                            <path d="M80.8865 9C92.4245 9 103.799 11.7307 114.079 16.9688C124.36 22.207 133.254 29.8038 140.036 39.1383C146.818 48.4727 151.294 59.2797 153.099 70.6756C154.904 82.0716 153.987 93.7329 150.421 104.706L80.8865 82.1131V9Z"
                                                                  fill="#ABD4A3"/>
                                                            <path d="M150.423 59.5054C155.206 74.1713 155.192 89.9647 150.382 104.622L80.5 82L150.423 59.5054Z"
                                                                  fill="#567E59"/>
                                                            <path d="M124.459 23.4952C136.899 32.7153 146.08 45.6101 150.687 60.3313L80.5 82L124.459 23.4952Z"
                                                                  fill="#7B9B7E"/>
                                                            <path d="M157.204 106.197C162.739 94.0952 164.955 80.7388 163.624 67.4981C162.293 54.2575 157.463 41.6094 149.629 30.8517C141.796 20.0941 131.242 11.6144 119.049 6.28277C106.856 0.951113 93.4647 -1.04047 80.2482 0.512379L80.3268 1.18144C93.4246 -0.357468 106.696 1.61623 118.779 6.89999C130.862 12.1838 141.322 20.5873 149.085 31.2483C156.848 41.9093 161.634 54.4438 162.954 67.5655C164.273 80.6872 162.077 93.9237 156.591 105.917L157.204 106.197Z"
                                                                  fill="#567E59"/>
                                                        </svg>
                                                        <h2 className="mb-3 w-100">{sustainablePercent}</h2>
                                                    </div>
                                                    <div>
                                                        <p className="mb-0">{sustainablePercent} {translations[language].summary.sustainability}</p>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                        <div className="col">
                                            <div className="card h-100 rounded bg-light p-5 p-lg-7">
                                                <div className="d-flex align-items-center flex-row gap-3 border-bottom pb-4">
                                                    <div>
                                                        <h4 className="large">{translations[language].summary.engagemant}</h4>
                                                    </div>
                                                </div>

                                                <div className="mt-4">
                                                    <p className="mb-2"><strong>{engagement}</strong></p>
                                                    <p dangerouslySetInnerHTML={{__html: engagement.includes('A.') ? translations[language].summary.engagemant1 : engagement.includes('B.') ? translations[language].summary.engagemant2 : translations[language].summary.engagemant3}}></p>
                                                </div>
                                                <div className="mt-4">

                                                    {(engagement.includes('A.') || engagement.includes('B.')) &&
                                                            <EngagementBars type={engagement.includes('B.') ? 'b' : 'a'}
                                                                            sustainableColor={sustainableColor}
                                                                            neutralsColor={neutralsColor}
                                                                            transitionColor={transitionColor}
                                                                            significantColor={significantColor}/>}
                                                    {!engagement.includes('C.') && <hr className="my-4"/>}
                                                    {!engagement.includes('C.') &&
                                                            <div className="d-flex align-items-center">
                                                                <svg className="me-3" width="24" height="24"
                                                                     viewBox="0 0 24 24"
                                                                     fill="none"
                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                    <mask id="mask0_1484_20895"
                                                                          style={{maskType: 'alpha'}}
                                                                          maskUnits="userSpaceOnUse" x="0" y="0"
                                                                          width="25"
                                                                          height="24">
                                                                        <rect width="21.25" height="21.2495"
                                                                              transform="matrix(0.990269 -0.139169 0.139177 0.990268 0 2.9574)"
                                                                              fill="#D9D9D9"/>
                                                                    </mask>
                                                                    <g mask="url(#mask0_1484_20895)">
                                                                        <path d="M11.3563 20.1374C10.8741 20.2051 10.4373 20.0916 10.046 19.7967C9.65396 19.5013 9.42407 19.1125 9.35629 18.6303L8.10247 9.70906C8.06961 9.47526 8.0859 9.24557 8.15136 9.02C8.21632 8.79509 8.31787 8.59097 8.45603 8.40764L12.5473 3.00456C12.7377 2.76917 12.9798 2.60849 13.2735 2.52251C13.5666 2.43661 13.8623 2.44721 14.1604 2.55432C14.4586 2.66143 14.6882 2.83779 14.8491 3.08341C15.0105 3.32895 15.0743 3.59567 15.0402 3.88359L14.6206 8.05539L19.3992 7.38382C19.8668 7.3181 20.3007 7.43596 20.7007 7.73738C21.1007 8.0388 21.3335 8.42332 21.3993 8.89093L21.6457 10.6445C21.6601 10.7468 21.6644 10.8579 21.6586 10.978C21.6534 11.0979 21.6361 11.2121 21.6066 11.3206L19.845 17.8715C19.7545 18.1822 19.5702 18.4615 19.2921 18.7092C19.014 18.9569 18.7069 19.1044 18.3708 19.1516L11.3563 20.1374ZM6.09554 20.8767C5.6133 20.9445 5.17651 20.8309 4.78517 20.536C4.39316 20.2406 4.16326 19.8518 4.09549 19.3696L2.98645 11.4786C2.91868 10.9964 3.03249 10.5593 3.32789 10.1673C3.62279 9.77594 4.01136 9.54639 4.4936 9.47861C4.97584 9.41084 5.41263 9.5244 5.80398 9.81929C6.19599 10.1147 6.42588 10.5035 6.49366 10.9857L7.60269 18.8767C7.67047 19.3589 7.55665 19.7961 7.26125 20.1881C6.96635 20.5794 6.57778 20.8089 6.09554 20.8767Z"
                                                                              fill="#437047"/>
                                                                    </g>
                                                                </svg>
                                                                <p className=" m-0 font-weight-medium">{translations[language].investments_content.strategy_2.question_1.remember_1}</p>
                                                            </div>}
                                                    {!engagement.includes('C.') &&
                                                            <div className="d-flex align-items-center mt-2">
                                                                <svg className="me-3" width="24" height="24"
                                                                     viewBox="0 0 24 24"
                                                                     fill="none"
                                                                     xmlns="http://www.w3.org/2000/svg">
                                                                    <mask id="mask0_1484_20901"
                                                                          style={{maskType: 'alpha'}}
                                                                          maskUnits="userSpaceOnUse" x="-1" y="0"
                                                                          width="26"
                                                                          height="24">
                                                                        <rect width="21.25" height="21.2495"
                                                                              transform="matrix(0.990269 0.139169 -0.139177 0.990268 2.95703 0)"
                                                                              fill="#D9D9D9"/>
                                                                    </mask>
                                                                    <g mask="url(#mask0_1484_20901)">
                                                                        <path d="M3.91728 15.676C3.44966 15.6103 3.06513 15.3775 2.7637 14.9775C2.46227 14.5775 2.34441 14.1437 2.41014 13.676L2.65659 11.9225C2.67096 11.8202 2.69733 11.7121 2.73568 11.5983C2.77403 11.4845 2.82231 11.3795 2.88053 11.2834L6.37968 5.47178C6.55227 5.198 6.80639 4.98039 7.14202 4.81894C7.47765 4.65748 7.81352 4.60037 8.14963 4.64761L15.164 5.63339C15.6463 5.70117 16.035 5.93089 16.3301 6.32255C16.6253 6.71422 16.739 7.15117 16.6712 7.63339L15.4174 16.5546C15.3845 16.7884 15.3057 17.0046 15.1809 17.2031C15.0561 17.4017 14.9021 17.57 14.7188 17.7082L9.29669 21.7743C9.04874 21.948 8.7719 22.0358 8.46616 22.0375C8.16043 22.0393 7.879 21.9476 7.62188 21.7624C7.36477 21.5773 7.19259 21.3444 7.10534 21.064C7.0181 20.7835 7.03052 20.5095 7.14261 20.2422L8.69585 16.3476L3.91728 15.676ZM20.4248 6.37273C20.9071 6.4405 21.2958 6.67022 21.5909 7.06189C21.8861 7.45356 21.9998 7.8905 21.932 8.37273L20.8229 16.2637C20.7552 16.746 20.5255 17.1346 20.1338 17.4298C19.7421 17.7249 19.3051 17.8386 18.8229 17.7708C18.3407 17.7031 17.952 17.4733 17.6568 17.0817C17.3617 16.69 17.248 16.2531 17.3157 15.7708L18.4248 7.87984C18.4926 7.39761 18.7223 7.00893 19.114 6.71378C19.5056 6.41864 19.9426 6.30496 20.4248 6.37273Z"
                                                                              fill="#96355D"/>
                                                                    </g>
                                                                </svg>
                                                                <p className=" m-0 font-weight-medium">{translations[language].investments_content.strategy_2.question_1.remember_2}</p>
                                                            </div>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col" id="negativeImpactBlock">
                                            <div className={'card h-100 rounded bg-light p-5 p-lg-7 ' + ((!checkboxItems.length && negativeImpact.includes('B.') && invalidValidation) && 'form-control is-invalid')}>
                                                <div className="d-flex align-items-center flex-row gap-3 border-bottom pb-4">
                                                    <div>
                                                        <h4 className="large">{translations[language].investmentsSection.negativeImpacts}</h4>
                                                    </div>
                                                </div>

                                                <div className="pt-4">
                                                    <p className="mb-2"><strong>{negativeImpact}</strong></p>
                                                    <p>{negativeImpact.includes('A.') ? translations[language].summary.negative1 : translations[language].summary.negative2}</p>
                                                    {/*<p style={{display: !checkboxItems.length && negativeImpact.includes('B.') && invalidValidation ? 'block' : 'none'}}*/}
                                                    {/*   className="theme-text text-decoration-underline"><strong>Please*/}
                                                    {/*    select themes.</strong></p>*/}

                                                </div>

                                                <div className="row mt-2 g-4"
                                                     data-selected-list={negativeImpact.includes('B.') ? 'b' : 'a'}>
                                                    {checkboxItemsSkip && negativeImpact.includes('B.') ?
                                                            <NegativeImpactsCheckboxes result={result}
                                                                                       invalidValidation={invalidValidation}/> :
                                                            <SummaryNegativeImpactRegular result={result}
                                                                                          checkboxItems={checkboxItems}/>}

                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container position-relative py-6 py-lg-9">
                            <svg width="2440" height="467" viewBox="0 0 2440 467" fill="none"
                                 xmlns="http://www.w3.org/2000/svg"
                                 className="position-absolute top-0 start-50 translate-middle-x h-auto z-n1"
                                 style={{width: '130vw'}}>
                                <path d="M1259.07 1.02893C1674.94 5.57879 2031.82 22.8767 2031.82 22.8767L2440 55.9526C2440 55.9526 2440 47.9593 2440 135.441V467H0V135.41C0.000284209 90.9229 0 82.9297 0 82.9297C102.783 71.3766 338.14 45.3852 397.354 38.3634C661.823 6.99785 993.403 -0.056903 1165.51 0.000344494C1198.06 0.361454 1229.21 0.702284 1259.07 1.02893Z"
                                      fill="url(#paint0_linear_1739_13351)"/>
                                <defs>
                                    <linearGradient id="paint0_linear_1739_13351" x1="1220" y1="0" x2="1220" y2="467"
                                                    gradientUnits="userSpaceOnUse">
                                        <stop stopColor="#ECF3EE"/>
                                        <stop offset="1" stopColor="#F5F9F6"/>
                                    </linearGradient>
                                </defs>
                            </svg>

                            <div className="row justify-content-center">
                                <div className="col-lg-8 d-flex flex-column gap-6 position-relative">

                                    <div className="row justify-content-center text-center">
                                        <div className="col-md-10 col-lg-8 d-flex flex-column gap-4">
                                            <h2 className="">{translations[language].summary.actiontitle}</h2>
                                            <p style={{display: activeTasks.length === 0 && 'none'}}
                                               className="preamble m-0">{activeList3.length !== 0 ? translations[language].summary.actionsubtitle2 : translations[language].summary.actionsubtitle}</p>
                                            <p className="preamble m-0"> {activeTasks.length === 0 && translations[language].nothinghere}</p>
                                        </div>
                                    </div>


                                    <div style={{display: activeList1.length === 0 && 'none'}}
                                         className="row row-cols-1 g-2 ">
                                        {!activeList1.length && !activeList3.length && ''}
                                        {activeList1.length !== 0 &&
                                                <h4 className="large mb-3">{translations[language].menuitems.item1}</h4>}
                                        {activeList1.map((item) => {
                                            return <SummaryTask resultPage={resultPage} setElement={setElement}
                                                                key={item.id} item={item}
                                                                showModal={showModal}/>;
                                        })}
                                    </div>


                                    <div style={{display: activeList3.length === 0 && 'none'}}
                                         className="row row-cols-1 g-2 ">

                                        {activeList3.length !== 0 &&
                                                <h4 className="large mb-3">{translations[language].menuitems.item3}</h4>}
                                        {activeList3.map((item) => {
                                            return <SummaryTask resultPage={resultPage}
                                                                showContactModal={showContactModal}
                                                                setElement={setElement}
                                                                key={item.id} item={item}
                                                                showModal={showModal}/>;
                                        })}
                                    </div>

                                    {!resultPage &&
                                            <DeleteModal element={element} isOpen={isOpen} hideModal={hideModal}/>}
                                    <div style={{display: successMessage || resultPage && 'none'}}
                                         className="row position-relative zIndex-3">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <CheckboxConfirmationModal swiper={swiper} isCheckboxModalOpen={isCheckboxScreen}
                                               setInvalidValidation={setInvalidValidation}
                                               hideCheckboxModal={hideCheckboxScreen} summaryPage={true}/>
                    {!resultPage && <Modal size="sm"
                                           aria-labelledby="contained-modal-title-vcenter"
                                           centered show={isOpenEmailScreen} onHide={hideEmailScreen}
                                           dialogClassName="modal-fullscreen-md-down"
                    >
                        <div className="overlay-background"></div>
                        <div className="modal-header justify-content-end">
                            <button onClick={hideEmailScreen} type="button" className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                        <EmailSubmissionModal successMessage={successMessage} setSuccessMessage={setSuccessMessage}/>
                    </Modal>}
                </div>
            </>
    );
};

export default Summary;