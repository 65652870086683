import React, {useState, useRef, useContext} from 'react';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {ScrollToPlugin} from 'gsap/ScrollToPlugin';
import LifeStyle from '../LifeStyle';
import {Swiper, SwiperSlide} from 'swiper/react';
import Investments from '../Investments';
import SwiperHeader from './SwiperHeader';
import Business from '../Business';
import Summary from '../Summary';

import {Settings} from '../../../store';
import SwiperCart from './SwiperCart';
import Modal from 'react-bootstrap/Modal';

const SwiperGroup = () => {
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
    const el = useRef(null);
    const [swiper, setSwiper] = useState(null);
    const settingsState = useContext(Settings.State);
    const dispatch = useContext(Settings.Dispatch);
    const {
        businessValue, isSwiperModalOpen, activeSlide, neutralsColor,
        sustainableColor,
        transitionColor,
        significantColor,
    } = settingsState;
    const [isCalendlyOpen, setIsCalendlyOpen] = useState(false);
    const [isContactOpen, setIsContactOpen] = useState(false);
    const hideContactModal = () => {
        setIsContactOpen(false);
    };
    const showContactModal = () => {
        setIsContactOpen(true);
    };
    const hideModal = () => {
        dispatch({type: 'setIsSwiperModalOpen', payload: false});
    };

    return (<>
        <Modal size="xl"
               fullscreen={true}
               scrollable={true}
               dialogClassName={'modal-fullscreen-md-down'}
               aria-labelledby="contained-modal-title-vcenter"
               className="p-0"
               centered show={isSwiperModalOpen} onHide={hideModal}
        >
            <div className="modal-body p-0">
                <SwiperCart showContactModal={showContactModal}/>
                <div id={'swiper-group'} className="block overflow-auto" ref={el}>
                    <SwiperHeader swiper={swiper} activeSlide={activeSlide}/>
                    <Swiper
                            onSwiper={(swiper) => setSwiper(swiper)}
                            className="m-0"
                            cssMode={false}
                            slidesPerView={1}
                            loop={false}
                            autoHeight={false}
                            allowTouchMove={false}
                            mousewheel={false}
                            keyboard={false}
                            centeredSlides={true}
                            onSlideChange={() => {
                                if (swiper !== null) {
                                    swiper.on('transitionEnd', function() {
                                        dispatch({type: 'setSwiperMove', payload: swiper.activeIndex});

                                        switch (swiper.activeIndex) {
                                            case 0:
                                                dispatch({type: 'setActiveSlide', payload: 'lifestyle-block'});
                                                break;
                                            case 1:
                                                dispatch({type: 'setActiveSlide', payload: 'investments-block'});
                                                break;
                                            case 2:
                                                if (swiper.slides.length === 3) {
                                                    dispatch({type: 'setActiveSlide', payload: 'summary-block'});
                                                } else {
                                                    dispatch({type: 'setActiveSlide', payload: 'business-block'});
                                                }
                                                break;
                                            case 3:
                                                dispatch({type: 'setActiveSlide', payload: 'summary-block'});
                                                break;
                                            default:
                                                dispatch({type: 'setActiveSlide', payload: 'summary-block'});
                                        }
                                    });
                                }
                            }}
                    >
                        <SwiperSlide id="lifestyle-block" key={1}>
                            <LifeStyle swiper={swiper}/>
                        </SwiperSlide>
                        <SwiperSlide id="investments-block" key={2}>
                            <Investments swiper={swiper} sustainableColor={sustainableColor}
                                         neutralsColor={neutralsColor} transitionColor={transitionColor}
                                         significantColor={significantColor}/>
                        </SwiperSlide>
                        {businessValue !== 0 && <SwiperSlide id="business-block" key={3}>
                            <Business swiper={swiper} isCalendlyOpen={isCalendlyOpen}
                                      setIsCalendlyOpen={setIsCalendlyOpen}
                                      isContactOpen={isContactOpen} setIsContactOpen={setIsContactOpen}
                                      showContactModal={showContactModal} hideContactModal={hideContactModal}/>
                        </SwiperSlide>}
                        <SwiperSlide id="summary-block" key={4}>
                            <Summary swiper={swiper} isCalendlyOpen={isCalendlyOpen}
                                     setIsCalendlyOpen={setIsCalendlyOpen}
                                     isContactOpen={isContactOpen} setIsContactOpen={setIsContactOpen}
                                     sustainableColor={sustainableColor} neutralsColor={neutralsColor}
                                     transitionColor={transitionColor} significantColor={significantColor}
                                     showContactModal={showContactModal} hideContactModal={hideContactModal}/>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </Modal>
    </>);
};
export default SwiperGroup;