import React from 'react';
import sectorsData from '../../../data/impact/sectors-data.json';
import {langSeparator} from '../../../hooks/helperFunctions';
import translations from '../../../data/impact/lang.json';
import {Settings} from '../../../store';
import sectorCodes from '../../../data/impact/sector-codes.json'

const SectorCard = (props) => {
    const {
        name,
        showModal,
        cardID,
        setActiveSector,
        percent,
        img,
        grid,
        negativeCalculation,
    } = props;
    const settingsState = React.useContext(Settings.State);
    const {language} = settingsState;
    let image;
    if (img) {
        image = React.createElement(
                'img',
                {src: '../media/' + img, className: 'position-absolute w-100'},
                null,
        );
    }

    return (<div style={{gridArea: grid}}
                 onClick={() => {
                     setActiveSector(sectorsData['sector' + cardID]);
                     setTimeout(() => {
                         showModal();
                     }, 200);
                 }}
                 data-id={cardID}
                 className={'card grid__item'}>
                {image}
                <span className="label">-{(percent * negativeCalculation / 100).toLocaleString(langSeparator(language), {maximumFractionDigits: 0})} {translations[language].general.currency}</span>
                <div className={'grid__item__inner'}>
                    <div className="overlay-fill" style={{width: percent + '%'}}></div>
                    <h5>{sectorCodes[name].name}</h5>
                    {/*<h3 className="percentage">{percent}</h3>*/}
                    <span className="icon"><svg xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path
                            d="M7.15 21.1q-.375-.375-.375-.888 0-.512.375-.887L14.475 12l-7.35-7.35q-.35-.35-.35-.875t.375-.9q.375-.375.888-.375.512 0 .887.375l8.4 8.425q.15.15.213.325.062.175.062.375t-.062.375q-.063.175-.213.325L8.9 21.125q-.35.35-.862.35-.513 0-.888-.375Z"/></svg></span>
                </div>
            </div>
    );
};

export default SectorCard;
