import React, {useContext} from 'react';
import {Settings} from '../../store';

const Next = (props) => {
    const {name, condition, validationIsActive, validationIsDisabled} = props;
    const settingsState = useContext(Settings.State);
    const dispatch = useContext(Settings.Dispatch);
    const {activeScreen, businessOwner, companyRevenue, businessSubIndustry, portfolioValue} = settingsState;

    return <div className={name === 'How' ? 'text-center py-4' : 'text-center py-4'}>
        <button
                className={name === 'Explore' ? 'btn btn-light btn-rounded btn-lg' : 'btn btn-light btn-rounded btn-lg'}
                onClick={() => {
                    if (condition || (businessOwner === 'yes' && (companyRevenue === 0 || businessSubIndustry === ''))) {
                        validationIsActive();
                        return;
                    }
                    if (activeScreen === 2) {
                        window.scrollTo(0, 0);
                    }
                    if (activeScreen === 3) {
                        dispatch({type: 'setIsSwiperModalOpen', payload: true});
                        return;
                    }
                    validationIsDisabled();
                    if (portfolioValue === 0) {
                        dispatch({type: 'setPortfolioValue', payload: 10000000});
                    }
                    dispatch({type: 'setActiveScreen', payload: activeScreen + 1});
                }}>
            <span>{name}</span>
        </button>
    </div>;
};

export default Next;