import {useEffect, useState} from 'react';
import TagManager from '../services/tag-manager';
import {hotjar} from 'react-hotjar';

export const usePageTracking = () => {

    const [initialized, setInitialized] = useState(false);
    const hostnameParts = window.location.hostname.split('.');

    let trackingID = 'GTM-MST5FSJ';
    let country = 'Norway';
    if (hostnameParts[hostnameParts.length - 1] === 'se') {
        country = 'Sweden';
    }
    if (hostnameParts[hostnameParts.length - 1] === 'com') {
        country = 'Global';
    }
    const tagManagerArgs = {
        gtmId: trackingID,
        dataLayer: {
            userProject: hostnameParts[0] === 'footprint' ? 'Footprint Tool 2023' : 'Impact Tool 2023',
            event: 'metadata',
            pageCategory: hostnameParts[0] === 'footprint' ? 'Footprint Tool 2023' : 'Impact Tool 2023',
            country: country,
        },
    };

    useEffect(() => {
        if (!initialized) {
            TagManager.initialize(tagManagerArgs);
            setInitialized(true);
        }

        if (hostnameParts[hostnameParts.length - 1] === 'no') {
            hotjar.initialize('1622560', 6);
        }

    }, []);

    // const params = new Proxy(new URLSearchParams(window.location.search), {
    //     get: (searchParams, prop) => searchParams.get(prop),
    // });

    // if (params.app) {
    //     return params.app.toLowerCase();
    // } else {
    //     return hostnameParts[0];
    // }

    return hostnameParts[0];

};