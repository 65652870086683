import React, {useEffect, useRef, useState} from 'react';
import SDGSectorsTab from './SDGSectorsTab';
import SDGContinentsTab from './SDGContinentsTab';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {gsap} from 'gsap';
import SDGSectorsRemainingModal from './SDGSectorsRemainingModal';

const SDGModalTabs = (props) => {
    const {
        SDG,
        activeSDG,
        showModalDetails,
        setActiveSDGSector,
        activeSDGSector,
        totalSDGImpactValue,
        setLoadSectorTabs,
        setActiveTab,
        activeTab,
        negativeCalculation,
        negativeValueForCurrentItem
    } = props;
    const [isOpenSectorsRemaining, setIsOpenSectorsRemaining] = useState(false);
    const [remainingSectorsCalc, setRemainingSectorsCalc] = useState(0);

    const showSectorsRemainingModal = () => {
        if (!isOpenSectorsRemaining) {
            setIsOpenSectorsRemaining(true);
        }
    };
    const hideSectorsModalRemaining = () => {
        setIsOpenSectorsRemaining(false);
    };
    const el = useRef(null);
    useEffect(() => {
        gsap.to(el.current, {
            opacity: 1,
            y: 0,
            duration: 0.1,
            delay: 0.2,
        });

        return () => {
            setLoadSectorTabs(false);
        };
    }, []);

    return (<div ref={el} className="row" style={{opacity: 0, transform: 'translateY(10px)'}}>
                <div className="col-lg-12">

                    <Tabs
                            defaultActiveKey="sectors"
                            id="nav-tab"
                            activeKey={activeTab}
                            transition={true}
                            onSelect={(k) => {
                                setActiveTab(k);
                                document.body.classList.remove('selected');
                            }}
                    >
                        <Tab eventKey="sectors" title="Sectors">
                            <SDGSectorsTab setActiveSDGSector={setActiveSDGSector}
                                           showModalDetails={showModalDetails}
                                           className={'sectors'}
                                           activeTab={activeTab}
                                           totalSDGImpactValue={totalSDGImpactValue}
                                           activeSDG={SDG}
                                           negativeCalculation={negativeCalculation}
                                           setRemainingSectorsCalc={setRemainingSectorsCalc}
                            />

                            {activeSDG.id !== 7 &&
                                    <p className="text-center mt-3 mb-0 formue-animation"><a className="small"
                                                                                             role="button"
                                                                                             onClick={showSectorsRemainingModal}><strong>Remaining
                                        sectors</strong></a></p>}
                            {activeSDG.id !== 7 &&
                                    <SDGSectorsRemainingModal activeSDG={activeSDG} activeSDGSector={activeSDGSector}
                                                              isOpenSectorsRemaining={isOpenSectorsRemaining}
                                                              hideSectorsModalRemaining={hideSectorsModalRemaining}
                                                              totalSDGImpactValue={totalSDGImpactValue}
                                                              negativeCalculation={negativeCalculation}
                                                              remainingSectorsCalc={remainingSectorsCalc}
                                                              negativeValueForCurrentItem={negativeValueForCurrentItem}
                                    />}
                        </Tab>
                        <Tab eventKey="continents" className={'continents'} title="Continents">
                            <SDGContinentsTab setActiveSDGSector={setActiveSDGSector}
                                              showModalDetails={showModalDetails}
                                              activeTab={activeTab}
                                              activeSDG={activeSDG}
                                              totalSDGImpactValue={totalSDGImpactValue}
                                              negativeCalculation={negativeCalculation}
                            />
                        </Tab>
                    </Tabs>
                </div>
            </div>
    );
};

export default SDGModalTabs;
