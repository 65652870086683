import React, {useContext} from 'react';
import {Settings} from '../../store';


const FootprintFooter = () => {
    const settingsState = useContext(Settings.State);

    const {language} = settingsState;
    if (language === 'no') {
        return (
                <footer className="zIndex-1 pt-8 pb-5 s2">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-6 col-lg mb-5 mb-lg-0">
                                <h4 className="mb-3">Kontakt</h4>
                                <ul className="list-unstyled">
                                    <li
                                            className="menu-item">
                                        <a href="https://formue.no/kontaktskjema/" className="nav-link p-0 mb-2">Kontakt
                                            en
                                            rådgiver</a></li>
                                    <li
                                            className="menu-item">
                                        <a href="https://formue.no/kontakt/#places"
                                           className="nav-link  p-0 mb-2">Kontor</a></li>
                                </ul>
                            </div>
                            <div className="col-sm-6 col-lg mb-5 mb-lg-0">
                                <h4 className="mb-3">Ressurser</h4>

                                <ul className="list-unstyled">
                                    <li
                                            className="menu-item">
                                        <a href="https://formue.no/uavhengig-radgivning/"
                                           className="nav-link  p-0 mb-2">Uavhengighet</a></li>
                                    <li
                                            className="menu-item">
                                        <a href="https://formue.no/arsmeldinger/"
                                           className="nav-link  p-0 mb-2">Årsmeldinger</a>
                                    </li>
                                    <li
                                            className="menu-item">
                                        <a href="https://formue.no/konsesjon-og-selskapsstruktur/"
                                           className="nav-link  p-0 mb-2">Konsesjon
                                            og selskapsstruktur</a></li>
                                    <li id="menu-item-42387"
                                        className="menu-item">
                                        <a href="https://formue.no/formue-baerekraft/"
                                           className="nav-link  p-0 mb-2">Bærekraft</a></li>
                                </ul>

                            </div>
                            <div className="col-sm-6 col-lg mb-5 mb-lg-0">
                                <h4 className="mb-3">Innsikt</h4>

                                <ul className="list-unstyled">
                                    <li
                                            className="menu-item">
                                        <a href="https://formue.no/innsikt/kategori/trygghet/"
                                           className="nav-link  p-0 mb-2">Trygghet</a></li>
                                    <li
                                            className="menu-item">
                                        <a href="https://formue.no/innsikt/kategori/bevare-utvikle/"
                                           className="nav-link  p-0 mb-2">Bevare &amp; Utvikle</a></li>
                                    <li
                                            className="menu-item">
                                        <a href="https://formue.no/innsikt/kategori/skape/"
                                           className="nav-link  p-0 mb-2">Skape</a></li>
                                    <li
                                            className="menu-item">
                                        <a href="https://formue.no/innsikt/kategori/podcast/"
                                           className="nav-link  p-0 mb-2">Podcast</a></li>
                                </ul>

                            </div>
                            <div className="col-sm-6 col-lg mb-5 mb-lg-0">
                                <h4 className="mb-3">Social</h4>

                                <ul id="menu-footer-social" className="list-unstyled">
                                    <li
                                            className="menu-item">
                                        <a target="_blank" rel="noopener"
                                           href="https://www.linkedin.com/company/formuenorge/"
                                           className="nav-link  p-0 mb-2">LinkedIn</a></li>
                                    <li
                                            className="menu-item">
                                        <a target="_blank" rel="noopener" href="https://www.facebook.com/formue.norge"
                                           className="nav-link  p-0 mb-2">Facebook</a></li>
                                    <li
                                            className="menu-item">
                                        <a href="https://www.instagram.com/formue.norge/"
                                           className="nav-link  p-0 mb-2">Instagram</a></li>
                                    <li
                                            className="menu-item">
                                        <a target="_blank" rel="noopener" href="https://twitter.com/formue"
                                           className="nav-link  p-0 mb-2">Twitter</a></li>
                                </ul>

                            </div>
                            <div className="col-sm-6 col-lg mb-5 mb-lg-0">
                                <h4 className="mb-3">Last ned</h4>
                                <ul className="d-flex flex-column list-unstyled">
                                    <li>
                                        <a href="https://apps.apple.com/no/app/formue/id1468084673"
                                           className="icn-link ios d-flex nav-link p-0 mb-2">App Store</a>
                                    </li>
                                    <li>
                                        <a href="https://play.google.com/store/apps/details?id=no.formue.formue.no&hl=no"
                                           className="icn-link android d-flex  nav-link p-0 mb-2">Google Play</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="border-top pt-4 mt-6 mt-lg-8 d-flex justify-content-between align-items-lg-center">
                                    <span className="small"><small>©2023 Formue. All Rights Reserved.</small></span>
                                    <div className="small"><a target="_blank" className="small"
                                                              href="https://formue.no/wp-content/uploads/2022/02/Alminnelige-forretningsvilkar-Formue-Norge-AS-002.pdf">Alminnelige
                                        forretningsvilkår</a> | <a href="https://formue.no/cookies-og-personvern/"
                                                                   className="small">Cookies
                                        og personvern</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
        );
    }

    if (language === 'se') {
        return (
                <footer className="zIndex-1 pt-8 pb-5 s2">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-6 col-lg mb-5 mb-lg-0">
                                <h4 className="mb-3">Kontakt</h4>
                                <div className="menu-footer-kontakt-container">
                                    <ul id="menu-footer-kontakt" className="list-unstyled">
                                        <li id="menu-item"
                                            className="nav-link p-0 mb-2">
                                            <a href="https://formue.se/kontaktformular/" className="nav-link p-0 mb-2">Kontakta
                                                en rådgivare</a></li>
                                        <li id="menu-item-31298"
                                            className="nav-link p-0 mb-2">
                                            <a href="https://formue.se/kontakt/#places" className="nav-link p-0 mb-2">Kontor</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg mb-5 mb-lg-0">
                                <h4 className="mb-3">Resurser</h4>
                                <div className="menu-footer-ressurser-container">
                                    <ul id="menu-footer-ressurser" className="list-unstyled">
                                        <li
                                            className="menu-item">
                                            <a href="https://formue.se/oberoende/" className="nav-link p-0 mb-2">Oberoende</a>
                                        </li>
                                        <li
                                            className="menu-item">
                                            <a href="https://formue.se/investerarinformation/"
                                               className="nav-link p-0 mb-2">Investerarinformation</a></li>
                                        <li
                                            className="menu-item">
                                            <a href="https://formue.se/finansiell-information-tillstand/"
                                               className="nav-link p-0 mb-2">Finansiell information &amp; Tillstånd</a></li>
                                        <li
                                            className="menu-item">
                                            <a href="https://formue.se/formue-hallbarhet/"
                                               className="nav-link p-0 mb-2">Hållbarhet</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg mb-5 mb-lg-0">
                                <h4 className="mb-3">Insikt</h4>
                                <div className="menu-insikt-container">
                                    <ul id="menu-insikt" className="list-unstyled">
                                        <li
                                            className="menu-item">
                                            <a href="https://formue.se/insikt/kategori/trygghet/"
                                               className="nav-link p-0 mb-2">Trygghet</a></li>
                                        <li
                                            className="menu-item">
                                            <a href="https://formue.se/insikt/kategori/bevara-utveckla/"
                                               className="nav-link p-0 mb-2">Bevara &amp; Utveckla</a></li>
                                        <li id="menu-item-34749"
                                            className="menu-item">
                                            <a href="https://formue.se/insikt/kategori/skapa/"
                                               className="nav-link p-0 mb-2">Skapa</a></li>
                                        <li id="menu-item-34748"
                                            className="menu-item">
                                            <a href="https://formue.se/insikt/kategori/podcast/pod/"
                                               className="nav-link p-0 mb-2">Förmögenhetspodden</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg mb-5 mb-lg-0">
                                <h4 className="mb-3">Social</h4>
                                <div className="menu-footer-social-container">
                                    <ul id="menu-footer-social" className="list-unstyled">
                                        <li id="menu-item-31294"
                                            className="menu-item">
                                            <a href="https://www.linkedin.com/company/formuesverige/"
                                               className="nav-link p-0 mb-2">LinkedIn</a></li>
                                        <li id="menu-item-31296"
                                            className="menu-item">
                                            <a href="https://www.facebook.com/formue.sverige"
                                               className="nav-link p-0 mb-2">Facebook</a></li>
                                        <li id="menu-item-31682"
                                            className="menu-item">
                                            <a href="https://www.instagram.com/formue.sverige/"
                                               className="nav-link p-0 mb-2">Instagram</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg mb-5 mb-lg-0">
                                <h4 className="mb-3">Ladda ner</h4>
                                <ul className="d-flex flex-column list-unstyled">
                                    <li>
                                        <a target="_blank" href="https://apps.apple.com/se/app/formue/id1468084673"
                                           className="icn-link ios d-flex nav-link p-0 mb-2">App Store</a>
                                    </li>
                                    <li>
                                        <a target="_blank" href="https://play.google.com/store/apps/details?id=no.formue.formue.no&hl=sv&gl=se"
                                           className="icn-link android d-flex  nav-link p-0 mb-2">Google Play</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="border-top pt-4 mt-6 mt-lg-8 d-flex justify-content-between align-items-lg-center">
                                    <span className="small"><small>©2023 Formue. All Rights Reserved.</small></span>
                                    <div className="small"><a  className="small" href="https://formue.se/finansiell-information-tillstand/#fit">Allmänna
                                        avtalsvillkor</a> | <a  className="small" href="https://formue.se/formue-integritetspolicy/">Cookies
                                        och integritet</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>

        );
    }


    if (language === 'com') {
        return (
                <footer className="zIndex-1 pt-8 pb-5 s2">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-6 col-lg mb-5 mb-lg-0">
                                <h4 className="mb-3">Contact</h4>
                                <div className="menu-footer-kontakt-container">
                                    <ul id="menu-footer-kontakt" className="list-unstyled">
                                        <li id="menu-item-13822"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-13822">
                                            <a href="https://formue.com/contact-us/" className="nav-link p-0 mb-2">Contact us</a>
                                        </li>
                                        <li id="menu-item-13823"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-13823">
                                            <a href="https://formue.com/board-of-directors/" className="nav-link p-0 mb-2">Board
                                                of directors</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg mb-5 mb-lg-0">
                                <h4 className="mb-3">Resources</h4>
                                <div className="menu-footer-ressurser-container">
                                    <ul id="menu-footer-ressurser" className="list-unstyled">
                                        <li id="menu-item-13825"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-13825">
                                            <a href="https://formue.com/independence/"
                                               className="nav-link p-0 mb-2">Independence</a></li>
                                        <li id="menu-item-13826"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-13826">
                                            <a href="https://formue.com/investment-philosophy/" className="nav-link p-0 mb-2">Investment
                                                philosophy</a></li>
                                        <li id="menu-item-13840"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-13840">
                                            <a href="https://formue.com/annual-reviews/" className="nav-link p-0 mb-2">Annual
                                                reviews</a></li>
                                        <li id="menu-item-14584"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-14584">
                                            <a href="https://formue.com/sustainability/"
                                               className="nav-link p-0 mb-2">Sustainability</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg mb-5 mb-lg-0">
                                <h4 className="mb-3">Wealth planning</h4>
                                <div className="menu-wealth-planning-container">
                                    <ul id="menu-wealth-planning" className="list-unstyled">
                                        <li id="menu-item-13832"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-13832">
                                            <a href="https://formue.com/wealth-planning/safety/"
                                               className="nav-link p-0 mb-2">Safety</a></li>
                                        <li id="menu-item-13833"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-13833">
                                            <a href="https://formue.com/wealth-planning/create/"
                                               className="nav-link p-0 mb-2">Create</a></li>
                                        <li id="menu-item-13834"
                                            className="menu-item menu-item-type-post_type menu-item-object-page menu-item-13834">
                                            <a href="https://formue.com/wealth-planning/preserve-develop/"
                                               className="nav-link p-0 mb-2">Preserve &amp; develop</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-6 col-lg mb-5 mb-lg-0">
                                <h4 className="mb-3">Social</h4>
                                <div className="menu-footer-social-container">
                                    <ul id="menu-footer-social" className="list-unstyled">
                                        <li id="menu-item-13828"
                                            className="menu-item menu-item-type-custom menu-item-object-custom menu-item-13828">
                                            <a href="https://www.linkedin.com/company/formuesforvaltning/"
                                               className="nav-link p-0 mb-2">LinkedIn</a></li>
                                        <li id="menu-item-13829"
                                            className="menu-item menu-item-type-custom menu-item-object-custom menu-item-13829">
                                            <a href="https://www.facebook.com/formuesforvaltning/"
                                               className="nav-link p-0 mb-2">Facebook</a></li>
                                        <li id="menu-item-13830"
                                            className="menu-item menu-item-type-custom menu-item-object-custom menu-item-13830">
                                            <a href="https://www.instagram.com/formue.no/"
                                               className="nav-link p-0 mb-2">Instagram</a></li>
                                        <li id="menu-item-13831"
                                            className="menu-item menu-item-type-custom menu-item-object-custom menu-item-13831">
                                            <a href="https://twitter.com/formue" className="nav-link p-0 mb-2">Twitter</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="border-top pt-4 mt-6 mt-lg-8 d-flex justify-content-between align-items-lg-center">
                                    <span className="small"><small>©2023 Formue. All Rights Reserved.</small></span> <a className="small"
                                        href="https://formue.com/cookies-and-privacy/"><small>Cookies and privacy</small></a>
                                </div>
                            </div>
                        </div>

                    </div>
                </footer>

        );
    }
};

export default FootprintFooter;