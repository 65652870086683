import {useContext} from 'react';
import Modal from 'react-bootstrap/Modal';
import TagManager from 'react-gtm-module';
import translations from '../../data/footprint/lang.json';
import {Settings} from '../../store';

const BaseModal = (props) => {
    const {
        isBaseOpen, hideBaseModal, content, title, icon, showContactModal,
    } = props;
    const settingsState = useContext(Settings.State);

    const {
        language,
    } = settingsState;

    return (<Modal size="sm"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered show={isBaseOpen} onHide={hideBaseModal}
    >
        <div className="overlay-background"></div>
        <div className="modal-header">
            <button onClick={hideBaseModal} type="button" className="btn-close" data-bs-dismiss="modal"
                    aria-label="Close"></button>
        </div>
        <div className="modal-body p-6">
            <span style={{display: icon ? 'block' : 'none'}} className="mb-3"><img style={{height: '40px'}} src={icon}
                                                                                   alt="icon"/></span>
            <h4 style={{display: title ? 'block' : 'none'}} className="mb-3"
                dangerouslySetInnerHTML={{__html: title}}></h4>
            <div dangerouslySetInnerHTML={{__html: content}}></div>
            <div style={{display: (icon === './media/icons/Advisor.svg') ? 'block' : 'none'}}>
                <br/>
                <a className="btn btn-link p-0" href="#" onClick={(e) => {
                    e.preventDefault();
                    TagManager.dataLayer({
                        dataLayer: {
                            userProject: 'Impact Tool 2023',
                            event: 'CTAevent',
                            CTAaction: 'Contact us - footer',
                        },
                    });
                    showContactModal();
                }}>{translations[language].whatcanyoudo.popup.button}</a>
            </div>
        </div>
    </Modal>);
};

export default BaseModal;