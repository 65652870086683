import React, {useContext, useRef} from 'react';
import translations from '../../../data/impact/lang.json';
import Modal from 'react-bootstrap/Modal';
import {Settings} from '../../../store';
import {Navigation, Pagination, Mousewheel, Keyboard} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import sectorsData from '../../../data/impact/sectors-data.json';
import {langSeparator} from '../../../hooks/helperFunctions';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import RemainingSectorsList from './RemainingSectorsList';
import {ProgressBar} from 'react-bootstrap';
import sectorCodes from '../../../data/impact/sector-codes.json'

const SectorModal = (props) => {
    const {
        isOpen,
        hideModal,
        activeSector,
        totalImpactValue,
        negativeCalculation,
    } = props;

    const settingsState = useContext(Settings.State);
    const {language} = settingsState;
    const el = useRef(null);
    const modalRef = useRef(null);
    return (<Modal size="xl"
                   ref={modalRef}
                   id={'testmodal'}
                   fullscreen={true}
                   scrollable={true}
                   dialogClassName={'modal-fullscreen-md-down'}
                   aria-labelledby="contained-modal-title-vcenter"
                   centered show={isOpen} onHide={hideModal}
                   className={'modal--sectors'}
                   onExit={() => document.body.classList.remove('selected')}
            >
                <div className="overlay-background"></div>
                <div className="modal-header">
                    <button onClick={hideModal} type="button" className="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                </div>
                <div className="modal-body p-0 pb-7">
                    <div className="swiper-controls">
                        <div className="sdg-small-cards sector-small-cards"></div>
                    </div>
                    <div className="swiper-buttons">
                        <div className="swiper-button-next"></div>
                        <div className="swiper-button-prev"></div>
                    </div>
                    <Swiper
                            className="m-0"
                            initialSlide={activeSector.id - 1}
                            cssMode={false}
                            slidesPerView={1}
                            loop={true}
                            autoHeight={false}
                            allowTouchMove={false}
                            mousewheel={false}
                            keyboard={true}
                            centeredSlides={true}
                            navigation={{
                                nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev',
                            }}
                            pagination={{
                                dynamicBullets: true,
                                dynamicMainBullets: 1,
                                clickable: true,
                                el: '.sector-small-cards',
                                renderBullet: function(index, className) {
                                    let sector = sectorsData['sector' + (parseFloat(index) + 1)];
                                    let url = 'media/' + sector.img;
                                    return '<span class="' + className + '"><img src="' + url + '" alt="sector"></span>';
                                },
                            }}
                            modules={[Pagination, Navigation, Mousewheel, Keyboard]}

                    >
                        {Object.keys(sectorsData).map((item) => {
                            const sector = sectorsData[item];
                            const percent = (100 * sector.value / totalImpactValue).toFixed(0);
                            const sectorName = sector.name;
                            console.log(sectorName)
                            return <SwiperSlide className="" key={sector.id}>
                                <div ref={el}
                                     className={'sector sector--' + sector.id + ' container details d-flex flex-column gap-3 gap-lg-6'}
                                     data-sector={sector.id}>
                                    <div className="row justify-content-center">
                                        <div className="col-lg-6">
                                            <div className="box d-flex flex-column gap-3 gap-lg-4">
                                                <div className={'grid__item__inner'}>
                                                    <div className="d-flex align-items-center">
                                                        <img className="box__media"
                                                             src={'media/' + sector.img} alt={sector.name}/>
                                                        <h3>{sectorCodes[sectorName].name}</h3>
                                                    </div>
                                                    <ProgressBar now={percent} className="my-4"
                                                                 style={{height: '12px'}}/>
                                                    <div className="details">
                                                        <div className="d-flex mw-100 justify-content-between mb-0">
                                                            <span>Numbers of Companies</span>
                                                            <span>{sector.companies}</span>
                                                        </div>
                                                        <div className="d-flex mw-100 justify-content-between my-3">
                                                            <span>Total Negative Impact</span>
                                                            <span> - {(sector.value * translations[language].general.currencyvalue / 1000000000).toLocaleString(langSeparator(language), {maximumFractionDigits: 0})} {translations[language].general.billions} {translations[language].general.currency}</span>
                                                        </div>
                                                        <div className="d-flex mw-100 justify-content-between mb-0">
                                                            <span><strong>Your Share:</strong></span>
                                                            <span><strong> - {(percent * negativeCalculation / 100).toLocaleString(langSeparator(language), {maximumFractionDigits: 0})} {translations[language].general.currency}</strong></span>
                                                        </div>
                                                        <hr className="my-4"/>
                                                        <p>{sectorCodes[sectorName].description}</p>
                                                    </div>
                                                    {sector.name === 'Remaining Sectors' && <RemainingSectorsList/>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>;
                        })}
                    </Swiper>
                </div>
            </Modal>
    );
};

export default SectorModal;
