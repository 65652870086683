import React, {useContext} from 'react';
import {Settings} from '../../store';
import translations from '../../data/footprint/lang.json';


const SummaryNegativeImpactRegular = (props) => {
    const {checkboxItems, result} = props;
    const settingsState = useContext(Settings.State);
    const {
        language,
    } = result || settingsState;
    return (
            <>
                <div className="col-sm-6">
                    <h5 className="mb-2">{translations[language].investments_content.conclusion.smallheading1}</h5>
                    <div className="d-flex align-items-center mb-1"
                         data-checked={checkboxItems.includes(translations[language].investments_content.conclusion.list1) ? true : false}><span>                                                    <svg
                            style={{minWidth: 20}} width="20" height="20"
                            viewBox="0 0 20 20" fill="none"
                            xmlns="http://www.w3.org/2000/svg"><path
                            d="M18.4932 8.74973H16.9929C16.8569 8.74973 16.7486 8.64073 16.7429 8.50473C16.6391 6.01198 14.9569 5.70623 14.7599 5.67923L13.9816 5.59423C13.9176 5.58723 13.8694 5.53273 13.8701 5.46824L13.8816 4.68673C13.8816 3.04548 12.6354 2.13473 11.4656 2.06098C10.4981 1.99973 9.33165 2.42323 8.6984 3.36323L8.2694 3.99973C8.2344 4.05173 8.16615 4.06973 8.1099 4.04173L7.42315 3.69948C7.2129 3.59823 5.4664 2.82473 4.06315 4.21273C3.99815 4.27698 3.88115 4.41598 3.7894 4.52773C3.71215 4.62198 3.5784 4.64599 3.47265 4.58548L2.1544 3.83123C2.02665 3.75823 1.9869 3.58848 2.07365 3.46973C3.6689 1.28423 5.9064 1.18448 7.3999 1.57323C7.4854 1.59548 7.5739 1.56948 7.63515 1.50598C8.6234 0.481485 9.93465 -0.00426535 11.5482 0.0627347C13.8806 0.158485 15.4061 1.89223 15.7879 3.76573C15.8041 3.84598 15.8576 3.91048 15.9334 3.94148C17.1791 4.44998 18.6572 5.76749 18.7444 8.49399C18.7486 8.63374 18.6332 8.74973 18.4932 8.74973Z"
                            fill="#464F81"></path><path
                            d="M18.375 19.5003H1.75C1.612 19.5003 1.5 19.3883 1.5 19.2503V5.03533C1.5 4.94083 1.601 4.88033 1.68425 4.92533L6.6185 7.58233C6.6995 7.62583 6.75 7.71033 6.75 7.80233V11.1206C6.75 11.2586 6.86175 11.3706 7 11.3706L18.375 11.3753C18.513 11.3753 18.625 11.4873 18.625 11.6253V19.2503C18.625 19.3883 18.513 19.5003 18.375 19.5003ZM3.75 17.5003H16.375C16.513 17.5003 16.625 17.3883 16.625 17.2503V13.6253C16.625 13.4873 16.5133 13.3753 16.375 13.3753L5 13.3696C4.862 13.3696 4.75 13.2576 4.75 13.1196V8.99683C4.75 8.90483 4.6995 8.82033 4.6185 8.77683L3.68425 8.27383C3.601 8.22883 3.5 8.28933 3.5 8.38383V17.2503C3.5 17.3883 3.612 17.5003 3.75 17.5003Z"
                            fill="#4C68A0"></path><path
                            d="M8.2115 16.5508H6.75C6.612 16.5508 6.5 16.4388 6.5 16.3008V14.5508C6.5 14.4128 6.612 14.3008 6.75 14.3008H8.2115C8.3495 14.3008 8.4615 14.4128 8.4615 14.5508V16.3008C8.4615 16.4388 8.3495 16.5508 8.2115 16.5508Z"
                            fill="#45A2FF"></path><path
                            d="M11.481 16.5508H10.0195C9.88153 16.5508 9.76953 16.4388 9.76953 16.3008V14.5508C9.76953 14.4128 9.88153 14.3008 10.0195 14.3008H11.481C11.619 14.3008 11.731 14.4128 11.731 14.5508V16.3008C11.731 16.4388 11.619 16.5508 11.481 16.5508Z"
                            fill="#45A2FF"></path><path
                            d="M14.7506 16.5508H13.2891C13.1511 16.5508 13.0391 16.4388 13.0391 16.3008V14.5508C13.0391 14.4128 13.1511 14.3008 13.2891 14.3008H14.7506C14.8886 14.3008 15.0006 14.4128 15.0006 14.5508V16.3008C15.0006 16.4388 14.8886 16.5508 14.7506 16.5508Z"
                            fill="#45A2FF"></path></svg>

                  </span><p className="ms-2 mb-0">{translations[language].investments_content.conclusion.list1}</p>
                    </div>
                    <div className="d-flex align-items-center mb-1"
                         data-checked={checkboxItems.includes(translations[language].investments_content.conclusion.list2) ? true : false}><span>                                                    <svg
                            style={{minWidth: 20}} width="20" height="20"
                            viewBox="0 0 20 20" fill="none"
                            xmlns="http://www.w3.org/2000/svg"><path
                            d="M10.2999 3.20761C12.6764 5.88986 15.3069 9.67061 15.3069 12.1919C15.3069 15.1186 12.9259 17.4996 9.99916 17.4996C7.07241 17.4996 4.69141 15.1186 4.69141 12.1919C4.69141 9.67986 7.30241 5.91761 9.67216 3.23711C9.71941 3.18361 9.76666 3.13036 9.81391 3.07761C9.91316 2.96661 10.0862 2.96861 10.1857 3.07936C10.2274 3.12586 10.2684 3.17211 10.2999 3.20761ZM9.33416 0.67011C7.53241 2.54236 2.69141 7.96586 2.69141 12.1921C2.69141 16.2279 5.96316 19.4996 9.99916 19.4996C14.0352 19.4996 17.3069 16.2279 17.3069 12.1919C17.3069 7.95286 12.4362 2.50886 10.6477 0.65286C10.4484 0.44611 10.2872 0.28361 10.1757 0.17261C10.0782 0.0756099 9.92091 0.0751099 9.82341 0.17211C9.70841 0.28636 9.54141 0.45461 9.33416 0.67011Z"
                            fill="#567E59"></path><path
                            d="M9.78325 13.9506C8.92025 13.8436 8.25 13.1056 8.25 12.2141C8.25 12.0153 8.28425 11.8208 8.34875 11.6376C8.38825 11.5248 8.33975 11.4006 8.23575 11.3418L6.91775 10.5968C6.78725 10.5231 6.623 10.5803 6.563 10.7176C6.35875 11.1851 6.25 11.6931 6.25 12.2141C6.25 14.1893 7.785 15.8126 9.7245 15.9541C9.87225 15.9648 10 15.8538 10 15.7056V14.3191C10 14.0716 9.90725 13.9661 9.78325 13.9506Z"
                            fill="#6ECA5B"></path></svg>

                  </span><p className="ms-2 mb-0">{translations[language].investments_content.conclusion.list2}</p>
                    </div>
                    <div className="d-flex align-items-center mb-1"
                         data-checked={checkboxItems.includes(translations[language].investments_content.conclusion.list3) ? true : false}><span>                                                    <svg
                            style={{minWidth: 20}} width="20" height="20"
                            viewBox="0 0 20 20" fill="none"
                            xmlns="http://www.w3.org/2000/svg"><path
                            d="M10.75 1.47461H9.25C9.112 1.47461 9 1.58661 9 1.72461V7.27961C9 7.41761 9.112 7.52961 9.25 7.52961H10.75C10.888 7.52961 11 7.41761 11 7.27961V1.72461C11 1.58636 10.888 1.47461 10.75 1.47461Z"
                            fill="#6ECA5B"></path><path
                            d="M7.51562 0.474609H6.01562C5.87763 0.474609 5.76562 0.586609 5.76562 0.724609V7.27961C5.76562 7.41761 5.87763 7.52961 6.01562 7.52961H7.51562C7.65363 7.52961 7.76562 7.41761 7.76562 7.27961V0.724609C7.76562 0.586359 7.65363 0.474609 7.51562 0.474609Z"
                            fill="#6ECA5B"></path><path
                            d="M13.9883 0.474609H12.4883C12.3503 0.474609 12.2383 0.586609 12.2383 0.724609V7.27961C12.2383 7.41761 12.3503 7.52961 12.4883 7.52961H13.9883C14.1263 7.52961 14.2383 7.41761 14.2383 7.27961V0.724609C14.2383 0.586359 14.1263 0.474609 13.9883 0.474609Z"
                            fill="#6ECA5B"></path><path
                            d="M7.60989 15.0285C6.29939 15.0372 5.12664 14.2157 4.68739 12.981C4.48239 12.4685 4.39739 11.5035 4.37489 10.6435C4.37339 10.5772 4.42564 10.5222 4.49189 10.5205C4.49614 10.5205 4.50064 10.5205 4.50489 10.521C5.43989 10.616 6.80989 10.896 7.87739 11.6785C7.98989 11.7585 8.14589 11.7322 8.22614 11.6197C8.23539 11.6067 8.24339 11.593 8.24989 11.5785C8.47964 11.1507 8.75189 10.747 9.06239 10.3735C9.14564 10.2635 9.12389 10.1065 9.01389 10.0232C9.00939 10.0197 9.00464 10.0165 8.99989 10.0135C6.64739 8.32346 3.49989 8.46096 3.32489 8.46846L2.64739 8.50346C2.51414 8.51021 2.40964 8.62021 2.40989 8.75346L2.37739 9.43096C2.37739 9.72346 2.25989 12.316 2.82239 13.726C3.85489 16.2785 6.04489 16.981 7.59989 17.0285C7.73789 17.0285 7.84989 16.9165 7.84989 16.7785V15.2785C7.84989 15.1442 7.74414 15.034 7.60989 15.0285Z"
                            fill="#455E4D"></path><path
                            d="M17.4989 9.41926L17.4664 8.74176C17.4667 8.60926 17.3637 8.49976 17.2314 8.49176L16.5539 8.45426C16.3689 8.45426 12.6239 8.28176 10.2489 10.5143L10.1489 10.6168L10.1289 10.6343C9.52641 11.2543 8.87891 12.2793 8.87891 12.7218V19.2168C8.87891 19.3548 8.99091 19.4668 9.12891 19.4668H10.6289C10.7669 19.4668 10.8789 19.3548 10.8789 19.2168V13.0068C10.8782 12.962 10.8894 12.918 10.9114 12.8793C11.8964 11.1293 14.0689 10.6443 15.3789 10.5093C15.4434 10.5023 15.5014 10.549 15.5082 10.6135C15.5087 10.6188 15.5089 10.624 15.5089 10.6293C15.4864 11.4918 15.3989 12.4568 15.1939 12.9693C14.7409 14.246 13.5102 15.0795 12.1564 15.0268C12.0184 15.0268 11.9064 15.1388 11.9064 15.2768V16.7768C11.9064 16.9148 12.0184 17.0268 12.1564 17.0268H12.4514C14.5144 16.9633 16.3347 15.6595 17.0589 13.7268C17.6264 12.3018 17.5214 9.70926 17.4989 9.41926Z"
                            fill="#567E59"></path></svg>

                  </span><p className="ms-2 mb-0">{translations[language].investments_content.conclusion.list3}</p>
                    </div>
                    <div className="d-flex align-items-center mb-1"
                         data-checked={checkboxItems.includes(translations[language].investments_content.conclusion.list4) ? true : false}><span>                                                    <svg
                            style={{minWidth: 20}} width="20" height="20"
                            viewBox="0 0 20 20" fill="none"
                            xmlns="http://www.w3.org/2000/svg"><path
                            d="M13.6588 6.71929C12.3231 6.71929 10.8626 6.42654 9.3601 5.62979C5.1871 3.41704 3.20585 5.28429 2.4591 5.84504C2.3546 5.92354 2.20735 5.90929 2.1186 5.81404L1.06785 4.69054C0.967848 4.58379 0.979598 4.41754 1.0951 4.32704C2.02735 3.59804 4.94885 0.994291 10.3401 3.85254C14.1838 5.88954 16.7531 3.65604 17.6108 3.04504C17.7198 2.96729 17.8703 2.98779 17.9558 3.08979L18.9413 4.26604C19.0338 4.37654 19.0149 4.53954 18.8976 4.62479C18.1801 5.14554 16.7548 6.71929 13.6588 6.71929Z"
                            fill="#4C68A0"></path><path
                            d="M13.6588 12.2193C12.3231 12.2193 10.8626 11.9265 9.3601 11.1298C5.1871 8.91729 3.20585 10.7845 2.4591 11.345C2.3546 11.4235 2.20735 11.4093 2.1186 11.314L1.06785 10.1905C0.967848 10.0838 0.979598 9.91754 1.0951 9.82704C2.02735 9.09804 4.94885 6.49429 10.3401 9.35254C14.1836 11.39 16.7531 9.15579 17.6108 8.54504C17.7198 8.46729 17.8703 8.48779 17.9558 8.58979L18.9413 9.76604C19.0338 9.87654 19.0149 10.0395 18.8976 10.1248C18.1801 10.6455 16.7548 12.2193 13.6588 12.2193Z"
                            fill="#45A2FF"></path><path
                            d="M13.6588 17.7194C12.3231 17.7194 10.8626 17.4267 9.3601 16.6299C5.1871 14.4174 3.20585 16.2847 2.4591 16.8452C2.3546 16.9237 2.20735 16.9094 2.1186 16.8142L1.06785 15.6907C0.967848 15.5839 0.979598 15.4177 1.0951 15.3272C2.02735 14.5982 4.94885 11.9942 10.3401 14.8527C14.1838 16.8897 16.7531 14.6562 17.6108 14.0452C17.7198 13.9674 17.8703 13.9877 17.9558 14.0899L18.9413 15.2662C19.0338 15.3767 19.0149 15.5397 18.8976 15.6249C18.1801 16.1457 16.7548 17.7194 13.6588 17.7194Z"
                            fill="#464F81"></path></svg>

                  </span><p className="ms-2 mb-0">{translations[language].investments_content.conclusion.list4}</p>
                    </div>
                    <div className="d-flex align-items-center mb-1"
                         data-checked={checkboxItems.includes(translations[language].investments_content.conclusion.list5) ? true : false}><span>                                                    <svg
                            style={{minWidth: 20}} width="20" height="20"
                            viewBox="0 0 20 20" fill="none"
                            xmlns="http://www.w3.org/2000/svg"><path
                            d="M16.4692 16.6328C16.5565 16.7993 16.436 16.9991 16.248 16.9991H3.69147C3.50347 16.9991 3.38272 16.7993 3.47022 16.6328L9.85922 4.45883C9.90597 4.36958 10.0337 4.36958 10.0805 4.45883L16.4692 16.6328ZM0.161722 18.6328C0.074472 18.7993 0.195222 18.9991 0.383222 18.9991H19.5562C19.7442 18.9991 19.865 18.7993 19.7775 18.6328L10.0802 0.154828C10.0335 0.0655781 9.90572 0.0655781 9.85897 0.154828L0.161722 18.6328Z"
                            fill="#F2637B"></path><path
                            d="M10.75 15.75H9.25C9.112 15.75 9 15.638 9 15.5V14C9 13.862 9.112 13.75 9.25 13.75H10.75C10.888 13.75 11 13.862 11 14V15.5C11 15.638 10.888 15.75 10.75 15.75Z"
                            fill="#A0496D"></path><path
                            d="M10.75 12.75H9.25C9.112 12.75 9 12.638 9 12.5V8.5C9 8.362 9.112 8.25 9.25 8.25H10.75C10.888 8.25 11 8.362 11 8.5V12.5C11 12.638 10.888 12.75 10.75 12.75Z"
                            fill="#A0496D"></path></svg>

                  </span><p className="ms-2 mb-0">{translations[language].investments_content.conclusion.list5}</p>
                    </div>
                </div>
                <div className="col-sm-6">
                    <h5 className="mb-2">{translations[language].investments_content.conclusion.smallheading2}</h5>
                    <div className="d-flex align-items-center mb-1"
                         data-checked={checkboxItems.includes(translations[language].investments_content.conclusion.list6) ? true : false}><span>                                                    <svg
                            style={{minWidth: 20}} width="20" height="20"
                            viewBox="0 0 20 20" fill="none"
                            xmlns="http://www.w3.org/2000/svg"><path
                            d="M6.42969 10C4.22419 10 2.42969 8.2055 2.42969 6C2.42969 3.7945 4.22419 2 6.42969 2C8.63519 2 10.4297 3.7945 10.4297 6C10.4297 8.2055 8.63544 10 6.42969 10ZM6.42969 4C5.32694 4 4.42969 4.89725 4.42969 6C4.42969 7.10275 5.32694 8 6.42969 8C7.53244 8 8.42969 7.10275 8.42969 6C8.42969 4.89725 7.53244 4 6.42969 4Z"
                            fill="#F2637B"></path><path
                            d="M14.5625 11.375C12.805 11.375 11.375 9.945 11.375 8.1875C11.375 6.43 12.805 5 14.5625 5C16.32 5 17.75 6.43 17.75 8.1875C17.75 9.945 16.32 11.375 14.5625 11.375ZM14.5625 6.7895C13.7915 6.7895 13.1645 7.41675 13.1645 8.1875C13.1645 8.95825 13.7917 9.5855 14.5625 9.5855C15.3333 9.5855 15.9605 8.95825 15.9605 8.1875C15.9605 7.41675 15.3335 6.7895 14.5625 6.7895Z"
                            fill="#A0496D"></path><path
                            d="M14.2073 14.6538C15.9865 14.745 17.4478 15.957 17.9415 17.5698C17.9743 17.676 18.069 17.7505 18.18 17.7505H19.73C19.8923 17.7505 20.0085 17.599 19.973 17.4405C19.3888 14.8225 17.1165 12.8003 14.3098 12.6565C13.6318 12.6218 12.974 12.7 12.3538 12.8738C12.185 12.921 12.1113 13.1238 12.2173 13.2633C12.5265 13.6703 12.7945 14.1093 13.0155 14.5743C13.0648 14.6778 13.1753 14.7333 13.2883 14.7133C13.5858 14.6603 13.893 14.6378 14.2073 14.6538Z"
                            fill="#A0496D"></path><path
                            d="M12.4966 17.706H10.9864C10.8504 17.706 10.7429 17.5975 10.7351 17.4618C10.6079 15.182 8.71314 13.3663 6.40214 13.3663C4.09114 13.3663 2.19614 15.1818 2.06914 17.4618C2.06164 17.5978 1.95414 17.706 1.81789 17.706H0.307641C0.166141 17.706 0.0491408 17.589 0.0548908 17.4478C0.190891 14.0643 2.98589 11.3535 6.40214 11.3535C9.81839 11.3535 12.6134 14.0643 12.7494 17.4478C12.7551 17.589 12.6381 17.706 12.4966 17.706Z"
                            fill="#724362"></path></svg>

                  </span><p className="ms-2 mb-0">{translations[language].investments_content.conclusion.list6}</p>
                    </div>
                    <div data-checked={checkboxItems.includes(translations[language].investments_content.conclusion.list7) ? true : false}
                         className="d-flex align-items-center mb-1"><span>                                                    <svg
                            style={{minWidth: 20}} width="20" height="20"
                            viewBox="0 0 20 20" fill="none"
                            xmlns="http://www.w3.org/2000/svg"><path
                            d="M19.7518 4.25H17.4698C17.3586 4.25 17.3026 4.11525 17.3813 4.0365L19.9213 1.49675C20.0188 1.39925 20.0191 1.24075 19.9213 1.14325L18.8608 0.08225C18.7633 -0.0155 18.6048 -0.0155 18.5073 0.08225L15.9653 2.62425C15.8866 2.703 15.7518 2.64725 15.7518 2.536V0.25C15.7518 0.112 15.6398 0 15.5018 0H14.0018C13.8638 0 13.7518 0.112 13.7518 0.25V4.73325C13.7518 4.7995 13.7256 4.86325 13.6786 4.91L13.0396 5.549L13.0391 5.5485L8.87409 9.71125C8.77634 9.80875 8.77634 9.96725 8.87409 10.0647L9.93434 11.1257C10.0318 11.2235 10.1903 11.2235 10.2878 11.1257L14.1173 7.29825L14.1178 7.29875L15.0938 6.323C15.1408 6.27625 15.2043 6.25 15.2706 6.25H19.7518C19.8898 6.25 20.0018 6.138 20.0018 6V4.5C20.0018 4.362 19.8898 4.25 19.7518 4.25Z"
                            fill="#59497A"></path><path
                            d="M9.53341 17.2357C5.92666 17.0087 2.99166 14.0737 2.76466 10.467C2.50491 6.342 5.71741 2.889 9.75566 2.7545C9.89116 2.75 9.99991 2.64225 9.99991 2.5065V1.00575C9.99991 0.865745 9.88441 0.750245 9.74441 0.753995C4.53816 0.895245 0.40591 5.338 0.77266 10.6565C1.08716 15.2177 4.78266 18.9132 9.34391 19.2277C14.6627 19.5945 19.1054 15.462 19.2467 10.256C19.2502 10.116 19.1349 10.0005 18.9947 10.0005H17.4939C17.3582 10.0005 17.2504 10.1092 17.2459 10.245C17.1114 14.283 13.6587 17.4955 9.53341 17.2357Z"
                            fill="#7C599F"></path><path
                            d="M9.59966 13.4781C7.99841 13.2993 6.70066 12.0018 6.52216 10.4006C6.29566 8.3706 7.81841 6.63385 9.77241 6.50785C9.90191 6.49935 9.99991 6.38685 9.99991 6.25735V4.7576C9.99991 4.6166 9.88316 4.49985 9.74241 4.5061C6.70966 4.64585 4.31791 7.2421 4.51091 10.3511C4.68091 13.0901 6.91016 15.3193 9.64916 15.4893C12.7582 15.6823 15.3544 13.2906 15.4942 10.2578C15.5007 10.1171 15.3837 10.0003 15.2427 10.0003H13.7429C13.6134 10.0003 13.5009 10.0983 13.4927 10.2276C13.3667 12.1818 11.6299 13.7043 9.59966 13.4781Z"
                            fill="#C76FF2"></path></svg>

                  </span><p className="ms-2 mb-0">{translations[language].investments_content.conclusion.list7}</p>
                    </div>
                    <div data-checked={checkboxItems.includes(translations[language].investments_content.conclusion.list8) ? true : false}
                         className="d-flex align-items-center mb-1"><span>                                                    <svg
                            style={{minWidth: 20}} width="20" height="20"
                            viewBox="0 0 20 20" fill="none"
                            xmlns="http://www.w3.org/2000/svg"><path
                            d="M3.84335 9.93945C1.8281 9.93945 0.172849 8.36745 0.0395989 6.38545C0.0300989 6.24445 0.148099 6.12695 0.289599 6.12695H1.7976C1.9226 6.12695 2.02885 6.2197 2.04385 6.3437C2.15135 7.2412 2.91735 7.93945 3.84335 7.93945C4.76935 7.93945 5.5356 7.2412 5.64285 6.3437C5.6576 6.21945 5.76385 6.12695 5.8891 6.12695H7.3971C7.5386 6.12695 7.6566 6.24445 7.6471 6.38545C7.51385 8.36745 5.8586 9.93945 3.84335 9.93945Z"
                            fill="#45A2FF"></path><path
                            d="M15.7624 19.5H4.19141C4.05341 19.5 3.94141 19.388 3.94141 19.25V17.75C3.94141 17.612 4.05341 17.5 4.19141 17.5H15.7627C15.9007 17.5 16.0127 17.612 16.0127 17.75V19.25C16.0124 19.388 15.9007 19.5 15.7624 19.5Z"
                            fill="#464F81"></path><path
                            d="M16.0124 2.74961H11.1829V0.724609C11.1829 0.586609 11.0709 0.474609 10.9329 0.474609H9.13316C8.99516 0.474609 8.88316 0.586609 8.88316 0.724609V2.74961H3.69141C3.55341 2.74961 3.44141 2.86161 3.44141 2.99961V4.49961C3.44141 4.63761 3.55341 4.74961 3.69141 4.74961H8.88316V16.2246C8.88316 16.3626 8.99516 16.4746 9.13316 16.4746H10.9329C11.0709 16.4746 11.1829 16.3626 11.1829 16.2246V4.74961H16.0124C16.1504 4.74961 16.2624 4.63761 16.2624 4.49961V2.99961C16.2624 2.86161 16.1507 2.74961 16.0124 2.74961Z"
                            fill="#4C68A0"></path><path
                            d="M16.1558 9.8772C14.1403 9.8772 12.4853 8.3052 12.3521 6.32295C12.3426 6.18195 12.4606 6.06445 12.6021 6.06445H14.1101C14.2351 6.06445 14.3413 6.1572 14.3563 6.2812C14.4638 7.1787 15.2298 7.87695 16.1558 7.87695C17.0821 7.87695 17.8481 7.17895 17.9553 6.2812C17.9701 6.15695 18.0763 6.06445 18.2016 6.06445H19.7096C19.8511 6.06445 19.9691 6.18195 19.9596 6.32295C19.8263 8.3052 18.1713 9.8772 16.1558 9.8772Z"
                            fill="#45A2FF"></path></svg>

                  </span><p className="ms-2 mb-0">{translations[language].investments_content.conclusion.list8}</p>
                    </div>


                </div>
            </>
    );
};

export default SummaryNegativeImpactRegular;