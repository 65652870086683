import React, {useEffect, useRef} from 'react';
import Scrollspy from 'react-scrollspy';
import gsap from 'gsap';

const ScrollspyNav = (props) => {

    const {isLoaded} = props;

    useEffect(() => {

        if (isLoaded === true) {
            window.onscroll = () => {
                let distanceFromTop = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;
                let bannerHeight = document.getElementById('banner').clientHeight;
                if (distanceFromTop >= bannerHeight - 1) {
                    document.getElementsByClassName('scrollspy')[0].classList.remove('disabled');
                } else {
                    document.getElementsByClassName('scrollspy')[0].classList.add('disabled');
                }
            };
        }
    }, [isLoaded]);

    useEffect(() => {
        let svgContainer = document.querySelector('.bodymovinanim');
        window.bodymovin.loadAnimation({
            wrapper: svgContainer,
            animType: 'svg',
            loop: true,
            path: '../media/scrollDown.json',
        });
    }, []);


    return (
            <div className="scrollspy--holder">
                <div className="scrollspy disabled">
                    <Scrollspy items={['investments', 'company', 'data-block', 'take-action']} componentTag={'ul'}
                               currentClassName={'active'}>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </Scrollspy>
                </div>
                {/*<div className="bodymovinanim"></div>*/}
            </div>
    );
};

export default ScrollspyNav;
