import {gsap, Expo} from 'gsap';
import {useEffect, useState} from 'react';
import PersonalFootprintData from '../data/footprint/personalFootprint.json';
import InvestmentsFootprintData from '../data/footprint/investmentsFootprint.json';
import BusinessFootprintData from '../data/footprint/businessFootprint.json';
import footprintTranslations from '../data/footprint/lang.json';

export function getNegativeImpactValue(index, language, investmentsSize) {

    return ((investmentsSize / 1000000) * index);
}

export function calculateInvestmentFootprint(updateInvestmentValue) {
    setTimeout(function() {
        let value = document.getElementById('invesmentValueVisible').value;
        if (value === '') {
            value = 5;
        }
        updateInvestmentValue(value);
    }, 500);
}

export function addListenerMulti(element, eventNames, listener) {
    var events = eventNames.split(' ');
    for (var i = 0, iLen = events.length; i < iLen; i++) {
        element.addEventListener(events[i], listener, false);
    }
}

export function mouseMoveHandler(e, movableElements) {
    movableElements.forEach((movableElement) => {
        const shiftValue = movableElement.getAttribute('data-value');
        const moveX = (e.clientX * shiftValue) / 250;
        const moveY = (e.clientY * shiftValue) / 250;
        gsap.to(movableElement, {x: moveX, y: moveY, duration: 1});
    });
}

export function throttled(delay, fn) {
    let lastCall = 0;
    return function(...args) {
        const now = (new Date).getTime();
        if (now - lastCall < delay) {
            return;
        }
        lastCall = now;
        return fn(...args);
    };
}

export function detectFontSize(characters) {
    if (characters == null) {
        return;
    }
    let size = characters.replaceAll(' ', '').length;

    let multi = 1;
    if (window.innerWidth < 768) {
        multi = 0.7;
    }
    if (16 <= size) {
        return 50 * multi;
    }
    if (15 <= size) {
        return 65 * multi;
    }
    if (14 <= size) {
        return 75 * multi;
    }
    if (13 <= size) {
        return 80 * multi;
    }
    if (12 <= size) {
        return 85 * multi;
    }
    return 96 * multi;
}

// export function scrollToSection(id) {
//     let position = '#' + id
//     if (id === 'investing_return') {
//         position = document.getElementById(id).offsetTop - window.innerHeight / 2
//     }
//     gsap.set(window, {scrollTo: position, delay: 0});
// }
export function scrollToSection(id) {
    let clearID = id.replace('#', '');
    if (id === '#investing_return') {
        id = window.innerHeight;
        clearID = 'investing_return';
    }
    if (id === '#negativeImpact') {
        id = window.innerHeight * 2.55;
        clearID = 'negativeImpact';
    }
    if (id === '#take_action_title') {

        id = parseFloat(document.getElementById('take_action_title').getAttribute('data-distance')) - window.innerHeight / 4.5;
    }
    gsap.set('.scrollspy span', {className: '=-active'});

    gsap.set('span[data-id="' + clearID + '"]', {className: 'active'});

    gsap.set(window, {scrollTo: id, delay: 0});
}

export function hasClass(element, className) {
    return (' ' + element.className + ' ').indexOf(' ' + className + ' ') > -1;
}

export function langSeparator(lang) {
    switch (lang) {
        case 'com':
            return 'en';
        case 'se':
            return 'sv';
        default:
            return 'no';
    }
}


export function useScrollPosition() {
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        function handleScroll() {
            setScrollPosition(window.scrollY);
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return scrollPosition;
}

export function lifeStyleCalculation(language, incomeLevel) {
    return parseFloat(PersonalFootprintData[language][incomeLevel].value.toFixed(2));
}

export function investmentsCalculation(language, portfolioAllocation, portfolioValue) {
    let market = InvestmentsFootprintData[language]['tCO2_per_m$_invested'][portfolioAllocation];
    return parseFloat(((market / 1000000) / InvestmentsFootprintData[language].usd_to_currency * (parseFloat(portfolioValue))).toFixed(2));
}


export function businessCalculation(language, industryValue, companyRevenue) {
    let currencyValuePerUSD = BusinessFootprintData[language].currentValuePerUSD;
    return parseFloat((((industryValue / 1000000) / currencyValuePerUSD) * (companyRevenue * 1000000)).toFixed(1));
}

export function filterAndListSectors(language) {
    return [...new Set(BusinessFootprintData[language].searchableValues.map((item) => item.sector))];
}

export function filterAndListIndustryGroup(language, sectorValue) {

    return [...new Set(BusinessFootprintData[language].searchableValues.map((item) => {
        if (item.sector === sectorValue) {
            return item.industry_group;
        }
    }))];
}

export function filterAndListIndustries(language, sectorValue, industryGroupValue) {

    return [...new Set(BusinessFootprintData[language].searchableValues.map((item) => {
        if (item.sector === sectorValue && item.industry_group === industryGroupValue) {
            return item.industry;
        }
    }))];
}

export function filterAndListSubIndustries(language, sectorValue, industryGroupValue, industryValue) {

    return [...new Set(BusinessFootprintData[language].searchableValues.map((item) => {
        if (item.sector === sectorValue && item.industry_group === industryGroupValue && item.industry === industryValue) {

            return {name: item.sub_industry, value: item.value};
        }
    }))];
}

export function showSwiper() {
    document.getElementById('swiper-backdrop').classList.remove('z-Index--1');
    document.getElementById('swiper-backdrop').classList.add('show');
    document.getElementById('swiper-group').classList.remove('d-none');
    gsap.to('#swiper-group', {
        height: 'auto', translateY: 0, duration: 0.45, ease: Expo.easeOut,
        // opacity: 1,

    });


    gsap.to('#animatedThumbup', {
        ease: 'expo.in', duration: 0.8, opacity: 1, scale: 1, rotate: 0,
    });

}

export function hideSwiper(swiper) {
    document.getElementById('swiper-backdrop').classList.remove('show');
    setTimeout(() => {
        document.getElementById('swiper-backdrop').classList.add('z-Index--1');
    }, 200);


    gsap.to('#swiper-group', {
        translateY: '100vh',
        transition: 'cubic-bezier(0.23, 1, 0.32, 1)',
        duration: 0.3,
        // opacity: 0,
        onComplete: () => {
            swiper.slideTo(0);
            const activeChild = document.querySelectorAll('.swiper-nav-item');
            activeChild.forEach((index) => {
                index.classList.remove('active');
            });
            document.querySelector('[data-active-swiper="0"]').classList.add('active');

            document.getElementById('swiper-group').classList.add('d-none');

        },
    });
}

export function removeCartItem(e, tasks, dispatchFunction) {
    const id = e.target.getAttribute('data-id');
    if (id === 'sustainableShare') {
        dispatchFunction({type: 'setSustainableShare', payload: ''});
        return;
    }
    if (id === 'engagement') {
        dispatchFunction({type: 'setEngagement', payload: ''});
        return;
    }
    if (id === 'negativeImpact') {
        dispatchFunction({type: 'setNegativeImpact', payload: ''});
        return;
    }
    const tempObject = tasks.filter(obj => obj.id !== id);
    dispatchFunction({type: 'setActiveTasks', payload: tempObject});

}

export function putItemFirst(arr, itemId) {
    const index = arr.findIndex(item => item.id === itemId);
    if (index > -1) {
        const item = arr.splice(index, 1)[0];
        arr.unshift(item);
    }
}

export function convertToThousand(labelValue, language) {
    let val = Math.abs(Number(labelValue));
    if (val >= 1.0e+12) {
        return {
            value: (Math.abs(Number(labelValue)) / 1.0e+9),
            string: language !== undefined ? ' ' + footprintTranslations[language].general.trillions : '',
        };
    }

    if (val >= 1.0e+9) {
        return {
            value: (Math.abs(Number(labelValue)) / 1.0e+9),
            string: language !== undefined ? ' ' + footprintTranslations[language].general.billions : '',
        };
    }

    if (val >= 1.0e+6) {
        return {
            value: (Math.abs(Number(labelValue)) / 1.0e+6),
            string: language !== undefined ? ' ' + footprintTranslations[language].general.millions : '',
        };
    }
    return {value: labelValue, string: ''};
}