import React, {useEffect, useState} from 'react';
import translations from '../../../data/impact/lang.json';
import Modal from 'react-bootstrap/Modal';
import {Settings} from '../../../store';
import sdgData from '../../../data/impact/sdg-data.json';
import {langSeparator} from '../../../hooks/helperFunctions';
import sectorCodes from '../../../data/impact/sector-codes.json';

const SDGDetailsModal = (props) => {
    const {
        isOpenDetails,
        hideModalDetails,
        activeSDG,
        activeSDGSector,
        totalSDGImpactValue,
        negativeCalculation,
    } = props;

    const settingsState = React.useContext(Settings.State);
    const {language} = settingsState;
    const [overlayColor, setOverlayColor] = useState('#F2FAF0');
    const totalImpactIN2020 = activeSDGSector.totalNegativeImpact;
    const totalImpactIn2020Procent = (totalImpactIN2020 * 100) / activeSDG.totalSectorImpact;
    const [activeSDFName, setActiveSDFName] = useState('');


    useEffect(() => {
        if (!isOpenDetails) {
            document.querySelectorAll('.sectors__item').forEach((item) => {
                item.classList.remove('active');
            });
        }
    }, [isOpenDetails]);


    useEffect(() => {
        setOverlayColor(activeSDG.overlayColor);
    }, [activeSDG]);

    useEffect(() => {
        if (activeSDGSector.name != undefined) {
            setActiveSDFName(sectorCodes[activeSDGSector.name].name);
        }
    }, [activeSDGSector.name]);


    const percent = ((sdgData['SDG' + activeSDG.id].totalSectorImpact * 100) / totalSDGImpactValue);
    const activeSDGImpact = (negativeCalculation * percent) / 100;
    const finalCalculation = (activeSDGImpact * totalImpactIn2020Procent) / 100;

    return (
            <Modal size="sm" dialogClassName={'modal-fullscreen-md-down'}
                   className={'modal--sectors sdg sdg--' + activeSDG.id}
                   backdrop={true}
                   aria-labelledby="contained-modal-title-vcenter"
                   centered show={isOpenDetails} onHide={() => {
                hideModalDetails();
            }}
            >
                <div className="overlay-background" style={{background: overlayColor}}></div>
                <div className="modal-header">
                    <button onClick={hideModalDetails} type="button" className="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                </div>
                <div className="modal-body d-flex bg-transparent justify-content-center align-items-center">
                    <div className="d-flex flex-column gap-5 w-100">
                        <div className="">
                            <h4>{activeSDFName}</h4>
                        </div>
                        <div className="box d-flex flex-column gap-4">

                            <div className="d-flex justify-content-between flex-column gap-1">
                                <h6>Number of Companies:</h6>
                                <span>{activeSDGSector.companies}</span>
                            </div>
                            <div className="d-flex justify-content-between flex-column gap-1">
                                <h6>Total Negative Impact:</h6>
                                <span>- {(totalImpactIN2020 * translations[language].general.currencyvalue / 1000000000).toLocaleString(langSeparator(language), {maximumFractionDigits: 0})} {translations[language].general.billions} {translations[language].general.currency}
                                </span>
                            </div>
                            <div className="d-flex justify-content-between flex-column gap-1">
                                <h6><strong>Your Share:</strong></h6>
                                <span>
                                - {finalCalculation.toLocaleString(langSeparator(language), {maximumFractionDigits: 0})} {translations[language].general.currency}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
    );
};

export default SDGDetailsModal;
