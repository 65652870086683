import React, {useContext, useEffect, useState} from "react";
import {Settings} from "../../../store";
import TalkToExpert from "./TalkToExpert";
import TagManager from "react-gtm-module";

const CTABlock = (props) => {
    const {showContactModal} = props;
    const settingsState = useContext(Settings.State)
    const {language} = settingsState;
    const [link, setLink] = useState('https://footprint.formue.no/');

    useEffect(() => {
        switch (language) {
            case 'no':
                setLink('https://footprint.formue.no/')
                break;
            case 'se':
                setLink('https://footprint.formue.se/')
                break;
            case 'com':
                setLink('https://footprint.formue.com/')
                break;
            default:
                setLink('https://footprint.formue.no/')

        }
    }, [language]);

    return (
        <footer id="footer" className="footer">
            <section className="block block--cta block--invert green">
                <div className="container">
                    <div className="block__content">
                        <div className="row justify-content-center text-center mt-lg-5 text-lg-start">
                            <div className="col-lg-6">
                                <h2 className="medium mb-4">Let's get started</h2>
                            </div>
                            <div className="col-lg-6">
                                <p className="lead mb-6 me-auto ms-0">Fortunately you do not need to do the above on
                                    your own. Use our tool
                                    and we
                                    can help you on the way. <a className="btn-link" href={'!#'} onClick={(e) => {
                                        e.preventDefault();
                                        TagManager.dataLayer({
                                            dataLayer: {
                                                userProject: 'Impact',
                                                event: 'CTAevent',
                                                CTAaction: 'Contact us - footer',
                                            }
                                        });
                                        showContactModal();
                                    }}>Contact us</a> if you want advice
                                    on
                                    this topic. Our sustainability advisors are ready to help you ;-)</p>
                                <div className="d-flex flex-column justify-content-center justify-content-lg-start flex-sm-row gap-2">
                                    <TalkToExpert showContactModal={showContactModal}/>
                                    <a href={''} target={"_blank"} className="btn btn-lg btn-link" onClick={() => {
                                        TagManager.dataLayer({
                                            dataLayer: {
                                                userProject: 'Impact',
                                                event: 'CTAevent',
                                                CTAaction: 'Wealth footprint tool - footer',
                                            }
                                        });
                                        window.location.href = link;
                                    }}>Wealth footprint tool</a>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="border-top py-5 mt-5">
                        <p className="small opacity-75 lh-sm">
                            <small><strong>Source:</strong> GIST. <strong>Disclaimer:</strong> Neither GIST Advisory
                                Switzerland S.A. (‘GIST’) nor any other party involved in or related to compiling,
                                computing or creating the GIST data makes any express or implied warranties or
                                representations with respect to such data (or the results to be obtained by the use
                                thereof), and all such parties hereby expressly disclaim all warranties of originality,
                                accuracy, completeness, merchantability or fitness for a particular purpose with respect
                                to any of such data. Without limiting any of the foregoing, in no event shall GIST, any
                                of its affiliates or any third party involved in or related to compiling, computing or
                                creating the data have any liability for any direct, indirect, special, punitive,
                                consequential or any other damages (including lost profits) even if notified of the
                                possibility of such damages. No further distribution or dissemination of the GIST data
                                is permitted without GIST’s express written consent.</small></p>
                    </div>
                </div>
            </section>
        </footer>
    );
}

export default CTABlock;
