import React, {useContext} from 'react';
import translations from '../../../data/impact/lang.json';
import {Settings} from '../../../store';
import {ProgressBar} from 'react-bootstrap';


const TakeActionLine = (props) => {
    const {percent, name, value, order,animateBar} = props;

    const settingsState = useContext(Settings.State);
    const {language} = settingsState;





    return (
            <div className={order ? 'item mb-3 order-' + order : 'item mb-3'}>
                <div className="d-flex align-items-center justify-content-between mb-1">
                    <span className="small">{name}</span>
                    <span className="small font-weight-medium">- {value} {translations[language].general.currency}</span>
                </div>
                <ProgressBar now={animateBar ? percent : 0}/>
            </div>
    );
};
export default TakeActionLine;
