import React, {useEffect, useState} from 'react';
import imagesLoaded from 'imagesloaded';
import {useParams} from 'react-router-dom';
import Summary from './Summary';
import axios from 'axios';

const ResultByID = () => {
    const params = useParams();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [result, setResult] = useState(null);
    const [isContactOpen, setIsContactOpen] = useState(false);
    const [isCalendlyOpen, setIsCalendlyOpen] = useState(false);

    const showContactModal = () => {
        setIsContactOpen(true);
    };

    const hideContactModal = () => {
        setIsContactOpen(false);
    };

    const getTotalFootprint = () => {
        const {id} = params;
        axios.get(`${process.env.REACT_APP_GET_URL}${id}`).then((footprints) => {
            const result = footprints.data.data.attributes;
            setResult(result);
            console.log(result)
            setIsLoaded(true);

        }).catch(function(error) {
            setError(error);
            console.log(error);
        });
    };
    useEffect(() => {
        getTotalFootprint();
    }, []);

    useEffect(() => {
        let t = imagesLoaded(document.body, function() {
            setTimeout(() => {
                document.querySelector('body').classList.remove('loading');
            }, 2000);

        });
    }, []);

    if (error) {
        return (
                <>
                    <div style={{padding: 100}}>Error: {error.message}</div>
                </>
        );
    }

    if (!isLoaded) {
        return (
                <div className="preloader loading">
                    <div className="dot-flashing"></div>
                </div>
        );
    }

    return <>
        <div id="footprint-app" className={'result-page'}>
            <div id="swiper-group">
                <div className="swiper">
                    <div className={'block'}>
                        <div className="swiper-wrapper">
                            {result !== null && <Summary resultPage={true} result={result} isCalendlyOpen={isCalendlyOpen}
                                     setIsCalendlyOpen={setIsCalendlyOpen}
                                     isContactOpen={isContactOpen} setIsContactOpen={setIsContactOpen}
                                     showContactModal={showContactModal} hideContactModal={hideContactModal}/> }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;
};

export default ResultByID;