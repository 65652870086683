import React, {useRef} from "react";
import {Settings} from "../../../store";
import SDGContinentItem from "./SDGContinentItem";

const SDGContinentsTab = (props) => {
    const {
        activeSDG,
        negativeImpactByType,
        setActiveSDGSector,
        totalSDGImpactValue,
        showModalDetails,
        negativeCalculation
    } = props;
    const settingsState = React.useContext(Settings.State)
    const {language} = settingsState;
    const el = useRef(null);
    return (
        <div ref={el} className="grid d-block d-lg-grid grid--tabs" style={{gridTemplateColumns: 'repeat(5, 1fr)', gridTemplateRows:'repeat(4, 1fr)', gap: 10, height: 500}} >
            {activeSDG.continents.map(item => <SDGContinentItem grid={item.grid} key={item.totalNegativeImpact} showModalDetails={showModalDetails}
                                                                setActiveSDGSector={setActiveSDGSector} item={item}
                                                                activeSDG={activeSDG}
                                                                totalSDGImpactValue={totalSDGImpactValue}
                                                                negativeImpactByType={negativeImpactByType}
                                                                language={language}
                                                                negativeCalculation={negativeCalculation}
            />)}
        </div>
    )
}

export default SDGContinentsTab;
