import React, {useContext, useEffect, useState} from 'react';
import SwiperNext from './swiper/SwiperNext';
import {Settings} from '../../store';
import translations from '../../data/footprint/lang.json';
import Task from './Task';
import VerticalBusinessLine from './VerticalBusinessLine';
import BFData from '../../data/footprint/businessFootprint.json';
import {langSeparator} from '../../hooks/helperFunctions';

import CTABlock from './ctaBlock/CTABlock';


const Business = (props) => {
    const backgroundUrl = '../media/business-lg.svg';
    const {
        swiper,
        isCalendlyOpen,
        setIsCalendlyOpen,
        isContactOpen,
        setIsContactOpen,
        hideContactModal,
        showContactModal,
    } = props;
    const settingsState = useContext(Settings.State);
    const {businessValue, language, businessSubIndustry, companyRevenue, businessSector,activeCurrencyLang} = settingsState;
    const [taskList, setTaskList] = useState([]);
    const [diagramData, setDiagramData] = useState(null);
    const [selectedData, setSelectedData] = useState(null);
    const divider = 1000000;
    const currencyValuePerUSD = BFData[activeCurrencyLang].currentValuePerUSD;

    useEffect(() => {
        setTaskList(translations[language].action_list_3.items);
    }, [language]);

    useEffect(() => {
        let preparedDiagramData = [];
        let finalDiagramArray = [];

        BFData[language].searchableValues.map((item) => {
            if (preparedDiagramData[item.sector] === undefined) {
                preparedDiagramData[item.sector] = {name: item.sector, value: [item.value], selectedValue: ''};
            } else {
                preparedDiagramData[item.sector].value.push(item.value);
            }
            if (businessSubIndustry === item.sub_industry) {
                preparedDiagramData[item.sector].selectedValue = item.value;
            }
        });

        Object.keys(preparedDiagramData).map((item) => {
            var total = 0;
            var avg;
            for (var i = 0; i < preparedDiagramData[item].value.length; i++) {
                if (BFData[activeCurrencyLang].maxVal.value !== preparedDiagramData[item].value[i]) {
                    total += preparedDiagramData[item].value[i];
                } else {
                    total = preparedDiagramData[item].value[i];
                    break;
                }
            }
            avg = ((total / preparedDiagramData[item].value.length) / divider) / currencyValuePerUSD;

            if (preparedDiagramData[item].value.includes(BFData[activeCurrencyLang].maxVal.value)) {
                avg = (BFData[activeCurrencyLang].maxVal.value / divider) / currencyValuePerUSD;
            }
            if (preparedDiagramData[item].value.includes(BFData[activeCurrencyLang].minVal.value)) {
                avg = (BFData[activeCurrencyLang].minVal.value / divider) / currencyValuePerUSD;
            }
            if (preparedDiagramData[item].selectedValue !== '') {
                avg = (preparedDiagramData[item].selectedValue / divider) / currencyValuePerUSD;
            }
            if (item !== businessSector) {
                finalDiagramArray.push({avgVal: avg, name: item});
            } else {
                setSelectedData({avgVal: avg, name: item});
            }
            finalDiagramArray.sort(function(a, b) {
                return b.avgVal - a.avgVal;
            });
        });

        setDiagramData(finalDiagramArray);
    }, []);


    return (<>
                <div className="block__content">
                    <img className="top-img m-auto d-block position-absolute start-50 translate-middle-x"
                         src={backgroundUrl} alt="My Image"/>
                    <div className="position-absolute start-0 end-0 z-n1">
                        <svg width="2440" height="467" viewBox="0 0 2440 467" fill="none"
                             xmlns="http://www.w3.org/2000/svg"
                             className="position-absolute top-0 start-50 translate-middle-x h-auto"
                             style={{width: '130vw'}}>
                            <g clipPath="url(#clip0_1733_11671)">
                                <path d="M1259.07 1.02893C1674.94 5.57879 2031.82 22.8767 2031.82 22.8767L2440 55.9526C2440 55.9526 2440 47.9593 2440 135.441V467H0V135.41C0.000284209 90.9229 0 82.9297 0 82.9297C102.783 71.3766 338.14 45.3852 397.354 38.3634C661.823 6.99785 993.403 -0.056903 1165.51 0.000344494C1198.06 0.361454 1229.21 0.702284 1259.07 1.02893Z"
                                      fill="url(#paint0_linear_1733_11671)"/>
                            </g>
                            <defs>
                                <linearGradient id="paint0_linear_1733_11671" x1="1220" y1="0" x2="1220" y2="467"
                                                gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#F6ECFB"/>
                                    <stop offset="1" stopColor="#F8F4FA"/>
                                </linearGradient>
                                <clipPath id="clip0_1733_11671">
                                    <rect width="2440" height="467" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div className="container position-relative py-6 py-lg-9">
                        <div className="row justify-content-center">
                            <div className="col-lg-12 col-xl-10 d-flex flex-column gap-5 gap-lg-6">
                                <div className="row justify-content-center text-center">
                                    <div className="col-sm-10 col-lg-9 col-xl-7 d-flex flex-column gap-4">
                                        <div className="d-flex flex-row align-items-baseline justify-content-center">
                                            <h4 className="large footprint-value mt-3">{businessValue.toLocaleString(langSeparator(language), {maximumFractionDigits: 1})}
                                            </h4><strong className="ms-2 small"> tCO<sub>2</sub></strong>
                                        </div>
                                        <h2 className="">{translations[language].businessSection.title}</h2>
                                        <p className="mb-5 preamble">{translations[language].businessSection.subtitle}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <h4 className=" text-center">{translations[language].businessSection.soloTitle}</h4>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-8">
                                        <div className="progress-list flex-column mb-lg-0 mb-5 rounded"
                                             style={{flex: '1 0'}}>
                                            <div className="mx-auto w-100">
                                                {selectedData !== null &&
                                                        <VerticalBusinessLine currentValue={companyRevenue}
                                                                              key={selectedData.name}
                                                                              maxVal={BFData[activeCurrencyLang].maxVal.value}
                                                                              value={selectedData.avgVal}
                                                                              minVal={BFData[activeCurrencyLang].minVal.value}
                                                                              name={selectedData.name}
                                                                              businessSector={businessSector}
                                                                              industryTitle={businessSubIndustry}
                                                                              language={language}
                                                                              maxDiagramValue={diagramData[0].avgVal}
                                                                              subIndustryName={businessSubIndustry}
                                                        />}
                                                {(diagramData !== undefined && diagramData !== null) && diagramData.map((item, index) => {
                                                    if (item.name !== businessSector) {
                                                        return <VerticalBusinessLine currentValue={companyRevenue}
                                                                                     key={item.name}
                                                                                     maxVal={BFData[activeCurrencyLang].maxVal.value}
                                                                                     value={item.avgVal}
                                                                                     minVal={BFData[activeCurrencyLang].minVal.value}
                                                                                     name={item.name}
                                                                                     businessSector={businessSector}
                                                                                     industryTitle={businessSubIndustry}
                                                                                     language={language}
                                                                                     maxDiagramValue={diagramData[0].avgVal}
                                                                                     subIndustryName={businessSubIndustry}
                                                        />;
                                                    }
                                                })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container position-relative py-6 py-lg-9">
                        <svg width="2440" height="467" viewBox="0 0 2440 467" fill="none"
                             xmlns="http://www.w3.org/2000/svg"
                             className="position-absolute top-0 start-50 translate-middle-x h-auto z-n1"
                             style={{width: '130vw'}}>
                            <g clipPath="url(#clip0_1733_11671)">
                                <path d="M1259.07 1.02893C1674.94 5.57879 2031.82 22.8767 2031.82 22.8767L2440 55.9526C2440 55.9526 2440 47.9593 2440 135.441V467H0V135.41C0.000284209 90.9229 0 82.9297 0 82.9297C102.783 71.3766 338.14 45.3852 397.354 38.3634C661.823 6.99785 993.403 -0.056903 1165.51 0.000344494C1198.06 0.361454 1229.21 0.702284 1259.07 1.02893Z"
                                      fill="url(#paint0_linear_1733_11671)"/>
                            </g>
                            <defs>
                                <linearGradient id="paint0_linear_1733_11671" x1="1220" y1="0" x2="1220" y2="467"
                                                gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#F6ECFB"/>
                                    <stop offset="1" stopColor="#F8F4FA"/>
                                </linearGradient>
                                <clipPath id="clip0_1733_11671">
                                    <rect width="2440" height="467" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                        <div className="row justify-content-center">
                            <div className="col-lg-8 d-flex flex-column gap-6 gap-lg-7">
                                <div className="row justify-content-center text-center">
                                    <div className="col-md-10 col-xl-9 d-flex flex-column gap-4">
                                        <h2 className="">{translations[language].businessSection.title2}</h2>
                                        <p className="preamble m-0">{translations[language].businessSection.subtitle2}</p>
                                    </div>
                                </div>
                                <div className="row row-cols-1 row-cols-md-1 g-2">
                                    <div className="col mb-3">
                                        <h4 className="large">{translations[language].businessSection.analizer}</h4>
                                    </div>
                                    <div className="col">
                                        <div className="card bg-dark p-5 p-lg-6 rounded d-flex flex-column flex-lg-row gap-3 gap-lg-4 h-100">
                                            <div className="flex-shrink-0">
                                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M23.9999 8.84492C26.9219 8.84492 29.6867 9.52592 32.1497 10.7325C32.4161 10.8627 32.7347 10.7859 32.9051 10.5435L34.9955 7.55792C35.2025 7.26272 35.1077 6.84692 34.7879 6.68012C31.5593 4.99832 27.8915 4.04492 23.9999 4.04492C20.1083 4.04492 16.4405 4.99892 13.2119 6.68012C12.8921 6.84632 12.7973 7.26212 13.0043 7.55792L15.0947 10.5435C15.2645 10.7865 15.5837 10.8633 15.8501 10.7325C18.3131 9.52532 21.0779 8.84492 23.9999 8.84492Z"
                                                          fill="#C76FF2"/>
                                                    <path d="M39.8191 10.2122C39.5533 9.96799 39.1291 10.0208 38.9221 10.3166L36.8335 13.2992C36.6637 13.5416 36.7003 13.868 36.9133 14.0738C40.4167 17.4578 42.5989 22.2014 42.5989 27.4454C42.5989 28.1018 42.5611 28.7492 42.4933 29.3882C42.4621 29.6834 42.6439 29.9564 42.9307 30.0332L46.4485 30.9758C46.7971 31.0694 47.1547 30.8378 47.2015 30.4802C47.3311 29.4866 47.3995 28.4738 47.3995 27.4454C47.3989 20.624 44.4763 14.489 39.8191 10.2122Z"
                                                          fill="#7C599F"/>
                                                    <path d="M9.07641 10.3163C8.86941 10.0205 8.44521 9.96833 8.17941 10.2119C3.52221 14.4887 0.599609 20.6237 0.599609 27.4451C0.599609 28.4741 0.668009 29.4869 0.797609 30.4799C0.844409 30.8375 1.20201 31.0685 1.55061 30.9755L5.06841 30.0329C5.35521 29.9561 5.53701 29.6825 5.50581 29.3879C5.43741 28.7489 5.39961 28.1015 5.39961 27.4451C5.39961 22.2011 7.58181 17.4581 11.0846 14.0735C11.2976 13.8677 11.3342 13.5419 11.1644 13.2989L9.07641 10.3163Z"
                                                          fill="#7C599F"/>
                                                    <path d="M23.8275 27.773C23.9181 27.4976 24.3069 27.4976 24.3975 27.773C25.7745 31.943 26.7441 35.3036 26.8053 36.4832C26.7933 37.958 25.5903 39.1544 24.1125 39.1544C22.6347 39.1544 21.4311 37.958 21.4197 36.4832C21.4803 35.3042 22.4499 31.9436 23.8275 27.773ZM16.6191 36.4616C16.6191 40.5998 19.9737 43.9544 24.1119 43.9544C28.2501 43.9544 31.6047 40.5998 31.6047 36.4616C31.6047 32.8034 25.7499 17.0576 24.3909 13.4522C24.2931 13.193 23.9295 13.193 23.8323 13.4522C22.4739 17.0576 16.6191 32.804 16.6191 36.4616Z"
                                                          fill="#59497A"/>
                                                </svg>
                                            </div>
                                            <div>
                                                <h4 className="mb-2">1. {translations[language].businessSection.cardTitle1}</h4>
                                                <p className="mb-0">{translations[language].businessSection.cardsubtitle1}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card bg-dark p-5 p-lg-6 rounded d-flex flex-column flex-lg-row gap-3 gap-lg-4 h-100">
                                            <div className="flex-shrink-0">
                                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M25.8016 3.54004H22.2016C21.8704 3.54004 21.6016 3.80884 21.6016 4.14004V17.472C21.6016 17.8032 21.8704 18.072 22.2016 18.072H25.8016C26.1328 18.072 26.4016 17.8032 26.4016 17.472V4.14004C26.4016 3.80824 26.1328 3.54004 25.8016 3.54004Z"
                                                          fill="#6ECA5B"/>
                                                    <path d="M18.034 1.13965H14.434C14.1028 1.13965 13.834 1.40845 13.834 1.73965V17.4716C13.834 17.8028 14.1028 18.0716 14.434 18.0716H18.034C18.3652 18.0716 18.634 17.8028 18.634 17.4716V1.73965C18.634 1.40785 18.3652 1.13965 18.034 1.13965Z"
                                                          fill="#6ECA5B"/>
                                                    <path d="M33.5691 1.13965H29.9691C29.6379 1.13965 29.3691 1.40845 29.3691 1.73965V17.4716C29.3691 17.8028 29.6379 18.0716 29.9691 18.0716H33.5691C33.9003 18.0716 34.1691 17.8028 34.1691 17.4716V1.73965C34.1691 1.40785 33.9003 1.13965 33.5691 1.13965Z"
                                                          fill="#6ECA5B"/>
                                                    <path d="M18.2641 36.066C15.1189 36.087 12.3043 34.1154 11.2501 31.152C10.7581 29.922 10.5541 27.606 10.5001 25.542C10.4965 25.383 10.6219 25.251 10.7809 25.2468C10.7911 25.2468 10.8019 25.2468 10.8121 25.248C13.0561 25.476 16.3441 26.148 18.9061 28.026C19.1761 28.218 19.5505 28.155 19.7431 27.885C19.7653 27.8538 19.7845 27.8208 19.8001 27.786C20.3515 26.7594 21.0049 25.7904 21.7501 24.894C21.9499 24.63 21.8977 24.2532 21.6337 24.0534C21.6229 24.045 21.6115 24.0372 21.6001 24.03C15.9541 19.974 8.40013 20.304 7.98013 20.322L6.35413 20.406C6.03433 20.4222 5.78353 20.6862 5.78413 21.006L5.70613 22.632C5.70613 23.334 5.42413 29.556 6.77413 32.94C9.25213 39.066 14.5081 40.752 18.2401 40.866C18.5713 40.866 18.8401 40.5972 18.8401 40.266V36.666C18.8401 36.3438 18.5863 36.0792 18.2641 36.066Z"
                                                          fill="#455E4D"/>
                                                    <path d="M42.0005 22.6086L41.9225 20.9826C41.9231 20.6646 41.6759 20.4018 41.3585 20.3826L39.7325 20.2926C39.2885 20.2926 30.3005 19.8786 24.6005 25.2366L24.3605 25.4826L24.3125 25.5246C22.8665 27.0126 21.3125 29.4726 21.3125 30.5346V46.1226C21.3125 46.4538 21.5813 46.7226 21.9125 46.7226H25.5125C25.8437 46.7226 26.1125 46.4538 26.1125 46.1226V31.2186C26.1107 31.1112 26.1377 31.0056 26.1905 30.9126C28.5545 26.7126 33.7685 25.5486 36.9125 25.2246C37.0673 25.2078 37.2065 25.32 37.2227 25.4748C37.2239 25.4874 37.2245 25.5 37.2245 25.5126C37.1705 27.5826 36.9605 29.8986 36.4685 31.1286C35.3813 34.1928 32.4275 36.1932 29.1785 36.0666C28.8473 36.0666 28.5785 36.3354 28.5785 36.6666V40.2666C28.5785 40.5978 28.8473 40.8666 29.1785 40.8666H29.8865C34.8377 40.7142 39.2063 37.5852 40.9445 32.9466C42.3065 29.5266 42.0545 23.3046 42.0005 22.6086Z"
                                                          fill="#567E59"/>
                                                </svg>
                                            </div>
                                            <div>
                                                <h4 className="mb-2">2. {translations[language].businessSection.cardTitle2}</h4>
                                                <p className="mb-0">{translations[language].businessSection.cardsubtitle2}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card bg-dark p-5 p-lg-6 rounded d-flex flex-column flex-lg-row gap-3 gap-lg-4 h-100">
                                            <div className="flex-shrink-0">
                                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M9.22404 23.8561C4.38744 23.8561 0.414837 20.0833 0.0950373 15.3265C0.0722373 14.9881 0.355437 14.7061 0.695037 14.7061H4.31424C4.61424 14.7061 4.86924 14.9287 4.90524 15.2263C5.16324 17.3803 7.00164 19.0561 9.22404 19.0561C11.4464 19.0561 13.2854 17.3803 13.5428 15.2263C13.5782 14.9281 13.8332 14.7061 14.1338 14.7061H17.753C18.0926 14.7061 18.3758 14.9881 18.353 15.3265C18.0332 20.0833 14.0606 23.8561 9.22404 23.8561Z"
                                                          fill="#45A2FF"/>
                                                    <path d="M37.8255 46.8H10.0551C9.72388 46.8 9.45508 46.5312 9.45508 46.2V42.6C9.45508 42.2688 9.72388 42 10.0551 42H37.8261C38.1573 42 38.4261 42.2688 38.4261 42.6V46.2C38.4255 46.5312 38.1573 46.8 37.8255 46.8Z"
                                                          fill="#464F81"/>
                                                    <path d="M38.4243 6.60063H26.8335V1.74063C26.8335 1.40943 26.5647 1.14062 26.2335 1.14062H21.9141C21.5829 1.14062 21.3141 1.40943 21.3141 1.74063V6.60063H8.85391C8.52271 6.60063 8.25391 6.86943 8.25391 7.20063V10.8006C8.25391 11.1318 8.52271 11.4006 8.85391 11.4006H21.3141V38.9406C21.3141 39.2718 21.5829 39.5406 21.9141 39.5406H26.2335C26.5647 39.5406 26.8335 39.2718 26.8335 38.9406V11.4006H38.4243C38.7555 11.4006 39.0243 11.1318 39.0243 10.8006V7.20063C39.0243 6.86943 38.7561 6.60063 38.4243 6.60063Z"
                                                          fill="#4C68A0"/>
                                                    <path d="M38.7729 23.7082C33.9357 23.7082 29.9637 19.9354 29.6439 15.178C29.6211 14.8396 29.9043 14.5576 30.2439 14.5576H33.8631C34.1631 14.5576 34.4181 14.7802 34.4541 15.0778C34.7121 17.2318 36.5505 18.9076 38.7729 18.9076C40.9959 18.9076 42.8343 17.2324 43.0917 15.0778C43.1271 14.7796 43.3821 14.5576 43.6827 14.5576H47.3019C47.6415 14.5576 47.9247 14.8396 47.9019 15.178C47.5821 19.9354 43.6101 23.7082 38.7729 23.7082Z"
                                                          fill="#45A2FF"/>
                                                </svg>
                                            </div>
                                            <div>
                                                <h4 className="mb-2">3. {translations[language].businessSection.cardTitle3}</h4>
                                                <p className="mb-0">{translations[language].businessSection.cardsubtitle3}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col mb-3 mt-6">
                                        <h4 className="large">{translations[language].businessSection.analizer2}</h4>
                                    </div>
                                    <div className="col">
                                        <div className="card bg-dark p-5 p-lg-6 rounded d-flex flex-column flex-lg-row gap-3 gap-lg-4 h-100">
                                            <div className="flex-shrink-0">
                                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_1726_12122)">
                                                        <path d="M47.8232 10.3388L44.8034 7.31895C44.5688 7.08435 44.5688 6.70455 44.8034 6.47055L47.7038 3.57015C47.9384 3.33555 47.9384 2.95575 47.7038 2.72175L45.1586 0.17595C44.924 -0.05865 44.5442 -0.05865 44.3102 0.17595L41.4092 3.07695C41.1746 3.31155 40.7948 3.31155 40.5608 3.07695L37.7792 0.29535C37.5446 0.06075 37.1648 0.06075 36.9308 0.29535L34.3856 2.84115C34.1516 3.07575 34.1516 3.45555 34.3856 3.68955L37.1666 6.47055C37.4012 6.70515 37.4012 7.08495 37.1666 7.31895L34.2656 10.22C34.0316 10.4546 34.031 10.8344 34.2656 11.0684L36.8108 13.6142C37.0454 13.8488 37.4252 13.8488 37.6592 13.6142L40.5602 10.7132C40.7948 10.4786 41.1746 10.4786 41.4086 10.7132L44.429 13.7336C44.6636 13.9682 45.0434 13.9682 45.2774 13.7336L47.8226 11.1878C48.0578 10.9526 48.0578 10.5728 47.8232 10.3388Z"
                                                              fill="#59497A"/>
                                                        <path d="M7.65 48.0002C3.432 48.0002 0 44.5682 0 40.3502C0 36.1322 3.432 32.7002 7.65 32.7002C11.868 32.7002 15.3 36.1322 15.3 40.3502C15.3 44.5682 11.868 48.0002 7.65 48.0002ZM7.65 37.5002C6.0786 37.5002 4.8 38.7788 4.8 40.3502C4.8 41.9216 6.0786 43.2002 7.65 43.2002C9.2214 43.2002 10.5 41.9216 10.5 40.3502C10.5 38.7788 9.2214 37.5002 7.65 37.5002Z"
                                                              fill="#C76FF2"/>
                                                        <path d="M41.4008 29.6726L6.94158 13.1684C6.73338 13.0688 6.60078 12.8582 6.60078 12.6272V9.6002C6.60078 9.269 6.86958 9.0002 7.20078 9.0002H28.2008C28.532 9.0002 28.8008 8.7314 28.8008 8.4002V4.8002C28.8008 4.469 28.532 4.2002 28.2008 4.2002H2.40078C2.06958 4.2002 1.80078 4.469 1.80078 4.8002V10.7066V15.6506C1.80078 15.8816 1.93338 16.0922 2.14158 16.1918L6.60078 18.3272L41.06 34.8314C41.2682 34.931 41.4008 35.1416 41.4008 35.3726V38.4002C41.4008 38.7314 41.132 39.0002 40.8008 39.0002H19.8008C19.4696 39.0002 19.2008 39.269 19.2008 39.6002V43.2002C19.2008 43.5314 19.4696 43.8002 19.8008 43.8002H45.6008C45.932 43.8002 46.2008 43.5314 46.2008 43.2002V37.2938V32.3498C46.2008 32.1188 46.0682 31.9082 45.86 31.8086L41.4008 29.6726Z"
                                                              fill="#7C599F"/>
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_1726_12122">
                                                            <rect width="48" height="48" fill="white"/>
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                            <div>
                                                <h4 className="mb-2">4. {translations[language].businessSection.cardTitle4}</h4>
                                                <p className="mb-0">{translations[language].businessSection.cardsubtitle4}</p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container position-relative pb-9">
                        <div className="row justify-content-center">
                            <div className="col-lg-10 d-flex flex-column gap-7">
                                <div className="row mt-6 justify-content-center text-center">
                                    <div className="col-xl-8 col-lg-8">
                                        <h2>{translations[language].businessSection.expertsTitle}</h2>
                                    </div>
                                </div>
                                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-6">
                                    <div className="col">
                                        <div className="card d-flex flex-column gap-1">
                                            <img className="mb-4 mx-auto rounded-pill img-thumbnail d-block img-fluid"
                                                 src={'../media/footprint/philip_mitchell.jpg'} alt="Philip Mitchell"/>
                                            <h4 className="large">{translations[language].whatcanyoudo.popup.teammembername1}</h4>
                                            <p><i>{translations[language].whatcanyoudo.popup.teammemberposition1}</i>
                                            </p>
                                            <p className="small">{translations[language].whatcanyoudo.popup.teammembernamebio1}</p>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card d-flex flex-column gap-1">
                                            <img className="mb-4 mx-auto rounded-pill img-thumbnail d-block img-fluid"
                                                 src={'../media/footprint/fridtjof_wergeland.jpg'}
                                                 alt="Fridtjof Wergeland"/>
                                            <h4 className="large">{translations[language].whatcanyoudo.popup.teammembername2}</h4>
                                            <p><i>{translations[language].whatcanyoudo.popup.teammemberposition2}</i>
                                            </p>
                                            <p className="small">{translations[language].whatcanyoudo.popup.teammembernamebio2}</p>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="card d-flex flex-column gap-1">
                                            <img className="mb-4 mx-auto rounded-pill img-thumbnail d-block img-fluid"
                                                 src={'../media/footprint/helena_von_der_esch.jpg'}
                                                 alt="Helena von der Esch"/>
                                            <h4 className="large">{translations[language].whatcanyoudo.popup.teammembername3}</h4>
                                            <p><i>{translations[language].whatcanyoudo.popup.teammemberposition3}</i>
                                            </p>
                                            <p className="small">{translations[language].whatcanyoudo.popup.teammembernamebio3}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-12 col-xl-10 text-center">
                                        <CTABlock language={language} isCalendlyOpen={isCalendlyOpen}
                                                  setIsCalendlyOpen={setIsCalendlyOpen}
                                                  isContactOpen={isContactOpen} setIsContactOpen={setIsContactOpen}
                                                  showContactModal={showContactModal}
                                                  hideContactModal={hideContactModal}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container position-relative py-9">
                        <svg width="2440" height="467" viewBox="0 0 2440 467" fill="none"
                             xmlns="http://www.w3.org/2000/svg"
                             className="position-absolute top-0 start-50 translate-middle-x h-auto z-n1"
                             style={{width: '130vw'}}>
                            <g clipPath="url(#clip0_1733_11671)">
                                <path d="M1259.07 1.02893C1674.94 5.57879 2031.82 22.8767 2031.82 22.8767L2440 55.9526C2440 55.9526 2440 47.9593 2440 135.441V467H0V135.41C0.000284209 90.9229 0 82.9297 0 82.9297C102.783 71.3766 338.14 45.3852 397.354 38.3634C661.823 6.99785 993.403 -0.056903 1165.51 0.000344494C1198.06 0.361454 1229.21 0.702284 1259.07 1.02893Z"
                                      fill="url(#paint0_linear_1733_11671)"/>
                            </g>
                            <defs>
                                <linearGradient id="paint0_linear_1733_11671" x1="1220" y1="0" x2="1220" y2="467"
                                                gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#F6ECFB"/>
                                    <stop offset="1" stopColor="#F8F4FA"/>
                                </linearGradient>
                                <clipPath id="clip0_1733_11671">
                                    <rect width="2440" height="467" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                        <div className="row justify-content-center">
                            <div className="col-lg-8 d-flex flex-column gap-6">

                                <div className="row justify-content-center text-center">
                                    <div className="col-md-10 col-lg-8 col-xl-6 d-flex flex-column gap-4">
                                        <h2>{translations[language].businessSection.title3}</h2>
                                        <p className="preamble m-0">{translations[language].businessSection.subtitle3}</p>
                                    </div>
                                </div>

                                <div className="row row-cols-1 g-2">
                                    {taskList.map((item) => {
                                        return <Task showContactModal={showContactModal}
                                                     key={item.id} item={item}/>;
                                    })}
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-12 col-xl-10">
                                        <SwiperNext swiper={swiper} name={translations[language].general.next}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    );
};
export default Business;