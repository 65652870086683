import React, {useContext} from 'react';
import {Settings} from '../../../store';
import translations from '../../../data/footprint/lang.json';

const Back = () => {
    const settingsState = useContext(Settings.State);
    const dispatch = useContext(Settings.Dispatch);
    const {activeScreen, language} = settingsState;

    return <div className="position-absolute top-0 start-0">
        <div className="d-block p-5">
            <button className="btn btn-link btn-light btn--svg d-flex gap-1" role="button" onClick={() => {
                dispatch({type: 'setActiveScreen', payload: activeScreen - 1});
                if (activeScreen === 2) {
                    dispatch({type: 'setFinalResult', payload: false});
                }
            }}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="mask0_1490_22397" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0"
                          width="24" height="24">
                        <rect width="24" height="24" fill="#D9D9D9"/>
                    </mask>
                    <g mask="url(#mask0_1490_22397)">
                        <path d="M10.875 19.3001L4.275 12.7001C4.175 12.6001 4.10417 12.4918 4.0625 12.3751C4.02083 12.2584 4 12.1334 4 12.0001C4 11.8668 4.02083 11.7418 4.0625 11.6251C4.10417 11.5084 4.175 11.4001 4.275 11.3001L10.875 4.70011C11.0583 4.51678 11.2875 4.42094 11.5625 4.41261C11.8375 4.40428 12.075 4.50011 12.275 4.70011C12.475 4.88344 12.5792 5.11261 12.5875 5.38761C12.5958 5.66261 12.5 5.90011 12.3 6.10011L7.4 11.0001H18.575C18.8583 11.0001 19.0958 11.0959 19.2875 11.2876C19.4792 11.4793 19.575 11.7168 19.575 12.0001C19.575 12.2834 19.4792 12.5209 19.2875 12.7126C19.0958 12.9043 18.8583 13.0001 18.575 13.0001H7.4L12.3 17.9001C12.4833 18.0834 12.5792 18.3168 12.5875 18.6001C12.5958 18.8834 12.5 19.1168 12.3 19.3001C12.1167 19.5001 11.8833 19.6001 11.6 19.6001C11.3167 19.6001 11.075 19.5001 10.875 19.3001Z"
                              fill="white"/>
                    </g>
                </svg>
                <span>{translations[language].general.goback}</span></button>
        </div>
    </div>;
};

export default Back;