import React, {useEffect, useRef, useState} from 'react';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import translations from '../../../data/impact/lang.json';
import {Settings} from '../../../store';
import SectorCard from './SectorCard';
import {SpreadGrid} from '../../../hooks/spreadGrid';
import SectorModal from './SectorModal';
import sectorsData from '../../../data/impact/sectors-data.json';
import {langSeparator} from '../../../hooks/helperFunctions';

const Sectors = (props) => {
    gsap.registerPlugin(ScrollTrigger);
    const {negativeCalculation} = props;
    const settingsState = React.useContext(Settings.State);
    const {language} = settingsState;
    const [totalImpactValue, setTotalImpactValue] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [activeSector, setActiveSector] = useState(sectorsData['sector2']);
    const el = useRef(null);
    const elGroup = gsap.utils.selector(el);

    const showModal = () => {
        if (!isOpen) {
            setIsOpen(true);
        }
    };
    const hideModal = () => {
        if (activeSector.id !== 1) {
            document.getElementById('sector_cards').querySelector('.card[data-id="' + activeSector.id + '"]').click();
        }
        setIsOpen(false);
    };

    useEffect(() => {
        elGroup('.grid').forEach((item) => {
            gsap.set(item, {
                height: item.clientHeight,
            });
        });
        gsap.set(elGroup('.grid__item'), {
            scale: 0,
            rotation: 0,
            x: 0,
            y: 0,
            opacity: 0,
        });
        let tl = gsap.timeline({
            scrollTrigger: {
                trigger: el.current,
                start: 'top 50%',
                end: 'bottom',
                scrub: false,
            },
        });
        let delay = 0.05;
        let sum = 0;

        Object.keys(sectorsData).forEach((key, index) => {
            sum += sectorsData[key].value;
            tl.to(elGroup('.grid__item')[index], {
                opacity: 1,
                delay: delay,
                scale: 1,
                duration: 0.5,
            }, 'same');
            delay += 0.05;
        });

        setTotalImpactValue(sum);

        if (window.innerWidth > 992) {
            [...elGroup('.grid')].forEach(grid => new SpreadGrid(grid));
        }

        gsap.set(elGroup('.word span'), {
            y: 100,
            position: 'relative',
            opacity: 0,
            transitionTimingFunction: 'cubic-bezier(0.19, 1, 0.22, 1)',
            display: 'inline-block',
        });

        delay = 0.05;
        gsap.utils.toArray(elGroup('.word span')).forEach(function(e, index) {
            gsap.to(elGroup('.word span')[index], {
                scrollTrigger: {
                    trigger: el.current,
                    start: 'top 70%',
                    scrub: false,
                },
                y: 0,
                ease: 'expo.out',
                duration: 0.8,
                delay,
                opacity: 1,
            });
            delay += 0.05;
        });
    }, []);

    return (
            <section ref={el} id={'sector_cards'}
                     className="block block--cards"
                     data-spy={'#sector_cards'}
                     data-section-theme="red">
                <div className="block__content">
                    <div className="container d-flex flex-column gap-5 gap-lg-6">
                        <div className="row justify-content-center text-center mt-lg-5">
                            <div className="col-auto block__heading">
                                <h2 className="formue-animation large">Sectors</h2>
                                <p className="lead formue-animation" data-delay={0.1}>This is how your share of negative impact is distributed across sectors.</p>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-9">
                                <div className="formue-animation text-center mb-5" data-delay={0.4}>
                                  <div className="dynamic-tooltip">
                                    <div className="dynamic-tooltip__inner">
                                      <p>Click them for more details</p>
                                    </div>
                                  </div>
                               </div>
                                <div className="grid d-block d-lg-grid"
                                     style={{
                                         display: 'grid',
                                         gridTemplateColumns: 'repeat(8, 1fr)',
                                         gridTemplateRows: 'repeat(12, 1fr)',
                                         gap: 24,
                                     }}
                                     data-duration="1"
                                     data-ease="elastic.out(0.5)"
                                     data-scale="1.4"
                                     data-max-rotation="20"
                                     data-spread="150"
                                     data-max-distance="700"

                                >
                                    {Object.keys(sectorsData).map((sector, index) => {
                                        let item = sectorsData[sector];
                                        let percent = (100 * item.value / totalImpactValue).toFixed(0);

                                        return item.name !== 'Remaining Sectors' && <SectorCard
                                                grid={item.grid}
                                                negativeCalculation={negativeCalculation}
                                                img={item.img}
                                                percent={percent}
                                                showModal={showModal}
                                                setActiveSector={setActiveSector}
                                                cardID={item.id}
                                                key={item.id}
                                                name={item.name}
                                        />;
                                    })
                                    }
                                </div>
                                <p className="text-center small mt-5 formue-animation" data-delay={0.2}>
                                    <a onClick={() => {
                                        setActiveSector(sectorsData['sector1']);
                                        setTimeout(() => {
                                            showModal();
                                        }, 200);
                                    }}
                                            className="" role="button"><strong>Remaining
                                        sectors</strong></a>: - {((100 * sectorsData['sector1'].value / totalImpactValue).toFixed(0) * negativeCalculation / 100).toLocaleString(langSeparator(language), {maximumFractionDigits: 0})} {translations[language].general.currency} (Companies: {sectorsData['sector1'].companies},
                                    Total: {(sectorsData['sector1'].value * translations[language].general.currencyvalue / 1000000000).toLocaleString(langSeparator(language), {maximumFractionDigits: 0})} {translations[language].general.billions} {translations[language].general.currency})</p>
                            </div>
                        </div>
                    </div>
                </div>
                <SectorModal
                        activeSector={activeSector}
                        isOpen={isOpen}
                        hideModal={hideModal}
                        totalImpactValue={totalImpactValue}
                        negativeCalculation={negativeCalculation}
                />
            </section>

    );
};

export default Sectors;
