import {useContext, useEffect} from 'react';
import {Settings} from '../store';

export const useLanguageDetector = () => {
    const dispatch = useContext(Settings.Dispatch);
    useEffect(() => {
        if (!dispatch) {
            return null;
        }
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        if (params.language) {
            dispatch({type: 'setLanguage', payload: params.language.toLowerCase()});
        } else {
            const {hostname} = window.location;
            const hostnameParts = hostname.split('.');
            switch (hostnameParts[hostnameParts.length - 1]) {
                case 'no': {
                    dispatch({type: 'setLanguage', payload: 'no'});
                    break;
                }
                case 'se': {
                    dispatch({type: 'setLanguage', payload: 'se'});
                    break;
                }
                case 'com': {
                    dispatch({type: 'setLanguage', payload: 'com'});
                    break;
                }
                default: {
                    dispatch({type: 'setLanguage', payload: 'no'});
                    break;
                }
            }
        }
    }, []);
    return null;
};