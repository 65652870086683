import React from 'react';
import Modal from 'react-bootstrap/Modal';
import sdgRemainingData from '../../../data/impact/sdgremaining-data.json';


const SDGRemainingModal = (props) => {
    const {
        isOpenRemaining,
        hideModalRemaining,
    } = props;

    return (<Modal size="xl"
                   fullscreen={true}
                   scrollable={true}
                   dialogClassName={'modal-fullscreen-md-down'}
                   aria-labelledby="contained-modal-title-vcenter"
                   centered show={isOpenRemaining} onHide={hideModalRemaining}
                   className={''}
                   onExit={() => document.body.classList.remove('selected')}
    >
        <div className="modal-header">
            <button onClick={hideModalRemaining} type="button" className="btn-close" data-bs-dismiss="modal"
                    aria-label="Close"></button>
        </div>
        <div className="modal-body px-4">
            <div className="container details d-flex flex-column gap-3 gap-lg-6 px-0" data-sdg="3">

            <div className="row justify-content-center text-center my-3 my-lg-5">
                <div className="col-lg-8 d-flex flex-column gap-3 gap-lg-4">
                    <h2 className="formue-animation">Remaining SDGs</h2>
                    <p className="lead formue-animation" data-delay={0.1}>Ensure healthy lives and promote well-being for all at all ages.</p>
                </div>
            </div>
            <div className="row text-center justify-content-center">
              <div className="col-lg-10 d-flex flex-row flex-wrap gap-3 align-items-center justify-content-center">
                  {Object.keys(sdgRemainingData).map((item) => {
                      const SDGRem = sdgRemainingData[item];
                      const url = 'media/SDGs/' + SDGRem.id + '.svg';
                      const width = 160;
                      const height = 160;
                      return React.createElement(
                              'img',
                              {src: url,
                                className: 'd-block mw-100', width, height},
                              null,
                      );
                  })}
              </div>
            </div>
          </div>
        </div>
    </Modal>);
};

export default SDGRemainingModal;
