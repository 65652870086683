import Home from './components/Home';
import {useLanguageDetector} from './services/useLanguageDetector';
import './App.scss';
import {gsap} from 'gsap';
import {usePageTracking} from './services/usePageTracking';

const App = () => {
    useLanguageDetector();
    const website = usePageTracking();
    gsap.config({
        nullTargetWarn: false,
    });
    return (
            <Home website={website}/>
    );
};

export default App;