import React, {useContext} from 'react';
import {Settings} from '../../store';

const EngagementBars = (props) => {
    const {type} = props;

    const settingsState = useContext(Settings.State);
    const {
        sustainableColor, neutralsColor, transitionColor, significantColor,
    } = settingsState;

    const barwidth = '';
    const barDefaultColor = 'rgba(0,0,0,.025)';
    return (<>
        <div className="row align-items-start">
            <div className="col-3 col-xl-3 col-lg-6 d-flex flex-column gap-1 text-center graph-bars px-2">
                <div style={{background: barDefaultColor, width: barwidth}} className="py-2"></div>
                <div style={{background: barDefaultColor, width: barwidth}} className="py-2"></div>
                <div style={{background: barDefaultColor, width: barwidth}} className="py-2"></div>
                <div style={{background: barDefaultColor, width: barwidth}} className="py-2"></div>
                <div style={{background: sustainableColor, width: barwidth}} className="py-2"></div>
                <div className="extra-small mx-auto mt-2">Sustainable</div>
            </div>
            <div className="col-3 col-xl-3 col-lg-6 d-flex flex-column gap-1 text-center graph-bars px-2">
                <div style={{background: barDefaultColor, width: barwidth}} className="py-2"></div>
                <div style={{background: neutralsColor, width: barwidth}} className="py-2"></div>
                <div style={{background: neutralsColor, width: barwidth}} className="py-2"></div>
                <div style={{background: neutralsColor, width: barwidth}} className="py-2"></div>
                <div style={{background: neutralsColor, width: barwidth}} className="py-2"></div>
                <div className="extra-small mx-auto mt-2">Neutral</div>
            </div>
            <div className="col-3 col-xl-3 col-lg-6 d-flex flex-column gap-1 text-center graph-bars px-2">
                <div style={{background: barDefaultColor, width: barwidth}} className="py-2"></div>
                <div style={{background: barDefaultColor, width: barwidth}} className="py-2"></div>
                <div style={{background: barDefaultColor, width: barwidth}} className="py-2"></div>
                <div style={{background: type === 'b' ? barDefaultColor : transitionColor, width: barwidth}}
                     className="py-2"></div>
                <div style={{background: type === 'b' ? barDefaultColor : transitionColor, width: barwidth}}
                     className="py-2"></div>
                <div className="extra-small mx-auto mt-2">Transition</div>
            </div>
            <div className="col-3 col-xl-3 col-lg-6 d-flex flex-column gap-1 text-center graph-bars px-2">
                <div style={{background: barDefaultColor, width: barwidth}} className="py-2"></div>
                <div style={{background: barDefaultColor, width: barwidth}} className="py-2"></div>
                <div style={{background: barDefaultColor, width: barwidth}} className="py-2"></div>
                <div style={{background: barDefaultColor, width: barwidth}} className="py-2"></div>
                <div style={{background: type === 'b' ? barDefaultColor : significantColor, width: barwidth}}
                     className="py-2"></div>
                <div className="extra-small mx-auto mt-2 d-flex"><span>Significant harm</span></div>
            </div>
        </div>
    </>);
};

export default EngagementBars;